import { UPDATE_USER_MANAGER_START, UPDATE_USER_MANAGER_SUCCESS, UPDATE_USER_MANAGER_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UpdateUserManagerAction(formdata, id, history, token) {
    return (dispatch) => {
        dispatch(UpdateUserManagerStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.put(`/api/users/${id}`, formdata, config)
            .then((res) => {
                // console.log(res)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha actualizado el usuario correctamente.' })
                dispatch(UpdateUserManagerSuccess())
                // history.push(`/admin-datos-de-proyecto/${res.data._id}`)
            })
            .catch(error => {
                dispatch(UpdateUserManagerFail())
                // console.log(error)
            })
    }
}




export const UpdateUserManagerStart = () => {
    return {
        type: UPDATE_USER_MANAGER_START
    };
};

export const UpdateUserManagerSuccess = () => ({
    type: UPDATE_USER_MANAGER_SUCCESS,
})

export const UpdateUserManagerFail = () => ({
    type: UPDATE_USER_MANAGER_FAIL,
})
