import {
    UPLOAD_ICON_COMPANY_FILE_PRODUCTS_START, UPLOAD_ICON_COMPANY_FILE_PRODUCTS_SUCCESS, UPLOAD_ICON_COMPANY_FILE_PRODUCTS_FAIL,
    UPLOAD_BG_COMPANY_FILE_PRODUCTS_START, UPLOAD_BG_COMPANY_FILE_PRODUCTS_SUCCESS, UPLOAD_BG_COMPANY_FILE_PRODUCTS_FAIL,
    UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_START, UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_SUCCESS, UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_FAIL,

} from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UploadIconAction(event, token, companyId) {
    return (dispatch) => {
        dispatch(UploadIconCompanyStart())
        // console.log(event)
        let fl = new FormData();
        fl.append("icon", event.target.files[0]);

        clienteAxios.post(`/api/img/icon/new/companyId/${companyId}`, fl, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        })
            .then((res) => {
                // console.log(res.data.img)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha cargado el archivo correctamente.' })
                dispatch(UploadIconCompanySuccess(res.data.img))
            })
            .catch(error => {
                dispatch(UploadIconCompanyFail())
                // console.log(error)
            })
    }
}
export function UploadBgAction(event, token, companyId) {
    return (dispatch) => {
        dispatch(UploadBgCompanyStart())
        // console.log(event)
        let fl = new FormData();
        fl.append("background", event.target.files[0]);

        clienteAxios.post(`/api/img/background/new/companyId/${companyId}`, fl, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        })
            .then((res) => {
                // console.log(res.data.img)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha cargado el archivo correctamente.' })
                dispatch(UploadBgCompanySuccess(res.data.img))
            })
            .catch(error => {
                dispatch(UploadBgCompanyFail())
                // console.log(error)
            })
    }
}

export function UploadLogoAction(event, token, companyId) {
    return (dispatch) => {
        dispatch(UploadLogoCompanyStart())
        // console.log(event)
        let fl = new FormData();
        fl.append("logo", event.target.files[0]);

        clienteAxios.post(`/api/img/logo/new/companyId/${companyId}`, fl, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        })
            .then((res) => {
                // console.log(res.data.img)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha cargado el archivo correctamente.' })
                dispatch(UploadLogoCompanySuccess(res.data.img))
            })
            .catch(error => {
                dispatch(UploadLogoCompanyFail())
                // console.log(error)
            })
    }
}


export const UploadIconCompanyStart = () => {
    return {
        type: UPLOAD_ICON_COMPANY_FILE_PRODUCTS_START
    };
};

export const UploadIconCompanySuccess = (icon) => ({
    type: UPLOAD_ICON_COMPANY_FILE_PRODUCTS_SUCCESS,
    payload: icon
})

export const UploadIconCompanyFail = () => ({
    type: UPLOAD_ICON_COMPANY_FILE_PRODUCTS_FAIL,
})


// ---------------------------------------------


export const UploadBgCompanyStart = () => {
    return {
        type: UPLOAD_BG_COMPANY_FILE_PRODUCTS_START
    };
};

export const UploadBgCompanySuccess = (Bg) => ({
    type: UPLOAD_BG_COMPANY_FILE_PRODUCTS_SUCCESS,
    payload: Bg
})

export const UploadBgCompanyFail = () => ({
    type: UPLOAD_BG_COMPANY_FILE_PRODUCTS_FAIL,
})


// ---------------------------------------------

export const UploadLogoCompanyStart = () => {
    return {
        type: UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_START
    };
};

export const UploadLogoCompanySuccess = (logo) => ({
    type: UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_SUCCESS,
    payload: logo
})

export const UploadLogoCompanyFail = () => ({
    type: UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_FAIL,
})
