import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarFilters'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    PagingState,
    IntegratedPaging,
    DataTypeProvider,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableColumnResizing,
    Toolbar,
    SearchPanel,
    VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import Layout from "../structures/Layout";

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;
const getRowId = row => row._id;


const OrdersPurchaseReport = ({ history }) => {
    // ID del parametro enviado por url, pertenece al id del proyecto que se esta viendo.
    const { materialId, idProject } = useParams();
    const [graphProjectsGeneralData, setGraphProjectsGeneralData] = useState([])
    const [searchValue, setSearchState] = useState('');

    // const [formdata, setFormData] = useState({
    //     status: ['aprobada'],
    //     projectId: null,
    //     requestedBy: null,
    //     endOfDay: null,
    //     startOfDay: null,
    //     advancePercentage: null,
    //     advancePercentageStart: 0,
    //     advancePercentageEnd: null,
    //     daysStart: 0,
    //     daysEnd: null
    // })

    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token)
    const [data, setData] = useState([])
    const [infoTitle, setInfoTitle] = useState({})

    // const { status, projectId, requestedBy, endOfDay, startOfDay, advancePercentageStart, advancePercentageEnd, daysStart, daysEnd } = formdata

    useEffect(() => {
        // console.log(formdata)

        // let body = {
        //     status: status === "Estado" ? ['aprobada'] : status,
        //     projectId: projectId === "" ? null : projectId,
        //     requestedBy: requestedBy === "" ? null : requestedBy,
        //     endOfDay: endOfDay === "" ? null : endOfDay,
        //     startOfDay: startOfDay === "" ? null : startOfDay,
        //     advancePercentageStart: advancePercentageEnd === 0 ? null : 0,
        //     advancePercentageEnd: advancePercentageEnd === 0 ? null : advancePercentageEnd,
        //     daysStart: daysEnd === 0 ? null : 0,
        //     daysEnd: daysEnd === 0 ? null : daysEnd,
        // }
        // console.log(body)

        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };
        clienteAxios.get(`/api/project/${idProject}/materialRequest/requestTableRow/materialId/${materialId}`, config)
            .then((res) => {
                console.log(res.data)
                const body = []
                let title = {
                    productName: res.data[0].material.productName,
                    category: res.data[0].material.category,
                    subcategory: res.data[0].material.subcategory,
                    sku: res.data[0].material.sku,
                }
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // console.log(element.projectId.projectName)
                    body.push({
                        number: element.materialRequestNumber,
                        cantidadSolicitada: element.cantidadSolicitada,
                        cantidadCompra: element.cantidadCompra,
                        montoCompra: element.montoCompra,
                        status: element.materialRequestStatus,
                        user: element.materialRequestUser[0].name + " " + element.materialRequestUser[0].lastname,
                        cargo: element.materialRequestUser[0].role,
                        _id: element.material._id,
                    })
                }
                console.log(title)
                setInfoTitle(title)
                setData(body)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])//formdata


    const [columns] = useState([
        { name: 'number', title: 'Solicitud' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        { name: 'cantidadCompra', title: 'Cantidad Compra' },
        { name: 'montoCompra', title: 'Monto Compra' },
        { name: 'status', title: 'Estado' },
        { name: 'user', title: 'Usuario' },
        { name: 'cargo', title: 'Cargo' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'number', width: 180 },
        { columnName: 'cantidadSolicitada', width: 200 },
        { columnName: 'cantidadCompra', width: 200 },
        { columnName: 'montoCompra', width: 200 },
        { columnName: 'status', width: 180 },
        { columnName: 'user', width: 220 },
        { columnName: 'cargo', width: 220 },
    ]);

    // const TableRow = ({ row, ...restProps }) => {
    //     return (<Table.Row {...restProps} onClick={() => {
    //         // console.log(row)
    //         let projectName = row.projectName;
    //         let id = row._id;

    //         if (status === "cerradas") {
    //             history.push(`/gestionar-compras-de-material/material/view/${id}`)
    //         } else {
    //             history.push(`/gestionar-compras-de-material/material/${id}`, { projectName })
    //         }

    //     }} style={{ cursor: 'pointer' }} />)
    // };

    // const TabCell = props => (
    //     <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    // );

    // const CurrencyFormatter = ({ value }) => {
    //     if (value) {
    //         return (<p>{value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </p>)
    //     } else {
    //         return (<p>{value}</p>)
    //     }
    // };

    // const CurrencyTypeProvider = props => (
    //     <DataTypeProvider
    //         formatterComponent={CurrencyFormatter}
    //         {...props}
    //     />
    // );

    // const [currencyColumns] = useState(['totalBudgetAmount', 'totalAmountPurchased', 'purchasePercentage']);



    return (
        <Layout>
            <>
                <Topbar title="Proyectos" orderPurchaseReport infoTitle={infoTitle} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card" style={{ height: '720px' }}>
                            <Grid rows={data} columns={columns} getRowId={getRowId} rootComponent={Root}>
                                {/* <CurrencyTypeProvider for={currencyColumns} /> */}
                                <SearchState value={searchValue} onValueChange={setSearchState} />
                                <IntegratedFiltering />
                                <Table />
                                {/* <VirtualTable height={670} cellComponent={TabCell} rowComponent={TableRow} /> */}
                                <VirtualTable height={670} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default OrdersPurchaseReport;