import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';

//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import clienteAxios from "../../config/axios";
import { useSelector } from "react-redux";
const CarouselProjectStatus = () => {

    const [data, setData] = useState([])
    const token = useSelector(state => state.auth.token)


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/log`, config)
            .then((res) => {
                // console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    return (
        <Carousel controls={false}>
            {data.map((item, i) => (
                <Carousel.Item>
                    <Carousel.Caption>
                        <Row>
                            <Col xs={12}>
                                <p className="text-left"><strong>Nombre: </strong>{item.projectName ? item.projectName : ""}</p>
                                <p className="text-left"><strong>Encargado: </strong>{item.userId ? item.userId.name + " " + item.userId.lastname : ""}</p>
                                <p className="text-left"><strong>Última actualización: </strong>{item.date ? item.date.split('T')[0] : ""}</p>
                                <Link to="#" className="btn btn-warning col-12 text-regular text-small mt-3 border-radius-0">{item.action ? item.action : ""}</Link>
                            </Col>
                        </Row>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}

        </Carousel>

    );
}

export default CarouselProjectStatus;