import * as React from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Structures
import UploadProductsManuallyPopup from './../structures/UploadProductsManuallyPopup'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from "react-bootstrap"
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import Layout from "../structures/Layout"


const AdminUploadProducts = () => {

    return (
        <Layout>
            <>
                <Topbar gestionProducts={true} />
                <Row className="d-flex align-items-start py-3">

                </Row>
            </>
            <>
                <Sidebar />
            </>
        </Layout>
    );
}

export default AdminUploadProducts;