import React, { useState, useEffect } from "react"
import { NavLink } from 'react-router-dom';
//Bootstrap
import { Col, Tooltip, OverlayTrigger, } from 'react-bootstrap';
// popup
import CreateProjectPopup from '../structures/CreateProjectPopup';
// router
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { SendNewMaterialRequestTableAction, SendNewMaterialRequestTableUpdayeAction } from '../../redux/actions/SendNewMaterialRequest';
import { QuotePDFAction } from '../../redux/actions/QuotePDF';
import { SaveQuotePDFAction } from '../../redux/actions/SaveQuoteAction';
import clienteAxios from './../../config/axios';
import { CSVLink, CSVDownload } from "react-csv";
import Swal from 'sweetalert2';
import exportFromJSON from 'export-from-json'
import {
    faSave, faPlusSquare, faTrash, faBoxes, faBox, faDolly, faFileDownload, faDownload, faSync, faSeedling, faCheck, faTimes, faFileInvoiceDollar, faUserPlus, faShapes, faArrowCircleUp, faExchangeAlt
} from '@fortawesome/free-solid-svg-icons'
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
// import styled from '@emotion/styled';


// NOTA: estos props condicionan que se muestren los botones del sidebar dependiendo de la vista
const Sidebar = ({ createProject,
    dateProject,
    products,
    search,
    save,
    saveUpdate,
    send, quote,
    pass, create,
    saveQuote,
    sendPassword,
    deleteUser, edit,
    history,
    dataToPostMaterialRequest,
    paramsMaterialRequest,
    statusToShowButton,
    projectId,
    quoteData,
    updateCompanyB,
    updateCompany,
    showButtonByUpdateIsTrue,
    projectName,
    declined,
    downloadExcel,
    ButtonExcel,
    CSVData,
    headers,
    headerToQuoteCSV,
    dataToQuoteCSV,
    gestionar,
    materialRequestNumber,
    projectNumber,
    deleteProject,
    deleteMaterialRequest,
    showButtonDeleteMaterialRequest,
    addTags,
    dataToTags,
    companyId, categoruesSubcategories,
    updateCLient, setDataToCLienteAfterUpdate, setModalShowAfterUpdtaeCLient,
    DownloadInformes, DownloadInformesData
}) => {
    const token = useSelector(state => state.auth.token)
    const user_role = useSelector(state => state.auth.user_role)
    // status de la orden enviada
    // const status = useSelector(state => state.saveProyectIDToMaterialRequest.status)
    // location
    let location = useLocation();
    // console.log(location)
    const path = location.pathname;
    const { id, idProject } = useParams();
    // createproject
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // -------------- new material request post
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const sendMaterialFn = (data, history, token, id, projectId, setFirstSend) => dispatch(SendNewMaterialRequestTableAction(data, history, token, id, projectId, setFirstSend));
    const sendMaterialUpdateFn = (data, history, token, id, projectId) => dispatch(SendNewMaterialRequestTableUpdayeAction(data, history, token, id, projectId));
    const QuotePDFActionFn = (body, history, token) => dispatch(QuotePDFAction(body, history, token));
    const SaveQuotePDFFN = (body, token, projectId, history, gestionar) => dispatch(SaveQuotePDFAction(body, token, projectId, history, gestionar));



    const [firstSend, setFirstSend] = useState(false)

    useEffect(() => {
        if (showButtonByUpdateIsTrue) {
            setFirstSend(showButtonByUpdateIsTrue)
        } else {
            setFirstSend(false)
        }
    }, [])

    const sendDataNewMaterialRequest = (customData, action, projectId, projectName) => {
        let products = [];
        for (let i = 0; i < customData.length; i++) {
            const element = customData[i];
            products.push({
                "materialId": element._id ? element._id : "",
                "cantidadSolicitada": element.cantidadSolicitada ? element.cantidadSolicitada : "",
                "observacion": element.observacion ? element.observacion : "",
            })
        }

        // console.log(paramsMaterialRequest)
        let body = {
            "materialRequestId": paramsMaterialRequest, // _id de la solicitud que se hace en el paso anterior,
            products
        }
        // console.log(projectName)
        if (action) {
            // console.log('update')
            let bodyUpdate = {
                products: customData,
                materialRequestId: id
            }
            // console.log(bodyUpdate)
            sendMaterialUpdateFn(bodyUpdate, history, token, id, projectId, projectName)
        } else {
            // console.log('post')
            // console.log(body)
            sendMaterialFn(body, history, token, id, projectId, setFirstSend, projectName)
        }
        // console.log(body, id)
    }
    // -------------------------------- put
    const putDataNewMaterialRequest = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        let body = {
            "status": "enviada"
        }
        clienteAxios.put(`/api/project/materialRequest/${id}`, body, config)
            .then((res) => {
                // console.log(res.data)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha enviado correctamente.' })
                history.push(`/proyectos/solicitud-de-material/${projectId}`)
            })
            .catch(error => {
                // console.log(error)
            })
    }
    // cotizar ---------------------------------------------
    const quoteFn = (products) => {
        if (products.length !== 0) {
            let rowsIds = [];
            for (let i = 0; i < products.length; i++) {
                const element = products[i];
                rowsIds.push(
                    element._id
                )
            }
            let data = [];
            for (let i = 0; i < products.length; i++) {
                const element = products[i];
                data.push({
                    "Sku": element.sku,
                    "Nombre del producto": element.productName,
                    "Unidades del producto": element.productUnits,
                    "Marca del producto": element.productBrand,
                    "Modelo del producto": element.productModel,
                    "Cantidad solicitada": element.cantidadSolicitada,
                    "Observacion": element.observacion,


                    "Documento": element.Documento,
                    "Cantidad compra": element.cantidadCompra,
                    "Cantidad presupuestada": element.cantidadPresupuestada,
                    "Disponible presupuesto": element.disponiblePresupuesto,
                    "Monto compra": element.montoCompra,
                    "Monto presupuestado": element.montoPresupuestado,
                    "Ocerp": element.ocerp,
                    "Saldo disponible": element.saldoDisponible,
                    "Total compra": element.totalCompra,
                    "Total presupuestado": element.totalPresupuestado,
                })
            }
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };

            let body = {
                rowsIds
            }
            clienteAxios.put(`/api/project/${projectId}/materialRequest/requestTableRow`, body, config)
                .then((res) => {
                    const fileName = `solicitud${materialRequestNumber}_proyecto${projectNumber}`
                    const exportType = exportFromJSON.types.xls

                    exportFromJSON({ data, fileName, exportType })
                })
                .catch(error => {
                    Swal.fire({ icon: 'error', title: 'Opps!', text: 'Error al cotizar' })
                })
            // done(false)
        } else {
            Swal.fire({ icon: 'error', title: 'Opps!', text: 'Debe habilitar la opcion de cotizar en almenos un producto.' })
        }

    }
    // guardar-------------------- quote
    const SaveFnQuote = (quoteData, projectId) => {
        // console.log(quoteData, projectId)

        let body = {
            materialRequestId: id,
            products: []
        }

        for (let i = 0; i < quoteData.length; i++) {
            const item = quoteData[i];
            body.products.push({
                materialId: item.materialId,
                cantidadSolicitada: item.cantidadSolicitada,
                cantidadCompra: item.cantidadCompra,
                montoCompra: item.montoCompra,
                ocerp: item.ocerp,
                Documento: item.Documento,
                observacion: item.observacion,
                status: item.status,
                _id: item._id
            })
        }

        // console.log(body)
        SaveQuotePDFFN(body, token, projectId, history, gestionar)
    }
    // ----------pass
    const PassAction = (value) => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        let body = {
            "status": value
        }
        // console.log(body)
        clienteAxios.put(`/api/project/materialRequest/${id}`, body, config)
            .then((res) => {
                // console.log(res.data)
                const text = value == "rechazada" ? "rechazado" : "aprobado"
                Swal.fire({ icon: 'success', title: 'Exito!', text: `Se ha ${text} correctamente.` })
                history.push('/aprobar-solicitud-de-material')
            })
            .catch(error => {
                // console.log(error)
            })
    }
    // ------------------------------------------

    const DownloadCurrrentProduts = (datoToXml) => {

        let data = [];
        for (let i = 0; i < datoToXml.length; i++) {
            const element = datoToXml[i];
            data.push({
                "Sku": element.sku,
                "Nombre del producto": element.productName,
                "Marca del producto": element.productBrand,
                "Modelo del producto": element.productModel,
                "Unidad del producto": element.productUnits,
                "Cantidad compra": element.cantidadCompra,
                "Cantidad presupuestada": element.cantidadPresupuestada,
                "Disponible presupuesto": element.disponiblePresupuesto,
                "Monto compra": element.montoCompra,
                "Monto presupuestado": element.montoPresupuestado,
                "Porcentaje compra": element.porcentajeCompra,
                "Tipo del producto": element.producType,
                "Saldo disponible": element.saldoDisponible,
                "Total compra": element.totalCompra,
                "Total presupuestado": element.totalPresupuestado,
            })
        }
        const fileName = 'productos_actuales'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }

    useEffect(() => {
        // console.log(quoteData)
        if (quoteData) {
            let elementToQuote = []
            for (let i = 0; i < quoteData.length; i++) {
                const element = quoteData[i];
                if (element) {
                    elementToQuote.push(element)
                }
            }
        }

    }, [quoteData])

    // delete project-----------------
    const DeleteProject = () => {
        Swal.fire({
            title: 'Estas seguro de eliminar el proyecto?',
            text: "Esta accion no se podra revertir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const config = {
                    headers: { 'Authorization': "Bearer " + token }
                };
                clienteAxios.delete(`/api/project/${id}`, config)
                    .then((res) => {
                        Swal.fire(
                            'Borrado!',
                            'Su proyecto ha sido borrado',
                            'success'
                        )
                        history.push('/admin-proyectos')
                    })
                    .catch(error => {
                        // console.log(error)
                    })

            }
        })

    }


    const deleteMaterialRequestFn = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.delete(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                // const text = value == "rechazada" ? "rechazado" : "aprobado"
                Swal.fire({ icon: 'success', title: 'Exito!', text: `Se ha eliminado correctamente.` })
                history.push(`/proyectos/solicitud-de-material/${idProject}`)
            })
            .catch(error => {
                // console.log(error.response.data.message)
                // console.log(error)
                Swal.fire({ icon: 'error', title: 'Opps!', text: `${error.response.data.message}` })
            })
    }


    // ------------------------------------------

    const UpdateClientAction = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.put('/api/client/all', {}, config)
            .then((res) => {
                console.log(res.data)
                setDataToCLienteAfterUpdate(res.data)
                Swal.fire({ icon: 'success', title: 'Exito!', text: `Se ha actuaizado correctamente` })
                setModalShowAfterUpdtaeCLient(true)
            })
            .catch(error => {
                console.log(error.response)
                Swal.fire({ icon: 'error', title: 'Opps!', text: `Error al actualizar` })
            })
    }


    const DownloadInformesAction = (datoToXml) => {
        // aprobada: 1
        // cerradas: 0
        // diasPromedio: "0.0"
        // enviada: 0
        // pendiente: 0
        // projectId: "615f5e62ebd95e3310d8380a"
        // projectName: "111101"
        // projectNumber: 111101
        // purchasePercentage: "0%"
        // solicitudes: 1
        // totalAmountPurchased: 500
        // totalBudgetAmount: 19332349
        console.log(datoToXml)
        let data = [];
        for (let i = 0; i < datoToXml.length; i++) {
            const element = datoToXml[i];
            data.push({
                "Aprobada": element.aprobada,
                "Cerradas": element.cerradas,
                "Dias Promedio": element.diasPromedio,
                "Enviada": element.enviada,
                "Pendiente": element.pendiente,
                "Id del proyecto": element.projectId,
                "Nombre del proyecto": element.projectName,
                "Numero del proyecto": element.projectNumber,
                "Porcentaje de compra": element.purchasePercentage,
                "Solicitudes": element.solicitudes,
                "Monto total comprado": element.totalAmountPurchased,
                "Importe total del presupuesto": element.totalBudgetAmount,
            })
        }
        const fileName = 'Informes'
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })

    }

    return (
        <React.Fragment>
            <Col xs={12} className="justify-content-center options text-center pt-3">
                {/* <Col sm={8} md={12} className="pb-3 pt-lg-3 px-0">
                    <p css={css``} className="font-weight-bold">Opciones</p>
                </Col> */}
                {createProject && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' activeClassName="active_icon" className="buttonsSide" onClick={handleShow}>Crear proyecto</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <div onClick={() => setShow(true)} css={css`cursor:pointer;`}>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Crear proyecto
                                    </Tooltip>
                                }>
                                <FontAwesomeIcon icon={faPlusSquare} size="2x" />
                            </OverlayTrigger>
                        </div>
                    </div>
                )}
                {dateProject && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to={`/admin-proyectos/datos-de-proyecto/${id}`} className={`buttonsSide ${path.includes('admin-proyectos/datos-de-proyecto') ? 'bg-warning' : ''}`}>Datos del proyecto</NavLink>
                    // </Col>
                    <div></div>
                )}
                {products && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to={`/admin-proyectos/datos-de-proyecto/productos/${id}`} className={`buttonsSide ${path.includes('admin-proyectos/datos-de-proyecto/productos') ? 'bg-warning' : ''}`}>Productos</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Productos
                                </Tooltip>
                            }>
                            <NavLink className={`text-center ${path.includes('admin-proyectos/datos-de-proyecto/productos') ? 'bg-warning' : ''}`} to={`/admin-proyectos/datos-de-proyecto/productos/${id}`}><FontAwesomeIcon icon={faDolly} size="2x" onClick={handleShow} /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {/* {search && (
                    <Col sm={8} md={12} className="pb-3 mx-auto">
                        <NavLink exact to='/proyecto-solicitud-de-material' className="buttonsSide" activeClassName="active_icon">Buscar</NavLink>
                    </Col>
                )} */}
                {save && (
                    statusToShowButton === "pendiente" && (
                        // <Col sm={8} md={12} className="pb-3 mx-auto">
                        //     <NavLink exact to='#' className="buttonsSide" onClick={() => sendDataNewMaterialRequest(dataToPostMaterialRequest, false, projectId, projectName)} activeClassName="active_icon">Guardar</NavLink>
                        // </Col>
                        <div css={css`width:100%;`} className="mb-3">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Guardar
                                    </Tooltip>
                                }>
                                <NavLink className="text-center" to='#'><FontAwesomeIcon icon={faSave} size="2x" onClick={() => sendDataNewMaterialRequest(dataToPostMaterialRequest, false, projectId, projectName)} /></NavLink>
                            </OverlayTrigger>
                        </div>
                    )
                )}
                {saveUpdate && (
                    statusToShowButton === "pendiente" && (
                        // <Col sm={8} md={12} className="pb-3 mx-auto">
                        //     <NavLink exact to='#' className="buttonsSide" onClick={() => sendDataNewMaterialRequest(dataToPostMaterialRequest, true, projectId, projectName)} activeClassName="active_icon">Actualizar</NavLink>
                        // </Col>
                        <div css={css`width:100%;`} className="mb-3">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        Actualizar
                                    </Tooltip>
                                }>
                                <NavLink to='#' onClick={() => sendDataNewMaterialRequest(dataToPostMaterialRequest, true, projectId, projectName)}><FontAwesomeIcon icon={faSync} size="2x" /></NavLink>
                            </OverlayTrigger>
                        </div>
                    )
                )}
                {send && (
                    statusToShowButton === "pendiente" && (
                        firstSend && (
                            // <Col sm={8} md={12} className="pb-3 mx-auto">
                            //     <NavLink exact to='#' className="buttonsSide" onClick={() => putDataNewMaterialRequest()} activeClassName="active_icon">Enviar</NavLink>
                            // </Col>
                            <div css={css`width:100%;`} className="mb-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Enviar
                                        </Tooltip>
                                    }>
                                    <NavLink to='#' onClick={() => putDataNewMaterialRequest()}><FontAwesomeIcon icon={faTelegramPlane} size="2x" /></NavLink>
                                </OverlayTrigger>
                            </div>
                        )
                    )
                )}
                {quote && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => quoteFn(dataToQuoteCSV)} activeClassName="active_icon">Cotizar</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Cotizar
                                </Tooltip>
                            }>
                            <NavLink to='#' onClick={() => quoteFn(dataToQuoteCSV)}><FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {saveQuote && (
                    user_role === "gerente" || user_role === "aprobador" || user_role === "comprador" ?
                        statusToShowButton === 'aprobada' || statusToShowButton === 'rechazada' ?
                            <div></div>
                            :
                            // <Col sm={8} md={12} className="pb-3 mx-auto">
                            //     <NavLink exact to='#' className="buttonsSide" onClick={() => SaveFnQuote(quoteData, projectId)} activeClassName="active_icon">Guardar</NavLink>
                            // </Col>
                            <div css={css`width:100%;`} className="mb-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Guardar
                                        </Tooltip>
                                    }>
                                    <NavLink to='#' onClick={() => SaveFnQuote(quoteData, projectId)}><FontAwesomeIcon icon={faSave} size="2x" /></NavLink>
                                </OverlayTrigger>
                            </div>
                        :
                        <div></div>
                )}
                {pass && (
                    user_role === "gerente" || user_role === "aprobador" ?
                        statusToShowButton === 'aprobada' || statusToShowButton === 'rechazada' ?
                            <div></div>
                            :
                            // <Col sm={8} md={12} className="pb-3 mx-auto">
                            //     <NavLink exact to='#' className="buttonsSide" onClick={() => PassAction("aprobada")} activeClassName="active_icon">Aprobar</NavLink>
                            // </Col>
                            <div css={css`width:100%;`} className="mb-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Aprobar
                                        </Tooltip>
                                    }>
                                    <NavLink to='#' onClick={() => PassAction("aprobada")}><FontAwesomeIcon icon={faCheck} size="2x" /></NavLink>
                                </OverlayTrigger>
                            </div>
                        :
                        <div></div>
                )}
                {declined && (
                    user_role === "gerente" || user_role === "aprobador" ?
                        statusToShowButton === 'aprobada' || statusToShowButton === 'rechazada' ?
                            <div></div>
                            :
                            // <Col sm={8} md={12} className="pb-3 mx-auto">
                            //     <NavLink exact to='#' className="buttonsSide" onClick={() => PassAction("rechazada")} activeClassName="active_icon">Rechazar</NavLink>
                            // </Col>
                            <div css={css`width:100%;`} className="mb-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Rechazar
                                        </Tooltip>
                                    }>
                                    <NavLink to='#' onClick={() => PassAction("rechazada")}><FontAwesomeIcon icon={faTimes} size="2x" /></NavLink>
                                </OverlayTrigger>
                            </div>
                        :
                        <div></div>
                )}
                {create && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='/gestion-de-usuarios/crear' className={`buttonsSide ${path.includes('gerente-crear-usuario') ? 'bg-warning' : ''}`} activeClassName="active_icon">Crear</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Crear
                                </Tooltip>
                            }>
                            <NavLink to='/gestion-de-usuarios/crear' className={`${path.includes('gerente-crear-usuario') ? 'bg-warning' : ''}`}><FontAwesomeIcon icon={faUserPlus} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {sendPassword && (
                    <Col sm={8} md={12} className="pb-3 mx-auto">
                        <NavLink exact to='/' className="buttonsSide" activeClassName="active_icon">Enviar password</NavLink>
                    </Col>
                )}
                {deleteUser && (
                    <Col sm={8} md={12} className="pb-3 mx-auto">
                        <NavLink exact to='/' className="buttonsSide" activeClassName="active_icon">Eliminar usuario</NavLink>
                    </Col>
                )}
                {edit && (
                    <Col sm={8} md={12} className="pb-3 mx-auto">
                        <NavLink exact to='/' className="buttonsSide" activeClassName="active_icon">Editar</NavLink>
                    </Col>
                )}
                {updateCompanyB && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => updateCompany()} activeClassName="active_icon">Actualizar compañia</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Actualizar compañia
                                </Tooltip>
                            }>
                            <NavLink to='#' onClick={() => updateCompany()}><FontAwesomeIcon icon={faSync} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {downloadExcel && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <a exact href='https://defaena.herokuapp.com/columnaTipo.xlsx' target="_blank" className="buttonsSide" activeClassName="active_icon">Descargar plantilla excel</a>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Descargar plantilla excel
                                </Tooltip>
                            }>
                            <a exact href='https://defaena.herokuapp.com/columnaTipo.xlsx' target="_blank"><FontAwesomeIcon icon={faFileDownload} size="2x" /></a>
                        </OverlayTrigger>
                    </div>
                )}
                {ButtonExcel && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => DownloadCurrrentProduts(CSVData)} activeClassName="active_icon">Descargar Productos actuales</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Descargar Productos actuales
                                </Tooltip>
                            }>
                            <NavLink exact to='#' onClick={() => DownloadCurrrentProduts(CSVData)}><FontAwesomeIcon icon={faDownload} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {categoruesSubcategories && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => DeleteProject()} activeClassName="active_icon">Borrar este proyecto</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Categorias y Subcategorias
                                </Tooltip>
                            }>
                            <NavLink to={`/admin-proyectos/datos-de-proyecto/${id}/categoria-y-subcategorias`}><FontAwesomeIcon icon={faShapes} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {deleteProject && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => DeleteProject()} activeClassName="active_icon">Borrar este proyecto</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Borrar este proyecto
                                </Tooltip>
                            }>
                            <NavLink to='#'><FontAwesomeIcon icon={faTrash} size="2x" onClick={() => DeleteProject()} /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {updateCLient && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => DeleteProject()} activeClassName="active_icon">Borrar este proyecto</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Actualizar Clientes
                                </Tooltip>
                            }>
                            <NavLink to='#'><FontAwesomeIcon onClick={() => UpdateClientAction()} icon={faExchangeAlt} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {DownloadInformes && (
                    // <Col sm={8} md={12} className="pb-3 mx-auto">
                    //     <NavLink exact to='#' className="buttonsSide" onClick={() => DownloadCurrrentProduts(CSVData)} activeClassName="active_icon">Descargar Productos actuales</NavLink>
                    // </Col>
                    <div css={css`width:100%;`} className="mb-3">
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Descargar informes
                                </Tooltip>
                            }>
                            <NavLink exact to='#' onClick={() => DownloadInformesAction(DownloadInformesData)}><FontAwesomeIcon icon={faDownload} size="2x" /></NavLink>
                        </OverlayTrigger>
                    </div>
                )}
                {
                    showButtonDeleteMaterialRequest && (
                        deleteMaterialRequest && (
                            // <Col sm={8} md={12} className="pb-3 mx-auto">
                            //     <NavLink exact to='#' onClick={() => deleteMaterialRequestFn()} className="buttonsSide" activeClassName="active_icon">Borrar esta solicitud de material</NavLink>
                            // </Col>
                            <div css={css`width:100%;`} className="mb-3">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Borrar esta solicitud de material
                                        </Tooltip>
                                    }>
                                    <NavLink to='#' onClick={() => deleteMaterialRequestFn()}><FontAwesomeIcon icon={faTrash} size="2x" /></NavLink>
                                </OverlayTrigger>
                            </div>
                        )
                    )
                }
            </Col>
            {path === '/admin-proyectos' && (
                <CreateProjectPopup show={show} onHide={handleClose} history={history} />
            )}
        </React.Fragment >
    );
}

export default Sidebar;