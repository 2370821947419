import { CREATE_PROJECT_START, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UploadProductsFileAction(body, history, token) {
    return (dispatch) => {
        dispatch(CreateProjectStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/project', body, config)
            .then((res) => {
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha creado el proyecto correctamente.' })
                dispatch(CreateProjectSuccess())
                history.push(`/admin-proyectos/datos-de-proyecto/${res.data._id}`)
            })
            .catch(error => {
                dispatch(CreateProjectFail())
                // console.log(error)
            })
    }
}




export const CreateProjectStart = () => {
    return {
        type: CREATE_PROJECT_START
    };
};

export const CreateProjectSuccess = () => ({
    type: CREATE_PROJECT_SUCCESS,
})

export const CreateProjectFail = () => ({
    type: CREATE_PROJECT_FAIL,
})
