import * as React from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarFilters'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Layout from "../structures/Layout";

const TransactionLogs = () => {

    return (
        <Layout>
            <>
                <Topbar />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Número del<br />Proyecto</th>
                                    <th>Proyecto</th>
                                    <th>Usuario</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>10/05/2021</td>
                                    <td>8090</td>
                                    <td>8090 CEN</td>
                                    <td>David Toro</td>
                                    <td>Solicitud de material</td>
                                </tr>
                                <tr>
                                    <td>10/05/2021</td>
                                    <td>8349</td>
                                    <td>8349 Marienberg</td>
                                    <td>Eduardo Vial</td>
                                    <td>Creción del proyecto</td>
                                </tr>
                                <tr>
                                    <td>10/05/2021</td>
                                    <td>10089</td>
                                    <td>10089 La Vara</td>
                                    <td>Vladimir Cárdenas</td>
                                    <td>Aprobación del presupuesto</td>
                                </tr>
                                <tr>
                                    <td>10/05/2021</td>
                                    <td>10089</td>
                                    <td>10089 La Vara</td>
                                    <td>Carlos Andrade</td>
                                    <td>Rechazo de presupuesto</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar />
            </>
        </Layout>
    );
}

export default TransactionLogs;