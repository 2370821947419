import React, { useState ,useEffect} from "react"
import { Link, useHistory } from 'react-router-dom';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// alert
import Swal from 'sweetalert2';
//Images
import LogoDefaena from './../../resources/images/page/logo_defaena.svg'
import SignInImage from './../../resources/images/login/login.jpg'
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { BusinessAction } from '../../redux/actions/AuthAction';
import Fn from './../extras/validateRUT';
const Register = () => {
    let history = useHistory();
    const token = useSelector(state => state.auth.token)
 
    const [formdata, setFormData] = useState({
        companyName: '',
        urlName: '',
        name: '',
        lastname: '',
        rut: '',
        email: '',
        password: '',
        companyRut: '',
    })

    const { companyName, urlName, name, lastname, rut, email, password, companyRut } = formdata;
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const BusinessFn = (formdata, history) => dispatch(BusinessAction(formdata, history));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        if (companyName === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre de la empresa requerido' })
        }
        // else if (urlName === "") {
        //     Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre de la URL requerido' })
        // }
        else if (companyRut === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'RUT de la empresa requerido' })
        } else if (name === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del administrador requerido' })
        } else if (lastname === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Apellido del administrador requerido' })
        } else if (rut === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'RUT del administrador requerido' })
        } else if (email === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Email del administrador requerido' })
        } else if (password === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Contraseña del administrador requerido' })
        } else {
            let newRut = rut.split('-')
            let newCompanyRut = companyRut.split('-')
            let body = {
                companyName,
                // urlName,
                name,
                lastname,
                rut: newRut[0] + newRut[1],
                email,
                password,
                companyRut: newCompanyRut[0] + newCompanyRut[1],
            }
            BusinessFn(body, history)
            // console.log(body)
        }
    }

    const checkPassword = (e) => {
        let pass = e.target.value;
        // console.log(pass)
        if (pass.length < 7 || pass.length > 20) {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'La contrasena debe tener almenos 7 digitos y menos a 20 digitos' })
        } else {
            setFormData({
                ...formdata,
                password: pass,
            })
        }
    }
    const checkRut = (e) => {
        let rutCheck = e.target.value;
        // console.log(rutCheck)

        if (rutCheck !== "" && rutCheck.includes("-")) {
            if (rutCheck.split('-')[1].toUpperCase() === "K") {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Si el digito verificador termina en "K" reemplazar con "0". Ingrese nuevamente el RUT.' })
            } else {
                if (!Fn.validaRut(rut)) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de administrador invalido' })
                } else {
                    setFormData({
                        ...formdata,
                        rut: rutCheck,
                    })
                }
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de administrador invalido' })
        }
    }
    const checkRutCompany = (e) => {
        let rutCheckCompany = e.target.value;
        // console.log(rutCheckCompany)
        if (rutCheckCompany !== "" && rutCheckCompany.includes("-")) {
            if (rutCheckCompany.split('-')[1].toUpperCase() === "K") {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Si el digito verificador termina en "K" reemplazar con "0". Ingrese nuevamente el RUT.' })
            } else {
                if (!Fn.validaRut(companyRut)) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de la compañia invalido' })
                } else {
                    setFormData({
                        ...formdata,
                        companyRut: rutCheckCompany,
                    })
                }
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de la compañia invalido' })
        }

    }

    return (
        <Container fluid className="bg_white w-100">
            <Row className="d-lg-flex align-items-center justify-content-center login">
                <Col xs={12} md={7} lg={5} xl={6} className="text-center justify-content-center pt-5 pt-lg-0">
                    <img src={LogoDefaena} className="pb-5 logo" alt="Logo Company" />
                    <Form className="col-lg-11 col-xl-8 mx-auto text-left">
                        <Form.Group controlId="businessName" className="mb-4">
                            {/* <Form.Label>Nombre de la empresa</Form.Label> */}
                            <Form.Control type="text" placeholder="Nombre de la empresa" name="companyName" onChange={readFormData} />
                        </Form.Group>
                        <Form.Group controlId="businessName" className="mb-4">
                            {/* <Form.Label>RUT de la empresa</Form.Label> */}
                            <Form.Control type="text" placeholder="RUT de la empresa" name="companyRut" value={companyRut} onChange={readFormData} onBlur={checkRutCompany} />
                        </Form.Group>
                        <Form.Group controlId="AdminName" className="mb-4">
                            {/* <Form.Label>Nombre del administrador</Form.Label> */}
                            <Form.Control type="text" placeholder="Nombre del administrador" name="name" onChange={readFormData} />
                        </Form.Group>
                        <Form.Group controlId="AdminLastname" className="mb-4">
                            {/* <Form.Label>Apellido del administrador</Form.Label> */}
                            <Form.Control type="text" placeholder="Apellido del administrador" name="lastname" onChange={readFormData} />
                        </Form.Group>
                        <Form.Group controlId="AdminRut" className="mb-4">
                            {/* <Form.Label>RUT del administrador</Form.Label> */}
                            <Form.Control type="text" placeholder="RUT del administrador" name="rut" value={rut} onChange={readFormData} onBlur={checkRut} />
                        </Form.Group>
                        <Form.Group controlId="AdminEmail" className="mb-4">
                            {/* <Form.Label>Email del administrador</Form.Label> */}
                            <Form.Control type="email" placeholder="Email del administrador" name="email" onChange={readFormData} />
                        </Form.Group>
                        <Form.Group controlId="AdminPassword" className="mb-4">
                            {/* <Form.Label>Contraseña</Form.Label> */}
                            <Form.Control type="password" placeholder="Contraseña" name="password" value={password} onChange={readFormData} onBlur={checkPassword} />
                        </Form.Group>
                        <Button variant="warning" className="col-12 py-3" onClick={() => handleSubmit()}>
                            Registrarse
                        </Button>
                    </Form>
                    <p className="text-dark text-center pt-5">¿Ya tiene una cuenta? <span><Link className="text_orange" to="/">Iniciar Sesion</Link></span></p>
                    <p className="text_orange text-center pt-3">Todos los Derechos Reservados defaena.</p>
                </Col>
                <Col xs={12} md={10} lg={7} xl={6} className="px-0 py-4 py-lg-0 bg_register text-center">
                    <img src={SignInImage} className="img-fluid col-12 px-0 cover_image d-lg-none" alt="Cover" />
                </Col>
            </Row>
        </Container>
    );
}

export default Register;