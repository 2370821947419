import React, { useEffect, useState } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { EditingState, PagingState, IntegratedPaging, DataTypeProvider, SearchState, IntegratedFiltering, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableInlineCellEditing, Toolbar, TableColumnResizing, TableFixedColumns, TableEditRow, PagingPanel, SearchPanel, TableGroupRow, VirtualTable, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import { Plugin, Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AutoComplete from './../extras/autocompleteUpdate';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Swal from "sweetalert2";
import formatNumber from '../extras/numberFormater'
import Layout from "../structures/Layout";

const getRowId = row => row._id;

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);
const ManagerMaterialPurchaseView = ({ history, location }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    //DISPATCH
    const dispatch = useDispatch();

    const [data, setData] = useState([])
    const [projectName, setProjectName] = useState("")
    const [projectNumber, setProjectNumber] = useState("")
    const [dataToQuote, setDataToQuote] = useState(null)
    const [materialRequestNumber, setMaterialRequestNumber] = useState("")
    const [editingCells, setEditingCells] = useState([]);
    const addEmptyRow = () => commitChanges({ added: [{}] });
    const [statusToShowButton, setStatusToShowButton] = useState("")
    const [projectId, setProjectId] = useState("")
    const [booleanColumns] = React.useState(["cotizar"]);
    const [lookupColumns] = React.useState(["status"]);
    const [editingStatusCell] = React.useState([
        "sku",
        "productName",
        "category",
        "subcategory",
        "productUnits",
        "productBrand",
        "productModel",
        "cantidadPresupuestada",
        "saldoDisponible",
        "cantidadSolicitada",
        "cantidadCompra",
        "montoPresupuestado",
        "montoCompra",
        "totalPresupuestado",
        "totalCompra",
        "disponiblePresupuesto",
        "ocerp",
        "Documento",
        "observacion",
        // "status",
    ]);
    const [leftColumns] = useState(['sku', 'productName']);
    const [startEditAction, setStartEditAction] = useState('click');
    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);
    const [expandAll, setExpandAll] = useState([]);

    const addItemToTable = (Item) => {
        if (Item) {
            for (let j = 0; j < Item.length; j++) {
                const arraytoadd = Item[j];
                if (data.length === 0) {
                    setData(state => [...state, arraytoadd])
                    Toast.fire({ icon: 'success', title: 'Productos agregados' })
                } else {
                    let include = false;
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        if (arraytoadd.productName === element.productName) {
                            include = true
                        }
                    }
                    if (include) {
                        Toast.fire({ icon: 'error', title: 'El producto ya se encuentra agregado' })
                    } else {
                        setData(state => [...state, arraytoadd])
                        Toast.fire({ icon: 'success', title: 'Productos agregados' })
                    }
                }
                setTimeout(() => {
                    var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                    [].forEach.call(test, function (matche) {
                        matche.setAttribute("colspan", "11");
                    });
                }, 100);
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Opps!', text: 'Seleccione un producto' })
        }
    }

    const [columns] = useState([
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Producto ' },
        { name: 'category', title: 'Categoria' },
        { name: 'subcategory', title: 'Subcategory ' },
        // { name: 'cotizar', title: 'Cotizar  ' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'cantidadPresupuestada', title: 'Cantidad Presupuestada' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        { name: 'cantidadCompra', title: 'Cantidad compra' },
        { name: 'montoPresupuestado', title: 'Monto presupuestado' },
        { name: 'montoCompra', title: 'Monto Compra' },
        { name: 'totalPresupuestado', title: 'Total presupuestado' },
        { name: 'totalCompra', title: 'Total compra' },
        { name: 'disponiblePresupuesto', title: 'Disponible presupuesto' },
        { name: 'ocerp', title: 'OC ERP' },
        { name: 'Documento', title: 'Documento' },
        { name: 'observacion', title: 'Observacion' },
        { name: 'status', title: 'Estado' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'sku', width: 120 },
        { columnName: 'productName', width: 300 },
        { columnName: 'subcategory', width: 120 },
        { columnName: 'category', width: 100 },
        { columnName: 'cotizar', width: 100 },
        { columnName: 'productUnits', width: 120 },
        { columnName: 'productBrand', width: 120 },
        { columnName: 'productModel', width: 120 },
        { columnName: 'cantidadPresupuestada', width: 220 },
        { columnName: 'cantidadSolicitada', width: 240 },
        { columnName: 'cantidadCompra', width: 240 },
        { columnName: 'saldoDisponible', width: 240 },
        { columnName: 'montoPresupuestado', width: 240 },
        { columnName: 'montoCompra', width: 240 },
        { columnName: 'totalPresupuestado', width: 240 },
        { columnName: 'totalCompra', width: 240 },
        { columnName: 'disponiblePresupuesto', width: 240 },
        { columnName: 'ocerp', width: 120 },
        { columnName: 'Documento', width: 240 },
        { columnName: 'observacion', width: 240 },
        { columnName: 'status', width: 240 },
    ]);


    const [editingStateColumnExtensions] = useState([
        { columnName: 'sku', editingEnabled: false },
        { columnName: 'productName', editingEnabled: false },
        { columnName: 'category', editingEnabled: false },
        { columnName: 'subcategory', editingEnabled: false },
        { columnName: 'productBrand', editingEnabled: false },
        { columnName: 'productModel', editingEnabled: false },
        { columnName: 'totalPresupuestado', editingEnabled: false },
        { columnName: 'saldoDisponible', editingEnabled: false },
        { columnName: 'productUnits', editingEnabled: false },
        { columnName: 'cantidadPresupuestada', editingEnabled: false },
        { columnName: 'montoPresupuestado', editingEnabled: false },
        { columnName: 'totalPresupuestado', editingEnabled: false },
        { columnName: 'totalCompra', editingEnabled: false },
        { columnName: 'disponiblePresupuesto', editingEnabled: false },
    ]);

    const headers = [
        { label: "sku", key: "sku" },
        { label: "Nombre", key: "productName" },
        { label: "Cantidad solicitada", key: "cantidadSolicitada" },
        { label: "Modelo", key: "productModel" },
        { label: "Marca", key: "productBrand" },
        { label: "Obsevacion", key: "observacion" },
    ];

    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = data.length > 0
                ? Math.max(data[data.length - 1]._id, data[0]._id) + 1
                : 0;
            changedRows = [
                ...added.map((row, index) => ({
                    _id: startingAddedId + index,
                    ...row,
                })),
                ...data,
            ];
            setEditingCells([{ rowId: startingAddedId, columnName: columns[0].name }]);
        }
        if (changed) {
            // console.log(changed)
            changedRows = data.map(row => (changed[row._id] ? { ...row, ...changed[row._id] } : row));
        }
        if (deleted) {
            if (deleted[0].match(/^[0-9a-fA-F]{24}$/)) {

                let promise = new Promise((resolve, reject) => {
                    Swal.fire({
                        title: 'Estas seguro?',
                        text: "Esta acción no se puede revertir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, eliminar!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const config = {
                                headers: { 'Authorization': "Bearer " + token }
                            };

                            clienteAxios.delete(`/api/project/${projectId}/materialRequest/updateTable/${deleted[0]}`, config)
                                .then((res) => {
                                    // console.log(res.data)

                                    const deletedSet = new Set(deleted);
                                    changedRows = data.filter(row => !deletedSet.has(row._id));

                                    Toast.fire({ icon: 'success', title: 'Eliminado correctamente' })

                                    resolve("done!")
                                })
                                .catch(error => {
                                    if(error.response.status === 400){
                                        Swal.fire({ icon: 'error', title: 'Error', text:"La cantidad de compra debe ser 0 y ser guardado previo a la eliminación" })
                                    }else{
                                        Toast.fire({ icon: 'error', title: 'Error al eliminar' })
                                    }
                                    reject()
                                })
                        }
                    })
                });

                let result = await promise; // wait until the promise resolves (*)
                // console.log(result); // "done!"

            } else {
                const deletedSet = new Set(deleted);
                changedRows = data.filter(row => !deletedSet.has(row._id));

                Toast.fire({
                    icon: 'success',
                    title: 'Eliminado correctamente'
                })
            }
        }
        // console.log(changedRows)
        setData(changedRows ? changedRows : data);
    };



    useEffect(() => {
        // console.log(location.state)
        // if (location.state.redirect) {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/showTable`, config)
            .then((res) => {
                console.log(res.data)

                setExpandAll(res.data.arrayForTable)

                let response = []
                for (let i = 0; i < res.data.materialRequest.length; i++) {
                    const element = res.data.materialRequest[i];
                    response.push({
                        "sku": element.materialId.sku,
                        "productName": element.materialId.productName,
                        "category": element.materialId.category ? element.materialId.category : "",
                        "subcategory": element.materialId.subcategory ? element.materialId.subcategory : "",
                        "cotizar": element.estimateSent === "false" ? false : true,
                        "productUnits": element.materialId.productUnits,
                        "saldoDisponible": element.materialId.saldoDisponible,
                        "cantidadSolicitada": element.cantidadSolicitada,
                        "cantidadCompra": element.cantidadCompra,
                        "montoCompra": element.montoCompra,
                        "cantidadPresupuestada": element.materialId.cantidadPresupuestada,
                        "montoPresupuestado": element.materialId.montoPresupuestado,
                        "disponiblePresupuesto": element.materialId.disponiblePresupuesto,
                        "totalPresupuestado": element.materialId.totalPresupuestado,
                        "totalCompra": element.totalCompra,
                        "ocerp": element.ocerp,
                        "Documento": element.Documento,
                        "observacion": element.observacion,
                        "status": element.status,
                        "materialId": element.materialId._id,
                        "productModel": element.materialId.productModel,
                        "productBrand": element.materialId.productBrand,
                        "_id": element._id,
                    })
                }
                setData(response)





                var ver = document.querySelectorAll('button#edit.dx-g-bs4-table-edit-command-cell');
                [].forEach.call(ver, function (matche) {
                    matche.innerHTML = 'ver';
                });

                var borrar = document.querySelectorAll('button#delete.dx-g-bs4-table-edit-command-cell');
                [].forEach.call(borrar, function (matche) {
                    matche.innerHTML = 'eliminar';
                });
                var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                [].forEach.call(test, function (matche) {
                    matche.setAttribute("colspan", "11");
                });

            })
            .catch(error => {
                // console.log(error)
            })

        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data.projectId);
                setStatusToShowButton(res.data.status)
                setProjectId(res.data.projectId)
            })
            .catch(error => {
                // console.log(error)
            })

        // }
    }, [])
    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/ProjectDataAndNumber`, config)
            .then((res) => {
                // console.log(res.data)
                setMaterialRequestNumber(res.data.materialRequestNumber)
                setProjectName(res.data.project.projectName)
                setProjectNumber(res.data.project.projectNumber)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])



    const TabCell = props => (
        <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    );

    const BooleanEditor = ({
        onValueChange,
        value,
        autoFocus,
        onBlur,
        onKeyDown
    }) => {
        const handleChange = event => {
            const { checked } = event.target;
            onValueChange(checked);
        };

        return (
            <Form.Check
                checked={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={handleChange}
                value={true}
            />
        );
    };

    const BooleanFormatter = ({ value }) => {
        // console.log(value)
        let isTrueOrFalseSet;
        if (value === 'false') {
            isTrueOrFalseSet = false;
            // console.log(isTrueOrFalseSet)
        } else if (!value) {
            isTrueOrFalseSet = false;
            // console.log(isTrueOrFalseSet)
        } else if (value === 'true') {
            isTrueOrFalseSet = true;
            // console.log(isTrueOrFalseSet)
        } else {
            isTrueOrFalseSet = value;
            // console.log(isTrueOrFalseSet)
        }
        return (
            <span className="badge badge-secondary">
                {isTrueOrFalseSet === false ? 'No' : 'Si'}
            </span>
        )
    };

    const BooleanProvider = props => {
        return (
            <DataTypeProvider
                formatterComponent={BooleanFormatter}
                editorComponent={BooleanEditor}
                {...props}
            />
        );
    };

    // ---------------------------
    const availableValues = {
        status: ["pendiente", "cotizando", "OC enviada", "en bodega", "recibido"]
    };
    const LookupEditor = ({ value, onValueChange, column, onBlur, autoFocus }) => {
        // const classes = useLookupEditCellStyles();
        const availableColumnValues = availableValues[column.name];
        const handleChange = event => {
            onValueChange(event.target.value);
        };

        return (
            <Form.Control as="select"
                value={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onChange={handleChange}
                // MenuProps={{
                //     className: classes.selectMenu
                // }}
                input={<Form.Control />}
            >
                {
                    availableColumnValues.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))
                }
            </Form.Control>
        );
    };
    const LookupFormatter = props => (
        <DataTypeProvider
            editorComponent={params => <LookupEditor {...params} />}
            {...props}
        />
    );

    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (
                <p>
                    {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </p>
            )
        } else {
            return (
                <p>
                    {value}
                </p>
            )
        }
    };

    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    useEffect(() => {
        // console.log(data)
        let dataToQuote = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.cotizar) {
                dataToQuote.push(element)
            }
        }
        // console.log(dataToQuote)
        setDataToQuote(dataToQuote)
    }, [data])

    const [currencyColumns] = useState(['montoPresupuestado', 'totalPresupuestado', 'disponiblePresupuesto', 'montoCompra', 'totalCompra']);



    // ---------------------------------------------------------------------
    function FieldGroup({ id, label, ...props }) {
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...props} />
            </Form.Group>
        );
    }



    const Popup = ({
        row,
        onChange,
        onApplyChanges,
        onCancelChanges,
        open,
    }) => (
        <Modal className="p-5" show={open} onHide={onCancelChanges} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <h2>Información</h2>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="sku" label="Sku" value={row.sku} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="productName" label="Nombre" value={row.productName} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="productModel" label="Modelo" value={row.productModel} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="productBrand" label="Marca" value={row.productBrand} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <Form.Label>Cotizar</Form.Label>
                            <select name="cotizar" value={row.cotizar} onChange={onChange} disabled={false} className="form-control">
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="productUnits" label="Unidad" value={row.productUnits} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="number" name="saldoDisponible" label="Saldo disponible" value={row.saldoDisponible} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="number" name="cantidadSolicitada" label="Cantidad solicitada" value={row.cantidadSolicitada} onChange={onChange} disabled={false} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="number" name="cantidadCompra" label="Cantidad compra" value={row.cantidadCompra} onChange={onChange} disabled={false} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="number" name="montoCompra" label="Monto compra" value={row.montoCompra} onChange={onChange} disabled={false} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="number" name="cantidadPresupuestada" label="Cantidad presupuestada" value={row.cantidadPresupuestada} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="text" name="montoPresupuestado" label="Monto presupuestado" value={formatNumber.new(row.montoPresupuestado, "$")} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="text" name="disponiblePresupuesto" label="Disponible presupuesto" value={formatNumber.new(row.disponiblePresupuesto, "$")} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="text" name="totalPresupuestado" label="Total presupuestado" value={formatNumber.new(row.totalPresupuestado, "$")} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup type="number" name="totalCompra" label="Total compra" value={row.totalCompra} onChange={onChange} disabled={true} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="ocerp" label="Ocerp" value={row.ocerp} onChange={onChange} disabled={false} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <FieldGroup name="Documento" label="Documento" value={row.Documento} onChange={onChange} disabled={false} />
                        </Col>
                        <Col sm={4} className="px-2">
                            <Form.Label>Estado</Form.Label>
                            <select name="status" value={row.status} onChange={onChange} disabled={false} className="form-control">
                                <option value={'pendiente'}>pendiente</option>
                                <option value={'cotizando'}>cotizando</option>
                                <option value={'OC enviada'}>OC enviada</option>
                                <option value={'en bodega'}>en bodega</option>
                                <option value={'recibido'}>recibido</option>
                            </select>
                        </Col>
                        <Col sm={12} className="px-2">
                            <FieldGroup as="textarea" row={5} name="observacion" label="Observación" value={row.observacion} onChange={onChange} disabled={false} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancelChanges} className="text-light" variant="secondary">
                    Cancelar
                </Button>
                {' '}
                <Button onClick={onApplyChanges} className="text-light" variant="warning">
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );


    const PopupEditing = React.memo(({ popupComponent: Popup }) => (
        <Plugin>
            <Template name="popupEditing">
                <TemplateConnector>
                    {(
                        {
                            rows,
                            getRowId,
                            addedRows,
                            editingRowIds,
                            createRowChange,
                            rowChanges,
                        },
                        {
                            changeRow, changeAddedRow, commitChangedRows, commitAddedRows,
                            stopEditRows, cancelAddedRows, cancelChangedRows,
                        },
                    ) => {
                        const isNew = addedRows.length > 0;
                        let editedRow;
                        let rowId;
                        if (isNew) {
                            rowId = 0;
                            editedRow = addedRows[rowId];
                        } else {
                            [rowId] = editingRowIds;
                            const targetRow = rows.filter(row => getRowId(row) === rowId)[0];
                            editedRow = { ...targetRow, ...rowChanges[rowId] };
                        }

                        const processValueChange = ({ target: { name, value } }) => {
                            const changeArgs = {
                                rowId,
                                change: createRowChange(editedRow, value, name),
                            };
                            if (isNew) {
                                changeAddedRow(changeArgs);
                            } else {
                                changeRow(changeArgs);
                            }
                        };
                        const rowIds = isNew ? [0] : editingRowIds;
                        const applyChanges = () => {
                            if (isNew) {
                                commitAddedRows({ rowIds });
                            } else {
                                stopEditRows({ rowIds });
                                commitChangedRows({ rowIds });
                            }
                        };
                        const cancelChanges = () => {
                            if (isNew) {
                                cancelAddedRows({ rowIds });
                            } else {
                                stopEditRows({ rowIds });
                                cancelChangedRows({ rowIds });
                            }
                        };

                        const open = editingRowIds.length > 0 || isNew;
                        return (
                            <Popup
                                open={open}
                                row={editedRow}
                                onChange={processValueChange}
                                onApplyChanges={applyChanges}
                                onCancelChanges={cancelChanges}
                            />
                        );
                    }}
                </TemplateConnector>
            </Template>
            <Template name="root">
                <TemplatePlaceholder />
                <TemplatePlaceholder name="popupEditing" />
            </Template>
        </Plugin>
    ));


    const recounGroup = () => {
        // console.log('test')
        setTimeout(() => {
            var test = document.querySelectorAll('.dx-g-bs4-group-cell');
            [].forEach.call(test, function (matche) {
                matche.setAttribute("colspan", "11");
            });
        }, 100);
    }
    const [scrollY, setScrollX] = useState(0);

    function logit() {
        let px = document.querySelector('.table-responsive').scrollLeft;
        setScrollX(px)
        var row = document.querySelectorAll('.dx-g-bs4-group-cell');
        [].forEach.call(row, function (matche) {
            matche.setAttribute("colspan", "11");
        });
    }

    useEffect(() => {
        return () => {
            document.querySelector('.table-responsive').addEventListener("scroll", logit);
        };
    });

    const [grouping, setGrouping] = useState([{ columnName: 'category' }, { columnName: 'subcategory' }])
    const [expandedGroups, setExpandedGroups] = useState([])

    const changeExpandedGroups = (expand) => {
        recounGroup()
        setExpandedGroups(expand);
    }

    const OpenAlls = () => {
        recounGroup()
        setExpandedGroups(expandAll);
    }
    const CloseAlls = () => {
        recounGroup()
        setExpandedGroups([]);
    }



    // const HighlightedCell = ({ isRecibido, value, style, ...restProps }) => {
    //     return (
    //         <Table.Cell
    //             {...restProps}
    //             style={{
    //                 // backgroundColor: isRecibido ? '#dff0d8' : '',
    //             }}
    //         >
    //             <span>
    //                 {value}
    //             </span>
    //         </Table.Cell>
    //     )
    // }

    // const Cell = (props) => {
    //     const { column } = props;
    //     let isRecibido = false
    //     if (column.name === 'status') {
    //         if (props.row.status === 'recibido') {
    //             isRecibido = true
    //         }
    //     }

    //     return <HighlightedCell isRecibido={isRecibido} {...props} />;
    // };



    const LookupEditInput = (props) => {
        const handleChange = event => {
            props.onValueChange(event.target.value);
        };
        const handleChangeNull = event => {

        };
        // console.log(props)
        return (
            <Form.Control
                value={props.value}
                autoFocus={props.autoFocus}
                onBlur={props.onBlur}
                onChange={props.row.status === 'recibido' ? handleChangeNull : handleChange}
                input={<Form.Control />}
            // disabled={}
            />

        );
    };
    const LookupStatusEditingInput = props => {
        // console.log(props)
        return (
            <DataTypeProvider
                editorComponent={params => <LookupEditInput {...params} />}
                {...props}
            />
        )
    };

    return (
        <Layout>
            <>
                <Topbar title={`Proyecto: ${projectName} | Número de Solicitud: ${materialRequestNumber}`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card">
                            <Grid rows={data} columns={columns} getRowId={getRowId}>
                                <CurrencyTypeProvider for={currencyColumns} />
                                <GroupingState
                                    grouping={grouping}
                                    expandedGroups={expandedGroups}
                                    onExpandedGroupsChange={(e) => changeExpandedGroups(e)}
                                />
                                <IntegratedGrouping />
                                <EditingState onCommitChanges={commitChanges} editingCells={editingCells} onEditingCellsChange={setEditingCells} addedRows={[]} onAddedRowsChange={addEmptyRow} columnExtensions={editingStateColumnExtensions} />
                                <SearchState />
                                <IntegratedFiltering />
                                <Table cellComponent={FocusableCell} />
                                <VirtualTable height={670} />
                                {/* <VirtualTable height={670} cellComponent={Cell} /> */}
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <TableGroupRow />
                                <TableInlineCellEditing startEditAction={startEditAction} selectTextOnEditStart={selectTextOnEditStart} />
                                <BooleanProvider for={booleanColumns} />
                                <LookupFormatter for={lookupColumns} />
                                <LookupStatusEditingInput for={editingStatusCell} />
                                {data.length > 1 ?
                                    <TableEditColumn showEditCommand showDeleteCommand />
                                    :
                                    <TableEditColumn showEditCommand />
                                }
                                <AutoComplete addItemToTable={addItemToTable} quote openAllsCategory OpenAlls={OpenAlls} />
                                <SearchPanel />
                                <TableFixedColumns
                                    leftColumns={leftColumns}
                                    // rightColumns={rightColumns}
                                />
                                <PopupEditing popupComponent={Popup} />

                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar
                    saveQuote
                    quote
                    gestionar
                    quoteData={data}
                    projectId={projectId}
                    history={history}
                    headerToQuoteCSV={headers}
                    // dataToQuoteCSV={dataToQuote}
                    dataToQuoteCSV={data}
                    materialRequestNumber={materialRequestNumber}
                    projectNumber={projectNumber}
                />
            </>
        </Layout>
    );
}

export default ManagerMaterialPurchaseView;