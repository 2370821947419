import * as React from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { ContainerInputFile, Input, Label } from './../extras/FormInputs';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import UploadIconImg from './../../resources/images/page/upload-icon-black.svg'
import { UploadIconAction, UploadBgAction, UploadLogoAction } from './../../redux/actions/UploadImageStyleCompany';
// redux
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../structures/Layout";

const EditStyleCompany = () => {
    const token = useSelector(state => state.auth.token)
    const companyId = useSelector(state => state.auth.company)
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UploadIcon = (event, token, companyId) => dispatch(UploadIconAction(event, token, companyId));
    const UploadBg = (event, token, companyId) => dispatch(UploadBgAction(event, token, companyId));
    const UploadLogo = (event, token, companyId) => dispatch(UploadLogoAction(event, token, companyId));

    // handles
    const onChangeHandlerIcon = event => {
        UploadIcon(event, token, companyId)
    }
    const onChangeHandlerBg = event => {
        UploadBg(event, token, companyId)
    }
    const onChangeHandlerLogo = event => {
        UploadLogo(event, token, companyId)
    }


    return (
        <Layout>
            <>
                <Topbar title="Editar estilo | Compañia" />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start">
                        {/* <Col xs={12} md={6}>
                                <ContainerInputFile>
                                    <p className="mb-3">Icono de la compañia</p>
                                    <Label
                                        htmlFor="imagenIcono">
                                        <img src={UploadIconImg} width="40" height="40" />
                                    </Label>
                                    <input
                                        type="file"
                                        id="imagenIcono"
                                        css={css`visibility:hidden !important;`}
                                        name="file" onChange={onChangeHandlerIcon}
                                        accept="image/jpg, image/jpeg"
                                    />
                                </ContainerInputFile>
                                <ContainerInputFile className="mt-5">
                                    <p className="mb-3">Background de la compañia</p>
                                    <Label
                                        htmlFor="imageBackground">
                                        <img src={UploadIconImg} width="40" height="40" />
                                    </Label>
                                    <input
                                        type="file"
                                        id="imageBackground"
                                        css={css`visibility:hidden !important;`}
                                        name="file" onChange={onChangeHandlerBg}
                                        accept="image/jpg, image/jpeg"
                                    />
                                </ContainerInputFile>
                            </Col> */}
                        <Col xs={12} md={6}>
                            <ContainerInputFile>
                                <p className="mb-3">Logo para cotizaciones</p>
                                <Label
                                    htmlFor="imageLogo">
                                    <img src={UploadIconImg} width="40" height="40" />
                                </Label>
                                <input
                                    type="file"
                                    id="imageLogo"
                                    css={css`visibility:hidden !important;`}
                                    name="file" onChange={onChangeHandlerLogo}
                                    accept="image/jpg, image/jpeg"
                                />
                            </ContainerInputFile>
                        </Col>
                    </Form>
                </Row>
            </>
        </Layout>
    );
}

export default EditStyleCompany;