import { UPLOAD_FILE_CATEGORY_SUBCATEGORY_START, UPLOAD_FILE_CATEGORY_SUBCATEGORY_SUCCESS, UPLOAD_FILE_CATEGORY_SUBCATEGORY_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UploadCategorySUbcategoryFileAction(event, id, token, setRefreshData) {
    return (dispatch) => {
        dispatch(UploadFileCategorySubcategoryStart())

        let fl = new FormData();
        fl.append("excelFile", event.target.files[0]);

        // clienteAxios.post(`/api/project/${id}/categoryAndSubcategory/excel`, fl, {
        clienteAxios.put(`/api/project/${id}/updateTable/categoriesAndSubcategoriesWithExcel`, fl, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        })
            .then((res) => {
                // console.log(res.data.products)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha cargado el archivo correctamente.' })
                // dispatch(UploadFileCategorySubcategorySuccess(res.data.products))
                setRefreshData(1)
            })
            .catch(error => {
                dispatch(UploadFileCategorySubcategoryFail())
                // console.log(error.response)
            })
    }
}


export const UploadFileCategorySubcategoryStart = () => {
    return {
        type: UPLOAD_FILE_CATEGORY_SUBCATEGORY_START
    };
};

export const UploadFileCategorySubcategorySuccess = () => ({
    type: UPLOAD_FILE_CATEGORY_SUBCATEGORY_SUCCESS,
    // payload: products
})

export const UploadFileCategorySubcategoryFail = () => ({
    type: UPLOAD_FILE_CATEGORY_SUBCATEGORY_FAIL,
})
