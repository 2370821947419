import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import clienteAxios from './../../config/axios';
import { useSelector } from 'react-redux';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';


const ManagerUsers = ({ history }) => {

    const token = useSelector(state => state.auth.token);
    // obteniendo la data de los proyectos creados
    const [data, setData] = useState([])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get('/api/users', config)
            .then((res) => {
                // console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const handlerProduct = (id) => {
        history.push(`/gestion-de-usuarios/editar/${id}`)
    }

    return (
        <Layout>
            <>
                <Topbar title="Gestion de usuarios" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>RUT</th>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Email</th>
                                    <th>Rol</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((user, i) => (
                                    <tr key={i} css={css`cursor:pointer;`} onClick={() => handlerProduct(user._id)}>
                                        <td>{user.rut ? user.rut.toString().slice(0, -1) + "-" + user.rut.toString().slice(-1) : ""}</td>
                                        <td>{user.name}</td>
                                        <td>{user.lastname}</td>
                                        <td>{user.email}</td>
                                        <td>{user.role}</td>
                                        <td>{user.isDeleted ? "Eliminado" : "Activo"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar create />
            </>
        </Layout>
    );
}

export default ManagerUsers;