import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom';

//Bootstrap
import { Col, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCloudUploadAlt,
    faCommentAlt,
    faFolder, faImage,
    faLayerGroup, faLock,
    faSignOutAlt, faStream,
    faThLarge, faTruck,
    faCheckSquare, faShoppingCart,
    faBars, faPen, faChartBar, faUser, faTags, faSquare, faTh, faUsers
} from '@fortawesome/free-solid-svg-icons'
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useLocation, useHistory } from "react-router-dom";
// redux
import { useDispatch, useSelector } from 'react-redux';
import { LogoutAction } from "../../redux/actions/AuthAction";
import clienteAxios from "../../config/axios";

const NavbarNavigation = () => {
    const user_role = useSelector(state => state.auth.user_role)
    const token = useSelector(state => state.auth.token)
    const user_id = useSelector(state => state.auth.user_id)
    const users = useSelector(state => state.showPageByRol.pagesByRol)
    const location = useLocation();
    let history = useHistory();
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const LogoutFn = (token, history) => dispatch(LogoutAction(token, history));

    // const [users, setUsers] = useState(null);

    useEffect(() => {
        if (!token) {
            history.push('/')
        }
    }, [token])

    


    return (
        <React.Fragment>
            {users && users.dashboard.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Dashboard
                            </Tooltip>
                        }>
                        <NavLink to='/admin-dashboard' className={`itemMenu ${location.pathname.includes('/admin-dashboard') ? "activeItem" : ""}`}><FontAwesomeIcon icon={faThLarge} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.administrarProyectos.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Administrar proyectos
                            </Tooltip>
                        }>
                        <NavLink to='/admin-proyectos' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faLayerGroup} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.solicitudDeMaterial.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Solicitud de material
                            </Tooltip>
                        }>
                        <NavLink to='/proyectos' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faTruck} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.aprobarSolicitudDeMaterial.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Aprobar solicitud de material
                            </Tooltip>
                        }>
                        <NavLink to='/aprobar-solicitud-de-material' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faCheckSquare} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.gestionarComprasDeMaterial.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Gestionar compras del material
                            </Tooltip>
                        }>
                        <NavLink to='/gestionar-compras-de-material' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faShoppingCart} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.informes.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Informes
                            </Tooltip>
                        }>
                        <NavLink to='/informe' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faChartBar} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.logs.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Logs
                            </Tooltip>
                        }>
                        <NavLink to='/logs' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faFolder} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.gestionDeUsuarios.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Gestion de usuarios
                            </Tooltip>
                        }>
                        <NavLink to='/gestion-de-usuarios' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faUser} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.gestionDeClientes.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Clientes
                            </Tooltip>
                        }>
                        <NavLink to='/clientes' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faUsers} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.perfil.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Perfil
                            </Tooltip>
                        }>
                        <NavLink to={`/gestion-de-usuarios/editar/${user_id}`} className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faUser} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.editarEstiloDeEmpresa.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Editar estilo de Empresa
                            </Tooltip>
                        }>
                        <NavLink to='/editar-estilo-empresa' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faPen} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.areas.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Areas
                            </Tooltip>
                        }>
                        <NavLink to='/agregar-etiquetas' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faTags} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>
                :
                <div></div>
            }
            {users && users.gestionDeEmpresas.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Gestion de empresas
                            </Tooltip>
                        }>
                        <NavLink to='/gestion-de-empresas' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faCloudUploadAlt} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>

                :
                <div></div>
            }
            {users && users.gestionDeVistasPorUsuario.includes(user_role) ?
                <Col xs={12} className="text-center pt-3 pt-lg-3 px-0">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="button-tooltip">
                                Gestion de vistas por usuario
                            </Tooltip>
                        }>
                        <NavLink to='/gestionar-vistas-por-usuarios' className={`itemMenu`} activeClassName="active_icon"><FontAwesomeIcon icon={faTh} size="2x" /></NavLink>
                    </OverlayTrigger>
                </Col>

                :
                <div></div>
            }
            <Col xs={12} className="text-center pt-3 pt-lg-5 px-0">
                <NavLink exact to='#' className={`itemMenu`} onClick={() => LogoutFn(token, history)}><FontAwesomeIcon icon={faSignOutAlt} size="2x" /></NavLink>
            </Col>
        </React.Fragment>
    );
}

export default NavbarNavigation;