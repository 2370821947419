import React, { useEffect, useState } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { EditingState, PagingState, IntegratedPaging, DataTypeProvider, SearchState, IntegratedFiltering, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableInlineCellEditing, Toolbar, TableColumnResizing, TableFixedColumns, TableEditRow, PagingPanel, SearchPanel, TableGroupRow, VirtualTable, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import { Plugin, Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AutoComplete from './../extras/autocompleteUpdate';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import Layout from "../structures/Layout";
const getRowId = row => row._id;

// #FOLD_BLOCK
const StartEditActionSelector = (props) => {
    const { defaultAction, changeAction } = props;
    return (
        <div>
            {'Iniciar edición:'}
            &nbsp;
            <select
                defaultValue={defaultAction}
                onChange={e => changeAction(e.target.value)}
                className="dropdown"
            >
                <option value="click">Click</option>
                <option value="doubleClick">Doble Click</option>
            </select>
        </div>
    );
};

// #FOLD_BLOCK
const SelectTextChecker = (props) => {
    const { isSelectText, changeSelectText } = props;
    return (
        <div
            style={{
                padding: '0em 1em',
            }}
        >
            <label htmlFor="selectTextChecker" className="form-check-label">
                <input
                    type="checkbox"
                    checked={isSelectText}
                    id="selectTextChecker"
                    name="selectTextChecker"
                    className="form-check-input"
                    onChange={e => changeSelectText(e.target.checked)}
                />
                Seleccionar texto al activar
            </label>
        </div>
    );
};

const EditPropsPanel = props => (
    <Plugin name="EditPropsPanel">
        <Template name="toolbarContent">
            <SelectTextChecker {...props} />
            <TemplatePlaceholder />
            <StartEditActionSelector {...props} />
        </Template>
    </Plugin>
);

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);



const ManagerProjectProducts = ({ history }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    const user_role = useSelector(state => state.auth.user_role)
    //DISPATCH
    const dispatch = useDispatch();

    const [data, setData] = useState([])
    const [editingCells, setEditingCells] = useState([]);
    const addEmptyRow = () => commitChanges({ added: [{}] });
    const [statusToShowButton, setStatusToShowButton] = useState("")
    const [projectId, setProjectId] = useState("")
    const [materialRequestNumber, setMaterialRequestNumber] = useState("")
    const [projectName, setProjectName] = useState("")
    const [booleanColumns] = React.useState(["cotizar"]);
    const [lookupColumns] = React.useState(["status"]);
    const [leftColumns] = useState(['sku', 'productName']);
    const [startEditAction, setStartEditAction] = useState('click');
    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);
    const [categoriesAndSUbcategories, setCategoriesAndSUbcategories] = useState([]);
    const [expandAll, setExpandAll] = useState([]);

    const addItemToTable = (Item) => {
        if (Item) {
            for (let j = 0; j < Item.length; j++) {
                const arraytoadd = Item[j];
                if (data.length === 0) {
                    setData(state => [...state, arraytoadd])
                    Toast.fire({ icon: 'success', title: 'Productos agregados' })
                } else {
                    let include = false;
                    for (let i = 0; i < data.length; i++) {
                        const element = data[i];
                        if (arraytoadd.productName === element.productName) {
                            include = true
                        }
                    }
                    if (include) {
                        Toast.fire({ icon: 'error', title: 'El producto ya se encuentra agregado' })
                    } else {
                        setData(state => [...state, arraytoadd])
                        Toast.fire({ icon: 'success', title: 'Productos agregados' })
                    }
                }
                setTimeout(() => {
                    var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                    [].forEach.call(test, function (matche) {
                        matche.setAttribute("colspan", "11");
                    });
                }, 100);
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Opps!', text: 'Seleccione un producto' })
        }
    }

    const [columns] = useState([
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Producto ' },
        { name: 'category', title: 'Categoria' },
        { name: 'subcategory', title: 'Subcategoria' },
        // { name: 'cotizar', title: 'Cotizar  ' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'cantidadPresupuestada', title: 'Cantidad Presupuestada' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        // { name: 'cantidadCompra', title: 'Cantidad compra' },
        { name: 'montoPresupuestado', title: 'Monto presupuestado' },
        { name: 'montoCompra', title: 'Monto Compra' },
        { name: 'totalPresupuestado', title: 'Total presupuestado' },
        { name: 'disponiblePresupuesto', title: 'Disponible presupuesto' },
        // { name: 'ocerp', title: 'OC ERP' },
        // { name: 'Documento', title: 'Documento' },
        { name: 'observacion', title: 'Observacion' },
        // { name: 'status', title: 'Estado' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'sku', width: 80 },
        { columnName: 'productName', width: 150 },
        { columnName: 'category', width: 300 },
        { columnName: 'subcategory', width: 300 },
        // { columnName: 'cotizar', width: 120 },
        { columnName: 'productUnits', width: 120 },
        { columnName: 'productBrand', width: 120 },
        { columnName: 'productModel', width: 120 },
        { columnName: 'cantidadPresupuestada', width: 200 },
        { columnName: 'cantidadSolicitada', width: 200 },
        // { columnName: 'cantidadCompra', width: 240 },
        { columnName: 'saldoDisponible', width: 200 },
        { columnName: 'montoPresupuestado', width: 200 },
        { columnName: 'montoCompra', width: 200 },
        { columnName: 'totalPresupuestado', width: 200 },
        { columnName: 'disponiblePresupuesto', width: 200 },
        // { columnName: 'ocerp', width: 120 },
        // { columnName: 'Documento', width: 240 },
        { columnName: 'observacion', width: 200 },
        // { columnName: 'status', width: 240 },
    ]);


    const [editingStateColumnExtensions] = useState([
        { columnName: 'sku', editingEnabled: false },
        { columnName: 'productName', editingEnabled: false },
        { columnName: 'productUnits', editingEnabled: false },
        { columnName: 'productBrand', editingEnabled: false },
        { columnName: 'productModel', editingEnabled: false },
        { columnName: 'cantidadPresupuestada', editingEnabled: false },
        { columnName: 'saldoDisponible', editingEnabled: false },
        { columnName: 'cantidadSolicitada', editingEnabled: true },
        { columnName: 'montoPresupuestado', editingEnabled: false },
        { columnName: 'montoCompra', editingEnabled: false },
        { columnName: 'totalPresupuestado', editingEnabled: false },
        { columnName: 'disponiblePresupuesto', editingEnabled: false },
        { columnName: 'observacion', editingEnabled: true },
    ]);

    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = data.length > 0
                ? Math.max(data[data.length - 1]._id, data[0]._id) + 1
                : 0;
            changedRows = [
                ...added.map((row, index) => ({
                    _id: startingAddedId + index,
                    ...row,
                })),
                ...data,
            ];
            setEditingCells([{ rowId: startingAddedId, columnName: columns[0].name }]);
        }
        if (changed) {
            // console.log(changed)
            changedRows = data.map(row => (changed[row._id] ? { ...row, ...changed[row._id] } : row));
        }
        if (deleted) {
            if (deleted[0].match(/^[0-9a-fA-F]{24}$/)) {

                let promise = new Promise((resolve, reject) => {
                    Swal.fire({
                        title: 'Estas seguro?',
                        text: "Esta acción no se puede revertir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, eliminar!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const config = {
                                headers: { 'Authorization': "Bearer " + token }
                            };

                            clienteAxios.delete(`/api/project/${projectId}/materialRequest/updateTable/${deleted[0]}`, config)
                                .then((res) => {
                                    // console.log(res.data)

                                    const deletedSet = new Set(deleted);
                                    changedRows = data.filter(row => !deletedSet.has(row._id));

                                    Toast.fire({ icon: 'success', title: 'Eliminado correctamente' })

                                    resolve("done!")
                                })
                                .catch(error => {
                                    if(error.response.status === 400){
                                        Swal.fire({ icon: 'error', title: 'Error', text:"La cantidad de compra debe ser 0 y ser guardado previo a la eliminación" })
                                    }else{
                                        Toast.fire({ icon: 'error', title: 'Error al eliminar' })
                                    }
                                    reject()
                                })
                        }
                    })
                });

                let result = await promise; // wait until the promise resolves (*)
                // console.log(result); // "done!"

            } else {
                const deletedSet = new Set(deleted);
                changedRows = data.filter(row => !deletedSet.has(row._id));

                Toast.fire({
                    icon: 'success',
                    title: 'Eliminado correctamente'
                })
            }

        }
        // console.log(changedRows)
        setData(changedRows ? changedRows : data);
    };



    useEffect(() => {
        // console.log(location.state)
        // if (location.state.redirect) {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/showTable`, config)
            .then((res) => {
                // console.log(res.data.arrayForTable)

                setExpandAll(res.data.arrayForTable)

                let response = []
                for (let i = 0; i < res.data.materialRequest.length; i++) {
                    const element = res.data.materialRequest[i];
                    response.push({
                        "sku": element.materialId.sku,
                        "productName": element.materialId.productName,
                        "category": element.materialId.category ? element.materialId.category : "",
                        "subcategory": element.materialId.subcategory ? element.materialId.subcategory : "",
                        "productUnits": element.materialId.productUnits,
                        "productBrand": element.materialId.productBrand,
                        "productModel": element.materialId.productModel,
                        "saldoDisponible": element.materialId.saldoDisponible,
                        "cantidadSolicitada": element.cantidadSolicitada,
                        "cantidadCompra": element.cantidadCompra,
                        "montoCompra": element.montoCompra,
                        "cantidadPresupuestada": element.materialId.cantidadPresupuestada,
                        "montoPresupuestado": element.materialId.montoPresupuestado,
                        "disponiblePresupuesto": element.materialId.disponiblePresupuesto,
                        "totalPresupuestado": element.materialId.totalPresupuestado,
                        "ocerp": element.ocerp,
                        "Documento": element.Documento,
                        "observacion": element.observacion,
                        "status": element.status,
                        "materialId": element.materialId._id,
                        "_id": element._id,
                    })
                }
                setData(response)

                // var ver = document.querySelectorAll('button#edit.dx-g-bs4-table-edit-command-cell');
                // [].forEach.call(ver, function (matche) {
                //     matche.innerHTML = 'ver';
                // });
                setTimeout(() => {
                    var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                    [].forEach.call(test, function (matche) {
                        matche.setAttribute("colspan", "11");
                    });
                }, 100);

                var borrar = document.querySelectorAll('button#delete.dx-g-bs4-table-edit-command-cell');
                [].forEach.call(borrar, function (matche) {
                    matche.innerHTML = 'eliminar';
                });
                // console.log(matches)
            })
            .catch(error => {
                // console.log(error)
            })

        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data);
                setStatusToShowButton(res.data.status)
                setProjectId(res.data.projectId)
            })
            .catch(error => {
                // console.log(error)
            })

        // }
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/ProjectDataAndNumber`, config)
            .then((res) => {
                // console.log(res.data)
                setMaterialRequestNumber(res.data.materialRequestNumber)
                setProjectName(res.data.project.projectName)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const BooleanEditor = ({
        onValueChange,
        value,
        autoFocus,
        onBlur,
        onKeyDown
    }) => {
        const handleChange = event => {
            const { checked } = event.target;
            onValueChange(checked);
        };

        return (
            <Form.Check
                checked={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={handleChange}
                value={true}
            />
        );
    };

    const BooleanFormatter = ({ value }) => (
        <span className="badge badge-secondary">
            {value ? 'Si' : 'No'}
        </span>
    );

    const BooleanProvider = props => {
        return (
            <DataTypeProvider
                formatterComponent={BooleanFormatter}
                editorComponent={BooleanEditor}
                {...props}
            />
        );
    };

    // ---------------------------
    const availableValues = {
        status: ["pendiente", "cotizando", "OC enviada", "en bodega", "recibido"]
    };
    const LookupEditor = ({ value, onValueChange, column, onBlur, autoFocus }) => {
        // const classes = useLookupEditCellStyles();
        const availableColumnValues = availableValues[column.name];
        const handleChange = event => {
            onValueChange(event.target.value);
        };

        return (
            <Form.Control as="select"
                value={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onChange={handleChange}
                // MenuProps={{
                //     className: classes.selectMenu
                // }}
                input={<Form.Control />}
            >
                {availableColumnValues.map(item => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </Form.Control>
        );
    };
    const LookupFormatter = props => (
        <DataTypeProvider
            editorComponent={params => <LookupEditor {...params} />}
            {...props}
        />
    );

    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (<p>{value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>)
        } else {
            return (<p>  {value} </p>)
        }
    };

    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['montoPresupuestado', 'totalPresupuestado', 'disponiblePresupuesto', 'montoCompra']);


    const recounGroup = () => {
        // console.log('test')
        setTimeout(() => {
            var test = document.querySelectorAll('.dx-g-bs4-group-cell');
            [].forEach.call(test, function (matche) {
                matche.setAttribute("colspan", "11");
            });
        }, 100);
    }
    const [scrollY, setScrollX] = useState(0);

    function logit() {
        let px = document.querySelector('.table-responsive').scrollLeft;
        setScrollX(px)
        var row = document.querySelectorAll('.dx-g-bs4-group-cell');
        [].forEach.call(row, function (matche) {
            matche.setAttribute("colspan", "11");
        });
    }

    useEffect(() => {
        return () => {
            document.querySelector('.table-responsive').addEventListener("scroll", logit);
        };
    });

    const [grouping, setGrouping] = useState([{ columnName: 'category' }, { columnName: 'subcategory' }])
    const [expandedGroups, setExpandedGroups] = useState([])

    const changeExpandedGroups = (expand) => {
        recounGroup()
        setExpandedGroups(expand);
    }

    const OpenAlls = () => {
        recounGroup()
        setExpandedGroups(expandAll);
    }
    const CloseAlls = () => {
        recounGroup()
        setExpandedGroups([]);
    }

    return (
        <Layout>
            <>
                <Topbar title={`Proyecto: ${projectName} | Número de Solicitud: ${materialRequestNumber}`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>

                        <div className="card">
                            <Grid rows={data} columns={columns} getRowId={getRowId}>
                                <CurrencyTypeProvider for={currencyColumns} />
                                <GroupingState
                                    grouping={grouping}
                                    expandedGroups={expandedGroups}
                                    onExpandedGroupsChange={(e) => changeExpandedGroups(e)}
                                />
                                <IntegratedGrouping />
                                <EditingState onCommitChanges={commitChanges} editingCells={editingCells} onEditingCellsChange={setEditingCells} addedRows={[]} onAddedRowsChange={addEmptyRow} columnExtensions={editingStateColumnExtensions} />
                                <SearchState />
                                <IntegratedFiltering />
                                <Table cellComponent={FocusableCell} />
                                <VirtualTable height={670} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <TableGroupRow />
                                <TableInlineCellEditing startEditAction={startEditAction} selectTextOnEditStart={selectTextOnEditStart} />
                                <BooleanProvider for={booleanColumns} />
                                <LookupFormatter for={lookupColumns} />
                                {statusToShowButton === "aprobada" || statusToShowButton === "rechazada" || statusToShowButton === "cerradas" ?
                                    <React.Fragment></React.Fragment>
                                    :
                                    user_role === "gerente" || user_role === "aprobador" ?
                                        <TableEditColumn showDeleteCommand />
                                        :
                                        <React.Fragment></React.Fragment>
                                }
                                <AutoComplete addItemToTable={addItemToTable} quote openAllsCategory OpenAlls={OpenAlls} />
                                <TableFixedColumns leftColumns={leftColumns} />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar
                    saveQuote
                    declined
                    pass
                    quoteData={data}
                    projectId={projectId}
                    history={history}
                    statusToShowButton={statusToShowButton}
                />
            </>
        </Layout>
    );
}

export default ManagerProjectProducts;