import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import Layout from "../structures/Layout";

const CompanyManagment = ({ history }) => {

    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token)

    const [data, setData] = useState([])

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };

        clienteAxios.get(`/api/company`, config)
            .then((res) => {
                // console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const ViewFn = (id) => {
        history.push(`/gestion-de-empresas/editar/${id}`)
    }

    return (
        <Layout>
            <>
                <Topbar title="Gestion de empresas" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Empresa</th>
                                    <th>Administrador</th>
                                    <th>Email</th>
                                    <th>Cantidad<br />de usuarios</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((project, i) => (
                                    <tr key={i} css={css`cursor: pointer;`} onClick={() => ViewFn(project._id)}>
                                        <td>{project.companyName}</td>
                                        <td>{project.ownerId.name + " " + project.ownerId.lastname}</td>
                                        <td>{project.ownerId.email}</td>
                                        <td>{project.userQty}</td>
                                        <td>{project.isActive ? "activo" : "inactivo"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default CompanyManagment;