import * as React from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarFilters'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Layout from "../structures/Layout";

const BuyerProjects = () => {

    return (
        <Layout>
            <>
                <Topbar />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Solicitud</th>
                                    <th>Proyecto</th>
                                    <th>Supervisor</th>
                                    <th>Estado<br />General</th>
                                    <th>Avance</th>
                                    <th>Días</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>8090 CEN</td>
                                    <td>David Toro</td>
                                    <td>Pendiente</td>
                                    <td>0%</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>8090 CEN</td>
                                    <td>David Toro</td>
                                    <td>Enviada</td>
                                    <td>0%</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>8090 CEN</td>
                                    <td>David Toro</td>
                                    <td>Aprobada</td>
                                    <td>0%</td>
                                    <td>5</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>8090 CEN</td>
                                    <td>David Toro</td>
                                    <td>Cerrada</td>
                                    <td>0%</td>
                                    <td>5</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar />
            </>

        </Layout>
    );
}

export default BuyerProjects;