import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { ContainerInputFile, Input, Label } from './../extras/FormInputs';
import UploadIconImg from './../../resources/images/page/upload-icon-black.svg'
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// redux
import { useSelector, useDispatch } from 'react-redux'
// alert
import Swal from 'sweetalert2';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// import { findAllByRole } from "@testing-library/dom";
import Fn from './../extras/validateRUT';
import { UpdateCompanyFnAction } from './../../redux/actions/UpdateCompanyFnAction';
import Layout from "../structures/Layout";
const UpdateCompany = ({ history }) => {
    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    const user_role = useSelector(state => state.auth.user_role)
    const [IsCheked, setIsCheked] = useState(false)
    const [formdata, setFormData] = useState({
        companyName: '',
        name: '',
        lastname: '',
        rut: '',
        email: '',
        businessName: '',
        businessEmailForContact: '',
        address: '',
        comuna: '',
        country: '',
        companyRut: '',
        urlName: '',
        isActive: ''
    })

    const {
        companyName,
        name,
        lastname,
        rut,
        email,
        businessName,
        businessEmailForContact,
        address,
        comuna,
        country,
        companyRut,
        // urlName,
        isActive
    } = formdata;
    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const BusinessUpdateFn = (formdata, history, id, token) => dispatch(UpdateCompanyFnAction(formdata, history, id, token));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }
    const setStatusCompany = (value) => {
        // console.log(value)
        setFormData({
            ...formdata,
            isActive: value
        })
    }

    const handleSubmit = async () => {
        if (companyName === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre de la compañia requerido' })
        } else if (name === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del administrador requerido' })
        } else if (lastname === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Apellido del administrador requerido' })
        } else if (rut === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'RUT del administrador requerido' })
        } else if (email === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Email del administrador requerido' })
        } else if (businessName === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre de la emrpesa requerido' })
        } else if (businessEmailForContact === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Email de contacto de la empresa requerido' })
        } else if (address === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Direccion de la empresa requerido' })
        } else if (comuna === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Comuna requerido' })
        } else if (country === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Pais requerido' })
        } else if (companyRut === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'RUT de la compañia requerido' })
        }
        // else if (urlName === "") {
        //     Swal.fire({ icon: 'error', title: 'Lo siento', text: 'URL de la pagina requerido' })
        // } 
        else {
            // console.log(formdata)
            if (Fn.validaRut(rut) && Fn.validaRut(companyRut)) {
                let newRut = rut.split('-')
                let newCompanyRut = companyRut.split('-')
                let body = {
                    address: address,
                    businessEmailForContact: businessEmailForContact,
                    businessName: businessName,
                    companyName: companyName,
                    companyRut: newCompanyRut[0] + newCompanyRut[1],
                    comuna: comuna,
                    country: country,
                    email: email,
                    isActive: isActive,
                    lastname: lastname,
                    name: name,
                    rut: newRut[0] + newRut[1],
                    // urlName: urlName,
                }
                BusinessUpdateFn(body, history, id, token)
                // console.log(body)
            } else {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut invalido' })
            }
        }
    }

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/company/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                // setData(res.data)
                let data = res.data;
                // rut administrador
                let afterNRut = data.ownerId.rut.toString().slice(-1)
                let beforeNRut = data.ownerId.rut.toString().slice(0, -1)
                // rut compania
                let afterNRutC = data.companyRut.toString().slice(-1)
                let beforeNRutC = data.companyRut.toString().slice(0, -1)


                setFormData({
                    companyName: data.companyName,
                    name: data.ownerId.name,
                    lastname: data.ownerId.lastname,
                    rut: beforeNRut + "-" + afterNRut,
                    email: data.ownerId.email,
                    businessName: data.businessName ? data.businessName : "",
                    businessEmailForContact: data.businessEmailForContact ? data.businessEmailForContact : "",
                    address: data.address ? data.address : "",
                    comuna: data.comuna ? data.comuna : "",
                    country: data.country ? data.country : "",
                    companyRut: beforeNRutC + "-" + afterNRutC,
                    // urlName: data.urlName,
                    isActive: data.isActive,
                })
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])


    const checkRut = (e) => {
        let rutCheck = e.target.value;
        // console.log(rutCheck)
        if (rutCheck !== "" && rutCheck.includes("-")) {
            if (rutCheck.split('-')[1].toUpperCase() === "K") {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Si el digito verificador termina en "K" reemplazar con "0". Ingrese nuevamente el RUT.' })
            } else {
                if (!Fn.validaRut(rut)) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de administrador invalido' })
                } else {
                    setFormData({
                        ...formdata,
                        rut: rutCheck,
                    })
                }
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de administrador invalido' })
        }
    }

    const checkRutCompany = (e) => {
        let companyRutCheck = e.target.value;
        // console.log(companyRutCheck)

        if (companyRutCheck !== "" && companyRutCheck.includes("-")) {
            if (companyRutCheck.split('-')[1].toUpperCase() === "K") {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Si el digito verificador termina en "K" reemplazar con "0". Ingrese nuevamente el RUT.' })
            } else {
                if (!Fn.validaRut(rut)) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de la compañia invalido' })
                } else {
                    setFormData({
                        ...formdata,
                        companyRut: companyRutCheck,
                    })
                }
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de la compañia invalido' })
        }
    }

    return (
        <Layout>
            <>
                <Topbar title="Editar Compañia" />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="companyName">
                                <Form.Label>Nombre Fantasía</Form.Label>
                                {/* <Form.Label>Nombre de la compañia</Form.Label> */}
                                <Form.Control type="text" placeholder="ienlace" value={companyName} name="companyName" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminRUT">
                                <Form.Label>RUT de la compañia</Form.Label>
                                <Form.Control type="text" placeholder="12345678-0" name="companyRut" value={companyRut} onChange={readFormData} onBlur={checkRutCompany} />
                            </Form.Group>
                            {/* <Form.Group controlId="companyAdminRUT">
                                    <Form.Label>URL de la pagina</Form.Label>
                                    <Form.Control type="text" placeholder="" value={urlName} name="urlName" onChange={readFormData} />
                                </Form.Group> */}
                            <Form.Group controlId="companyAdminName">
                                <Form.Label>Nombre del administrador</Form.Label>
                                <Form.Control type="text" placeholder="Camila" value={name} name="name" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminLastname">
                                <Form.Label>Apellido del administrador</Form.Label>
                                <Form.Control type="text" placeholder="Perez" value={lastname} name="lastname" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminLastname">
                                <Form.Label>Rut del administrador</Form.Label>
                                <Form.Control type="text" placeholder="12345678-0" name="rut" value={rut} onChange={readFormData} onBlur={checkRut} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Email del administrador</Form.Label>
                                <Form.Control type="email" placeholder="correo@correo.com" value={email} name="email" onChange={readFormData} />
                            </Form.Group>
                            {/* <Form.Group controlId="companystatus">
                                    <Form.Label>Estado de la empresa</Form.Label>
                                    <Form.Control as="select">
                                        <option>Inactiva</option>
                                        <option>Activa</option>
                                    </Form.Control>
                                </Form.Group> */}
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Razón social o nombre legal de la empresa</Form.Label>
                                {/* <Form.Label>Nombre de la empresa</Form.Label> */}
                                <Form.Control type="text" placeholder="" value={businessName} name="businessName" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Email de contacto de la empresa</Form.Label>
                                <Form.Control type="email" placeholder="" value={businessEmailForContact} name="businessEmailForContact" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Direcciòn de la empresa</Form.Label>
                                <Form.Control type="text" placeholder="" value={address} name="address" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Comuna</Form.Label>
                                <Form.Control type="text" placeholder="" value={comuna} name="comuna" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Pais</Form.Label>
                                <Form.Control type="text" placeholder="" value={country} name="country" onChange={readFormData} />
                            </Form.Group>
                            {user_role === "superAdmin" ?
                                <Form.Group controlId="companyAdminEmail" css={css`display:flex !important;flex-direction: column;`}>
                                    <Form.Label>Activa</Form.Label>
                                    <label className="switch mt-1">
                                        <input type="checkbox" checked={isActive} onClick={(e) => setStatusCompany(isActive ? false : true)} />
                                        <span className="slider round"></span>
                                    </label>
                                </Form.Group>
                                :
                                <div></div>
                            }


                            {/* <ContainerInputFile>
                                    <p className="mb-3">Icono de la compañia</p>
                                    <Label
                                        htmlFor="imagenIcono">
                                        <img src={UploadIconImg} width="40" height="40" />
                                    </Label>
                                    <input
                                        type="file"
                                        id="imagenIcono"
                                        css={css`visibility:hidden !important;`}
                                        name="file"
                                        // onChange={onChangeHandlerIcon}
                                        accept="image/jpg, image/jpeg"
                                    />
                                </ContainerInputFile>
                                <ContainerInputFile className="mt-5">
                                    <p className="mb-3">Logo de la compañia</p>
                                    <Label
                                        htmlFor="imageBackground">
                                        <img src={UploadIconImg} width="40" height="40" />
                                    </Label>
                                    <input
                                        type="file"
                                        id="imageBackground"
                                        css={css`visibility:hidden !important;`}
                                        name="file"
                                        // onChange={onChangeHandlerBg}
                                        accept="image/jpg, image/jpeg"
                                    />
                                </ContainerInputFile> */}
                        </Col>
                    </Form>
                </Row>
            </>
            <>
                <Sidebar updateCompanyB updateCompany={handleSubmit} />
            </>
        </Layout>
    );
}

export default UpdateCompany;