import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// redux
import { useSelector, useDispatch } from 'react-redux'
// alert
import Swal from 'sweetalert2';
import { UpdateUserManagerAction } from './../../redux/actions/UpdateUserManagerAction';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// import { findAllByRole } from "@testing-library/dom";
import Fn from './../extras/validateRUT';

// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';

const ViewEditClient = ({ history }) => {

    const [password, setPassword] = useState("")

    const { id } = useParams();

    const token = useSelector(state => state.auth.token)
    const currentRoleID = useSelector(state => state.auth.user_role)

    const [formdata, setFormData] = useState({
        rut: "",
        clientRazonSocial: "",
        addressComuna: "",
        addressCity: "",
        addressCountry: "",
        phoneNumber: "",
        contactName: "",
        contactLastname: "",
        contactEmail: "",
    })

    const { rut, clientRazonSocial, addressComuna, addressCity, addressCountry, phoneNumber, contactName, contactLastname, contactEmail } = formdata;

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    // const UpdateUserManagerFn = (formdata, id, history, token) => dispatch(UpdateUserManagerAction(formdata, id, history, token));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!Fn.validaRut(rut)) {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'Rut no válido' })
        }
        else if (clientRazonSocial === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'Razón social requerida' })
        }
        //  else if (addressComuna === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'Comuna requerida' })
        // } else if (addressCity === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'Ciudad requerida' })
        // } else if (addressCountry === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'País requerido' })
        // }
        else if (phoneNumber === "") {
            Swal.fire({ icon: 'error', title: 'Oops...', text: 'Teléfono requerido' })
        }
        // else if (contactName === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'Nombre requerido' })
        // }
        // else if (contactLastname === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'Apellido requerido' })
        // } else if (contactEmail === "") {
        //     Swal.fire({ icon: 'error', title: 'Oops...', text: 'Email requerido' })
        // } 
        else {
            console.log(formdata)

            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };
            clienteAxios.put(`/api/client/${id}`, formdata, config)
                .then((res) => {
                    console.log(res.data)
                    Swal.fire({ icon: 'success', title: 'Cliente actualizado', text: 'Cliente actualizado correctamente' })
                    history.push('/clientes')
                })
                .catch(error => {
                    // console.log(error)
                    Swal.fire({ icon: 'error', title: 'Oops...', text: 'Error al actualizar cliente' })
                })

        }
    }



    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/client/${id}`, config)
            .then((res) => {
                console.log(res.data)
                setFormData({
                    rut: res.data[0].rut,
                    clientRazonSocial: res.data[0].clientRazonSocial ? res.data[0].clientRazonSocial : "",
                    addressComuna: res.data[0].addressComuna ? res.data[0].addressComuna : "",
                    addressCity: res.data[0].addressCity ? res.data[0].addressCity : "",
                    addressCountry: res.data[0].addressCountry ? res.data[0].addressCountry : "",
                    phoneNumber: res.data[0].phoneNumber ? res.data[0].phoneNumber : "",
                    contactName: res.data[0].contactName ? res.data[0].contactName : "",
                    contactLastname: res.data[0].contactLastname ? res.data[0].contactLastname : "",
                    contactEmail: res.data[0].contactEmail ? res.data[0].contactEmail : "",
                })
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])


    return (
        <Layout>
            <>
                <Topbar title="Actaulizar cliente / Gerencia" />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start" onSubmit={handleSubmit}>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group controlId="rut">
                                        <Form.Label>RUT</Form.Label>
                                        <Form.Control type="text" placeholder="RUT" name="rut" onChange={readFormData} value={rut} />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group controlId="clientRazonSocial">
                                        <Form.Label>Razón Social</Form.Label>
                                        <Form.Control type="text" placeholder="Razón Social" name="clientRazonSocial" onChange={readFormData} value={clientRazonSocial} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="addressComuna">
                                <Form.Label>Dirección de comuna</Form.Label>
                                <Form.Control type="text" placeholder="Dirección de comuna" name="addressComuna" onChange={readFormData} value={addressComuna} />
                            </Form.Group>
                            <Form.Group controlId="addressCity">
                                <Form.Label>Dirección de ciudad</Form.Label>
                                <Form.Control type="text" placeholder="Dirección de ciudad" name="addressCity" onChange={readFormData} value={addressCity} />
                            </Form.Group>
                            <Form.Group controlId="addressCountry">
                                <Form.Label>Dirección de país</Form.Label>
                                <Form.Control type="text" placeholder="Dirección de país" name="addressCountry" onChange={readFormData} value={addressCountry} />
                            </Form.Group>
                            <Form.Group controlId="phoneNumber">
                                <Form.Label>Número de teléfono</Form.Label>
                                <Form.Control type="number" placeholder="Número de teléfono" name="phoneNumber" onChange={readFormData} value={phoneNumber} />
                            </Form.Group>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group controlId="contactName">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text" placeholder="Nombre" name="contactName" onChange={readFormData} value={contactName} />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group controlId="contactLastname">
                                        <Form.Label>Apellido</Form.Label>
                                        <Form.Control type="text" placeholder="Apellido" name="contactLastname" onChange={readFormData} value={contactLastname} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="contactEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" name="contactEmail" onChange={readFormData} value={contactEmail} />
                            </Form.Group>
                            <Button onClick={handleSubmit} variant="warning" className="mt-5">Actualizar cliente</Button>
                        </Col>

                    </Form>
                </Row>
            </>
            <>
                <Sidebar />
            </>
        </Layout>
    );
}

export default ViewEditClient;