import React, { PureComponent, useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
// redux
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from "../../config/axios";


const ChartMonthlyPtoject = () => {

    const token = useSelector(state => state.auth.token)
    const [graphMonthlyProjects, setGraphMonthlyProjects] = useState("")

    
  

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/graphMonthlyProjects`, config)
            .then((res) => {

                // console.log(res.data)
                let resut = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];

                    if (element._id.month === 1) {
                        resut.push({
                            name: "Ene",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 2){
                        resut.push({
                            name: "Feb",
                            Proyectos: element.count,
                        })
                    }
                    else if(element._id.month === 3){
                        resut.push({
                            name: "Mar",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 4){
                        resut.push({
                            name: "Abr",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 5){
                        resut.push({
                            name: "May",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 6){
                        resut.push({
                            name: "Jun",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 7){
                        resut.push({
                            name: "Jul",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 8){
                        resut.push({
                            name: "Ago",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 9){
                        resut.push({
                            name: "Sep",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 10){
                        resut.push({
                            name: "Oct",
                            Proyectos: element.count,
                        })
                    }else if(element._id.month === 11){
                        resut.push({
                            name: "Nov",
                            Proyectos: element.count,
                        })
                    }
                    else if(element._id.month === 12){
                        resut.push({
                            name: "Dic",
                            Proyectos: element.count,
                        })
                    }
                }
                setGraphMonthlyProjects(resut)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])


    return (
        <ResponsiveContainer width="100%" css={css`height:200px !important;`}>
            <LineChart
                width={500}
                height={300}
                data={graphMonthlyProjects}
                padding={{
                    top: 20,
                }}
                margin={{
                    left: -20,
                    right: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Proyectos" stroke="#FFA41E" activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default ChartMonthlyPtoject;