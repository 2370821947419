import React, { useState, useEffect } from "react"

//Navbar
import Navbar from '../navigation/Navbar'
import Topbar from '../navigation/Topbar'
import Sidebar from '../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, ToggleButtonGroup, ToggleButton, ButtonGroup } from 'react-bootstrap';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import Layout from "../structures/Layout";


const ContainerGeneral = styled.div`
input[type=checkbox], input[type=radio]{
    visibility: hidden;
}
  overflow: auto;
  white-space: nowrap;
    .btn-secondary:not(:disabled):not(.disabled).active{
        background-color: #FFA41E;
        border-color: #FFA41E;
    }
    .btn-secondary.focus, .btn-secondary:focus{
        color: #fff;
        background-color: #FFA41E;
        border-color: #FFA41E;
        box-shadow: 0 0 0 0.2rem rgb(255 164 30 / 50%);
    }
    .btn-secondary:hover {
        color: #fff;
        background-color: #FFA41E;
        border-color: #FFA41E;
    }
    .btn-secondary {
        color: #fff;
    }
`

const TitleUser = styled.p`
    font-size: 1.5rem;
    text-transform: capitalize;
`

const ManagerVistaByUser = ({ location, history }) => {
    //DISPATCH
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token)

    const [users, setUsers] = useState(null);
    const [users1, setUsers1] = useState([]);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.get('/api/usersWindow', config)
            .then(res => {
                console.log(res.data)
                setUsers(res.data)


            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    let handleChange = async (i, e) => {
        let newFormValues = [...users];
        newFormValues[i][e.target.name] = e.target.value === "true" ? true : false;
        console.log(newFormValues[i])

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        }


        await clienteAxios.put(`/api/usersWindow/${newFormValues[i]._id}`, newFormValues[i], config)
            .then(res => {
                setUsers(newFormValues);
                console.log(res.data)
                Toast.fire({
                    icon: 'success',
                    title: 'Se ha actualizado el usuario'
                })
            })
            .catch(err => {
                console.log(err)
            })

    }


    return (
        <Layout>
            <>
                <Topbar title={`Vistas por usuarios`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12} className="position-relative">
                        <ContainerGeneral>
                            {users && users.map((user, i) => {
                                return (
                                    <div key={i}>
                                        <TitleUser>{user.role}</TitleUser>
                                        <ButtonGroup className="mb-2">
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.dashboard} name="dashboard" value={user.dashboard ? false : true} onChange={e => handleChange(i, e)} >
                                                dashboard
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.administrarProyectos} name="administrarProyectos" value={user.administrarProyectos ? false : true} onChange={e => handleChange(i, e)} >
                                                Administrar proyectos
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.solicitudDeMaterial} name="solicitudDeMaterial" value={user.solicitudDeMaterial ? false : true} onChange={e => handleChange(i, e)} >
                                                Solicitud de material
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.aprobarSolicitudDeMaterial} name="aprobarSolicitudDeMaterial" value={user.aprobarSolicitudDeMaterial ? false : true} onChange={e => handleChange(i, e)} >
                                                Aprobar solicitud de material
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.gestionarComprasDeMaterial} name="gestionarComprasDeMaterial" value={user.gestionarComprasDeMaterial ? false : true} onChange={e => handleChange(i, e)} >
                                                Gestionar compras del material
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.informes} name="informes" value={user.informes ? false : true} onChange={e => handleChange(i, e)} >
                                                Informes
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.logs} name="logs" value={user.logs ? false : true} onChange={e => handleChange(i, e)} >
                                                Logs
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.gestionDeUsuarios} name="gestionDeUsuarios" value={user.gestionDeUsuarios ? false : true} onChange={e => handleChange(i, e)} >
                                                Gestion de usuarios
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.perfil} name="perfil" value={user.perfil ? false : true} onChange={e => handleChange(i, e)} >
                                                Perfil
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.editarEstiloDeEmpresa} name="editarEstiloDeEmpresa" value={user.editarEstiloDeEmpresa ? false : true} onChange={e => handleChange(i, e)} >
                                                Editar estilo de Empresa
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.areas} name="areas" value={user.areas ? false : true} onChange={e => handleChange(i, e)} >
                                                Areas
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.gestionDeEmpresas} name="gestionDeEmpresas" value={user.gestionDeEmpresas ? false : true} onChange={e => handleChange(i, e)} >
                                                Gestion de empresas
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.gestionDeVistasPorUsuario} name="gestionDeVistasPorUsuario" value={user.gestionDeVistasPorUsuario ? false : true} onChange={e => handleChange(i, e)} >
                                                Gestion de vistas por usuario
                                            </ToggleButton>
                                            <ToggleButton id="toggle-check" type="checkbox" variant="secondary" checked={user.gestionDeClientes} name="gestionDeClientes" value={user.gestionDeClientes ? false : true} onChange={e => handleChange(i, e)} >
                                                Gestion de clientes
                                            </ToggleButton>
                                        </ButtonGroup>
                                    </div>
                                )
                            })}
                        </ContainerGeneral>
                    </Col>
                </Row>
            </>
        </Layout >
    );
}

export default ManagerVistaByUser;