import { UPLOAD_FILE_PRODUCTS_START, UPLOAD_FILE_PRODUCTS_SUCCESS, UPLOAD_FILE_PRODUCTS_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UploadProductsFileAction(event, id, token) {
    return (dispatch) => {
        dispatch(UploadFileProductStart())

        let fl = new FormData();
        fl.append("excelFile", event.target.files[0]);

        clienteAxios.post(`/api/project/${id}/uploadTable`, fl, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': "Bearer " + token
            }
        })
            .then((res) => {
                // console.log(res.data.products)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha cargado el archivo correctamente.' })
                dispatch(UploadFileProductSuccess(res.data.products))
            })
            .catch(error => {
                dispatch(UploadFileProductFail())
                // console.log(error.response)
            })
    }
}
export function UpdatingTableProductsAction(changedRows) {
    return (dispatch) => {
        dispatch(UploadFileProductStart())
        dispatch(UploadFileProductSuccess(changedRows))
    }
}




export const UploadFileProductStart = () => {
    return {
        type: UPLOAD_FILE_PRODUCTS_START
    };
};

export const UploadFileProductSuccess = (products) => ({
    type: UPLOAD_FILE_PRODUCTS_SUCCESS,
    payload: products
})

export const UploadFileProductFail = () => ({
    type: UPLOAD_FILE_PRODUCTS_FAIL,
})
