import { UPLOAD_FILE_PRODUCTS_START, UPLOAD_FILE_PRODUCTS_SUCCESS, UPLOAD_FILE_PRODUCTS_FAIL} from '../actions/ActionsTypes';

const initialState = {
    listProducts:[],
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPLOAD_FILE_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case UPLOAD_FILE_PRODUCTS_SUCCESS:
            return {
                ...state,
                listProducts: action.payload,
                error: null,
                loading: false
            }
        case UPLOAD_FILE_PRODUCTS_FAIL:
            return {
                ...state,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}