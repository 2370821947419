import axios from 'axios';

const clienteAxios = axios.create({
    // https://flaviocopes.com/axios-credentials/
    // https://stackoverflow.com/questions/43002444/make-axios-send-cookies-in-its-requests-automatically
    // https://github.com/github/fetch/issues/386

    // withCredentials: true,
    // baseURL: 'http://localhost:8080'
    baseURL: 'https://defaena.herokuapp.com'
    // baseURL: 'https://59c5-73-12-254-19.ngrok.io'
});

export default clienteAxios;
