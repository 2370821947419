import React, { useState, useEffect } from "react"

//Bootstrap
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
// import { LoginAction } from '../../redux/actions/AuthAction';
// alert
import Swal from 'sweetalert2';
// router
import { useParams } from "react-router";
import clienteAxios from "../../config/axios";

import Select from 'react-select';

const AdminUploadProductsManually = ({ setData, filterCategory }) => {
    const token = useSelector(state => state.auth.token)
    // ID del parametro enviado por url, pertenece al id del proyecto que se esta viendo.
    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [option1, setOption1] = useState([]);
    const [generalDataCategorySubcategoty, setGeneralDataCategorySubcategoty] = useState([]);
    const [subcategoryOption, setSubcategoryOption] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [formdata, setFormData] = useState({
        sku: '',
        productName: '',
        producType: '',
        productBrand: '',
        productModel: '',
        productUnits: '',
        cantidadPresupuestada: '',
        montoPresupuestado: '',
        totalPresupuestado: '',
        category: '',
        subcategory: '',
    })

    const { sku,
        productName,
        producType,
        productBrand,
        productModel,
        productUnits,
        cantidadPresupuestada,
        montoPresupuestado,
        totalPresupuestado, category,
        subcategory } = formdata;

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    // const addProductManuallyFn = (formdata, history) => dispatch(LoginAction(formdata, history));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        if (sku === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Sku del producto requerido' })
        } else if (productName === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del producto requerido' })
        }
        else if (category === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Categoría requerida' })
        }
        else if (subcategory === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Subcategoría requerida' })
        }
        else if (productBrand === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Marca del producto requerido' })
        } else if (productModel === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Modelo del producto requerido' })
        } else if (productUnits === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Unidad del producto requerido' })
        } else if (cantidadPresupuestada === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Cantidad presupuestada del producto requerido' })
        } else if (montoPresupuestado === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Monto presupuestado del producto requerido' })
        }
        // else if (totalPresupuestado === "") {
        //     Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Total presupuestado del producto requerido' })
        // } 
        else {
            // console.log(formdata)
            // let _id = Math.floor(Math.random() * 99999);
            // formdata._id = _id.toString();
            // console.log(formdata);
            // console.log(token)
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };
            clienteAxios.post(`/api/project/${id}/uploadTableRow`, formdata, config)
                .then((res) => {
                    // console.log(res.data)
                    setData(state => [...state, res.data])
                    handleClose()
                    Toast.fire({
                        icon: 'success',
                        title: 'Producto agregado'
                    })
                })
                .catch(error => {
                    // console.log(error)
                    // setLoadingTable(false)
                })
            // addProductManuallyFn()
        }
    }

    useEffect(() => {
        // console.log(filterCategory)
        let data = []

        for (var key1 in filterCategory) {
            // console.log(key1);
            let subcategory = [];
            for (var key2 in filterCategory[key1]) {
                // console.log(filterCategory[key1][key2]);
                subcategory.push(filterCategory[key1][key2])
            }
            data.push({
                category: key1,
                subcategory
            })
        }

        let categoryData = []
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            categoryData.push({ "value": i, "label": item.category })
        }
        setGeneralDataCategorySubcategoty(data)
        setOption1(categoryData)
        // console.log(data)
    }, [])

    const getSubcategory = (e) => {
        let itemSelected;
        for (let i = 0; i < generalDataCategorySubcategoty.length; i++) {
            let item = generalDataCategorySubcategoty[i];
            if (e === i) {
                itemSelected = item
            }
        }

        let data2 = []
        for (let i = 0; i < itemSelected.subcategory.length; i++) {
            let item = itemSelected.subcategory[i];
            data2.push({ "value": item, "label": item })
        }
        setSubcategoryOption(data2)
    }
    return (
        <React.Fragment>
            <Button variant="secondary" className="text-white" onClick={handleShow}>
                Cargar manual
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><strong>Carga Manual</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="col-12">
                        <Col xs={12}>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>SKU</Form.Label>
                                <Form.Control type="text" placeholder="1.2.5" name="sku" onChange={readFormData} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="d-lg-flex align-items-start">
                            <Col xs={12} lg={12} className="px-0">
                                <Form.Group controlId="projectName">
                                    <Form.Label>Producto</Form.Label>
                                    <Form.Control type="text" placeholder="Patch panel" name="productName" onChange={readFormData} />
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-lg-flex align-items-start">
                            <Col xs={12} className="px-0">
                                <Form.Group controlId="category">
                                    <Form.Label>Categoría</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Carlos Andrade" name="supervisedById" onChange={readFormData} /> */}
                                    <Select
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="category"
                                        options={option1}
                                        onChange={(e) => {
                                            // console.log(e.label)
                                            getSubcategory(e.value)
                                            setFormData({
                                                ...formdata,
                                                "category": e.label
                                            })
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-lg-flex align-items-start">
                            <Col xs={12} className="px-0">
                                <Form.Group controlId="subcategory">
                                    <Form.Label>Subcategoría</Form.Label>
                                    {/* <Form.Control type="text" placeholder="Carlos Andrade" name="supervisedById" onChange={readFormData} /> */}
                                    <Select
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="subcategory"
                                        options={subcategoryOption}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formdata,
                                                "subcategory": e.label
                                            })
                                        }}
                                    // inputValue={supervisedById}
                                    />
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-lg-flex align-items-start">
                            <Col xs={12} lg={6} className="px-0">
                                <Form.Group controlId="projectName">
                                    <Form.Label>Marca</Form.Label>
                                    <Form.Control type="text" placeholder="Trimerx 22" name="productBrand" onChange={readFormData} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="px-0">
                                <Form.Group controlId="projectName">
                                    <Form.Label>Modelo</Form.Label>
                                    <Form.Control type="text" placeholder="MM23" name="productModel" onChange={readFormData} />
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col xs={12} className="d-lg-flex align-items-start">
                            <Col xs={12} lg={8} className="px-0">
                                <Form.Group controlId="projectName">
                                    <Form.Label>Cantidad presupuestada</Form.Label>
                                    <Form.Control type="number" placeholder="100" name="cantidadPresupuestada" onChange={readFormData} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={4} className="px-0">
                                <Form.Group controlId="supervisor">
                                    <Form.Group controlId="projectName">
                                        <Form.Label>Unidad</Form.Label>
                                        <Form.Control type="text" placeholder="Unidad" name="productUnits" onChange={readFormData} />
                                    </Form.Group>
                                    {/* <Form.Control as="select" name="productUnits" onChange={readFormData} >
                                        <option value=""></option>
                                        <option value="m">m</option>
                                        <option value="ft">ft</option>
                                        <option value="inches">inches</option>
                                    </Form.Control> */}
                                </Form.Group>
                            </Col>
                        </Col>
                        <Col xs={12}>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>Monto presupuestado</Form.Label>
                                <Form.Control type="number" placeholder="10" name="montoPresupuestado" onChange={readFormData} />
                            </Form.Group>
                        </Col>
                        {/* <Col xs={12}>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>Total presupuestado</Form.Label>
                                <Form.Control type="number" placeholder="2000" name="totalPresupuestado" onChange={readFormData} />
                            </Form.Group>
                        </Col> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-center align-items-center">
                    <Col xs={5} className="px-0">
                        <Button variant="warning" className="col-12" onClick={() => handleSubmit()}>Agregar</Button>
                    </Col>
                    <Col xs={5} className="px-0">
                        <Button variant="danger" onClick={handleClose} className="col-12">Cancelar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </React.Fragment>

    );
}

export default AdminUploadProductsManually;