import * as React from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Layout from "../structures/Layout";

const BuyerProjectProducts = () => {

    return (
        <Layout>
            <>
                <Topbar />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <Table responsive className="products">
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>producto</th>
                                    <th>cotizar</th>
                                    <th>unidades</th>
                                    <th>cantidad<br />presupuestada</th>
                                    <th>cantidad<br />solicitada</th>
                                    <th>saldo<br />disponible</th>
                                    <th>monto<br />presupuestado</th>
                                    <th>monto<br />compra</th>
                                    <th>total<br />presupuestado</th>
                                    <th>disponible<br />presupuestado</th>
                                    <th className="text-uppercase no-wrap">OC ERP</th>
                                    <th>documento</th>
                                    <th>Observación</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td><Form.Check type="checkbox" label="" /></td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td className="no-wrap">Cotizando</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td><Form.Check type="checkbox" label="" /></td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td className="no-wrap">OC Enviada</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td><Form.Check type="checkbox" label="" /></td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td className="no-wrap">En Bodega</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td><Form.Check type="checkbox" label="" /></td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td className="no-wrap">Recibido</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td><Form.Check type="checkbox" label="" /></td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td className="no-wrap">En Bodega</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td><Form.Check type="checkbox" label="" /></td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>6.000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td>0</td>
                                    <td>6000</td>
                                    <td className="no-wrap">OC Enviada</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
            < >
                <Sidebar />
            </>

        </Layout>
    );
}

export default BuyerProjectProducts;