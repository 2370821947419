import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    LOGOUT_START,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    REMOVE_ICON_COMPANY_FILE_PRODUCTS_SUCCESS,
    UPLOAD_AVATAR_FILE_SUCCESS,
    COMPANY_START,
    COMPANY_SUCCESS,
    COMPANY_FAIL,
    SHOW_PAGE_BY_ROL_START, SHOW_PAGE_BY_ROL_SUCCESS, SHOW_PAGE_BY_ROL_FAIL
} from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function BusinessAction(formdata, history) {
    return (dispatch) => {
        dispatch(AuthStart())

        clienteAxios.post('/api/users/companySignup', formdata)
            .then((res) => {
                dispatch(AuthSuccess(res.data))
                // console.log('registro')
                // console.log(res)
                dispatch(CompanyInfoStart())
                clienteAxios.get(`/api/company/${res.data.user.company}`, {
                    headers: { 'Authorization': "Bearer " + res.data.token }
                })
                    .then((res) => {
                        // console.log('compania')
                        // console.log(res.data)
                        dispatch(CompanyInfoSuccess(res.data))
                        history.push('/admin-dashboard')
                    })
                    .catch(error => {
                        dispatch(CompanyInfoFail())
                        // console.log(error)
                    })

            })
            .catch(error => {
                dispatch(AuthFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error'
                    })
                }
            })
    }
}


export function LoginAction(formdata, history) {
    return (dispatch) => {
        dispatch(AuthStart())

        clienteAxios.post('/api/users/signin', formdata)
            .then((res) => {
                // console.log('registro')
                console.log(res.data)
                const UserData = res.data;
                dispatch(AuthSuccess(res.data))

                if (res.data.user.role === "superAdmin") {
                    history.push('/gestion-de-empresas')
                } else {
                    dispatch(CompanyInfoStart())
                    dispatch(SaveCurrentViewsByUserRolStart())

                    clienteAxios.get('/api/usersWindow', {
                        headers: { 'Authorization': "Bearer " + res.data.token }
                    })
                        .then(res => {
                            console.log(res.data)
                            let data = {
                                "dashboard": [],
                                "administrarProyectos": [],
                                "solicitudDeMaterial": [],
                                "aprobarSolicitudDeMaterial": [],
                                "gestionarComprasDeMaterial": [],
                                "informes": [],
                                "logs": [],
                                "gestionDeUsuarios": [],
                                "perfil": [],
                                "editarEstiloDeEmpresa": [],
                                "areas": [],
                                "gestionDeEmpresas": [],
                                "gestionDeVistasPorUsuario": [],
                                "gestionDeClientes": [],
                            }

                            for (let i = 0; i < res.data.length; i++) {
                                const page = res.data[i];
                                if (page.dashboard) {
                                    data.dashboard.push(page.role)
                                }
                                if (page.administrarProyectos) {
                                    data.administrarProyectos.push(page.role)
                                }
                                if (page.solicitudDeMaterial) {
                                    data.solicitudDeMaterial.push(page.role)
                                }
                                if (page.aprobarSolicitudDeMaterial) {
                                    data.aprobarSolicitudDeMaterial.push(page.role)
                                }
                                if (page.gestionarComprasDeMaterial) {
                                    data.gestionarComprasDeMaterial.push(page.role)
                                }
                                if (page.informes) {
                                    data.informes.push(page.role)
                                }
                                if (page.logs) {
                                    data.logs.push(page.role)
                                }
                                if (page.gestionDeUsuarios) {
                                    data.gestionDeUsuarios.push(page.role)
                                }
                                if (page.perfil) {
                                    data.perfil.push(page.role)
                                }
                                if (page.editarEstiloDeEmpresa) {
                                    data.editarEstiloDeEmpresa.push(page.role)
                                }
                                if (page.areas) {
                                    data.areas.push(page.role)
                                }
                                if (page.gestionDeEmpresas) {
                                    data.gestionDeEmpresas.push(page.role)
                                }
                                if (page.gestionDeVistasPorUsuario) {
                                    data.gestionDeVistasPorUsuario.push(page.role)
                                }
                                if (page.gestionDeClientes) {
                                    data.gestionDeClientes.push(page.role)
                                }
                            }
                            // setUsers(data)
                            dispatch(SaveCurrentViewsByUserRolSuccess(data))
                            console.log(data)
                        })
                        .catch(err => {
                            console.log(err)
                            dispatch(SaveCurrentViewsByUserRolFail())
                        })

                    clienteAxios.get(`/api/company/${res.data.user.company}`, {
                        headers: { 'Authorization': "Bearer " + res.data.token }
                    })
                        .then((res) => {
                            dispatch(CompanyInfoSuccess(res.data))
                            if (UserData.user.role === "supervisor") {
                                history.push('/proyectos')
                            } else if (UserData.user.role === "gerente") {
                                history.push('/admin-dashboard')
                            } else if (UserData.user.role === "visualizador") {
                                history.push('/admin-dashboard')
                            } else if (UserData.user.role === "colaborador") {
                                history.push('/proyectos')
                            } else if (UserData.user.role === "comprador") {
                                history.push('/proyectos')
                            } else if (UserData.user.role === "aprobador") {
                                history.push('/admin-dashboard')
                            }
                        })
                        .catch(error => {
                            dispatch(CompanyInfoFail())
                            // console.log(error)
                        })
                }
            })
            .catch(error => {
                dispatch(AuthFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else if (error.response.status === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Usuario o contraseña incorrecta'
                    })
                }
                else if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Compañia no activa'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error, intente de nuevo'
                    })
                }
            })
    }
}

export function LogoutAction(token) {
    return (dispatch) => {
        dispatch(LogoutStart())
        // console.log(token)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.post('/api/users/signout', {}, config)
            .then((res) => {
                // console.log('todo bien, nos vemos')
                dispatch(removeIcon())
                dispatch(LogoutSuccess())
                dispatch(UploadAvatarSuccess())
            })
            .catch(error => {
                dispatch(LogoutFail())
                if (!error.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error de conexión.'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo siento',
                        text: 'Error'
                    })
                }
            })
    }
}



export const AuthStart = () => {
    return {
        type: AUTH_START
    };
};

export const AuthSuccess = (auth) => ({
    type: AUTH_SUCCESS,
    payload: auth
})

export const AuthFail = () => ({
    type: AUTH_FAIL,
})

// ------------------------------
export const LogoutStart = () => {
    return {
        type: LOGOUT_START
    };
};

export const LogoutSuccess = (auth) => ({
    type: LOGOUT_SUCCESS,
    payload: auth
})

export const LogoutFail = () => ({
    type: LOGOUT_FAIL,
})
// ------------------------------------------

export const removeIcon = () => ({
    type: REMOVE_ICON_COMPANY_FILE_PRODUCTS_SUCCESS,
})
// -----------------------------------------------
export const UploadAvatarSuccess = () => ({
    type: UPLOAD_AVATAR_FILE_SUCCESS,
    payload: null
})
// ---------------------------------------

export const CompanyInfoStart = () => {
    return {
        type: COMPANY_START
    };
};

export const CompanyInfoSuccess = (company) => ({
    type: COMPANY_SUCCESS,
    payload: company
})

export const CompanyInfoFail = () => ({
    type: COMPANY_FAIL,
})
// ---------------------------------------

export const SaveCurrentViewsByUserRolStart = () => {
    return {
        type: SHOW_PAGE_BY_ROL_START
    };
};

export const SaveCurrentViewsByUserRolSuccess = (users) => ({
    type: SHOW_PAGE_BY_ROL_SUCCESS,
    payload: users
})

export const SaveCurrentViewsByUserRolFail = () => ({
    type: SHOW_PAGE_BY_ROL_FAIL,
})