import React, { useState, useEffect, useRef, useCallback } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { EditingState, PagingState, IntegratedPaging, DataTypeProvider, SearchState, IntegratedFiltering, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableInlineCellEditing, Toolbar, TableColumnResizing, TableFixedColumns, TableEditRow, PagingPanel, SearchPanel, TableGroupRow, VirtualTable, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import { Plugin, Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AutoComplete from './../extras/autocompleteUpdate';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import Layout from "../structures/Layout";
const getRowId = row => row._id;

// #FOLD_BLOCK
const StartEditActionSelector = (props) => {
    const { defaultAction, changeAction } = props;
    return (
        <div>
            {'Iniciar edición:'}
            &nbsp;
            <select
                defaultValue={defaultAction}
                onChange={e => changeAction(e.target.value)}
                className="dropdown"
            >
                <option value="click">Click</option>
                <option value="doubleClick">Doble Click</option>
            </select>
        </div>
    );
};

// #FOLD_BLOCK
const SelectTextChecker = (props) => {
    const { isSelectText, changeSelectText } = props;
    return (
        <div
            style={{
                padding: '0em 1em',
            }}
        >
            <label htmlFor="selectTextChecker" className="form-check-label">
                <input
                    type="checkbox"
                    checked={isSelectText}
                    id="selectTextChecker"
                    name="selectTextChecker"
                    className="form-check-input"
                    onChange={e => changeSelectText(e.target.checked)}
                />
                Seleccionar texto al activar
            </label>
        </div>
    );
};

const EditPropsPanel = props => (
    <Plugin name="EditPropsPanel">
        <Template name="toolbarContent">
            <SelectTextChecker {...props} />
            <TemplatePlaceholder />
            <StartEditActionSelector {...props} />
        </Template>
    </Plugin>
);

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);
const MaterialRequestUpdate = ({ location, history }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    // console.log(location)

    const { id, idProject } = useParams();
    const token = useSelector(state => state.auth.token)
    const projectID = useSelector(state => state.saveProyectIDToMaterialRequest.id)
    //DISPATCH
    const dispatch = useDispatch();

    const [data, setData] = useState([])

    const [columnsUpdate] = useState([
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Nombre' },
        { name: 'category', title: 'Categoria' },
        { name: 'subcategory', title: 'Subcategoria' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        { name: 'cantidadCompra', title: 'Canticad comprada' },
        { name: 'status', title: 'Estado del material' },
        { name: 'observacion', title: 'Observacion' },
    ]);
    const [columnsCreate] = useState([
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Nombre' },
        { name: 'category', title: 'Categoria' },
        { name: 'subcategory', title: 'Subcategoria' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        // { name: 'cantidadCompra', title: 'Canticad comprada' },
        // { name: 'status', title: 'Estado del material' },
        { name: 'observacion', title: 'Observacion' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'sku', width: 120 },
        { columnName: 'productName', width: 150 },
        { columnName: 'category', width: 300 },
        { columnName: 'subcategory', width: 300 },
        { columnName: 'productUnits', width: 120 },
        { columnName: 'productBrand', width: 120 },
        { columnName: 'productModel', width: 120 },
        { columnName: 'saldoDisponible', width: 150 },
        { columnName: 'cantidadSolicitada', width: 150 },
        { columnName: 'cantidadCompra', width: 120 },
        { columnName: 'status', width: 120 },
        { columnName: 'observacion', width: 200 },
    ]);


    const HighlightedCell = ({ value, style, ...restProps }) => {
        // console.log(restProps)
        return (
            <Table.Cell {...restProps}>
                <span style={{
                    color: Number(value) < restProps.row.saldoDisponible ? "#000" : '#DD3434',
                    fontWeight: Number(value) < restProps.row.saldoDisponible ? "normal" : 'bold'
                }}>
                    {value}
                </span>
            </Table.Cell>
        )
    };

    const Cell = (props) => {
        const { column, row } = props;
        if (column.name === 'cantidadSolicitada') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };


    const [editingCells, setEditingCells] = useState([]);

    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = data.length > 0
                ? Math.max(data[data.length - 1]._id, data[0]._id) + 1
                : 0;
            changedRows = [
                ...added.map((row, index) => ({
                    _id: startingAddedId + index,
                    ...row,
                })),
                ...data,
            ];
            statusToShowButton === "pendiente" ?
                setEditingCells([{ rowId: startingAddedId, columnName: columnsCreate[0].name }])
                :
                setEditingCells([{ rowId: startingAddedId, columnName: columnsUpdate[0].name }])
        }
        if (changed) {
            let isHigher = false;
            changedRows = data.map(row => (changed[row._id] ? { ...row, ...changed[row._id] } : row));
            data.map(row => (changed[row._id]
                ? changed[row._id].cantidadSolicitada > row.saldoDisponible ? isHigher = true : isHigher = false
                : row
            ));
            if (isHigher) {
                Swal.fire({ icon: 'warning', title: 'Información!', text: 'La cantidad solicitada no se encuentra disponible.' })
            }

        }
        if (deleted) {

            if (deleted[0].match(/^[0-9a-fA-F]{24}$/)) {

                let promise = new Promise((resolve, reject) => {
                    Swal.fire({
                        title: 'Estas seguro?',
                        text: "Esta acción no se puede revertir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, eliminar!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const config = {
                                headers: { 'Authorization': "Bearer " + token }
                            };
                            if (statusToShowButton === "pendiente") {
                                console.log("pending")
                                clienteAxios.delete(`/api/project/${projectId}/materialRequest/updateTable/${deleted[0]}/NotSend`, config)
                                    .then((res) => {
                                        // console.log(res.data)

                                        const deletedSet = new Set(deleted);
                                        changedRows = data.filter(row => !deletedSet.has(row._id));

                                        Toast.fire({ icon: 'success', title: 'Eliminado correctamente' })

                                        resolve("done!")
                                    })
                                    .catch(error => {
                                        Toast.fire({ icon: 'error', title: 'Error al eliminar' })
                                        reject()
                                    })
                            } else {
                                console.log("otro")
                                clienteAxios.delete(`/api/project/${projectId}/materialRequest/updateTable/${deleted[0]}`, config)
                                    .then((res) => {
                                        // console.log(res.data)

                                        const deletedSet = new Set(deleted);
                                        changedRows = data.filter(row => !deletedSet.has(row._id));

                                        Toast.fire({ icon: 'success', title: 'Eliminado correctamente' })

                                        resolve("done!")
                                    })
                                    .catch(error => {
                                        Toast.fire({ icon: 'error', title: 'Error al eliminar' })
                                        reject()
                                    })
                            }

                        }
                    })
                });

                let result = await promise; // wait until the promise resolves (*)
                // console.log(result); // "done!"

            } else {
                const deletedSet = new Set(deleted);
                changedRows = data.filter(row => !deletedSet.has(row._id));

                Toast.fire({
                    icon: 'success',
                    title: 'Eliminado correctamente'
                })
            }

        }
        // console.log(changedRows)
        setData(changedRows ? changedRows : data);
        validateQty(changedRows ? changedRows : data);
    };

    const validateQty = (data) => {
        console.log(data)
        let stop = false
        let count = 0
        let dataMaterialRequest = []
        for (let i = 0; i < data.length; i++) {
            let element = data[i];
            if (element.cantidadSolicitada) {
                count = count + 1
                dataMaterialRequest.push(element)
            }
        }


        if (count == 10) {
            Swal.fire({ icon: 'warning', title: '¡Atención!', text: 'Cantidad de materiales solicitados (10). Solo puede agregar 5 productos más.' })
            stop = true;
        } else {
            Toast.fire({
                icon: 'success',
                title: `Se han agregado ${count} productos.`
            })
        }
        if (count == 15) {
            Swal.fire({ icon: 'warning', title: '¡Atención!', text: 'Cantidad de materiales solicitados (15). Has alcanzado el máximo de materiales.' })
            stop = true;
        }

    }



    const addEmptyRow = () => commitChanges({ added: [{}] });

    const addItemToTable = (Item) => {
        // console.log(Item)
        if (Item) {
            for (let i = 0; i < Item.length; i++) {
                const material = Item[i];

                if (data.length === 0) {
                    setData(state => [...state, material])
                    Toast.fire({ icon: 'success', title: 'Productos agregados' })
                } else {
                    let include = false;
                    if (data.length === 0) {
                        setData(state => [...state, material])
                        Toast.fire({ icon: 'success', title: 'Productos agregados' })
                    } else {
                        for (let i = 0; i < data.length; i++) {
                            const element = data[i];
                            if (material.productName === element.productName) {
                                include = true
                            }
                        }
                        if (include) {
                            Toast.fire({ icon: 'error', title: 'El producto ya se encuentra agregado' })
                        } else {
                            setData(state => [...state, material])
                            Toast.fire({ icon: 'success', title: 'Productos agregados' })
                        }
                    }
                }
            }
            setTimeout(() => {
                var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                [].forEach.call(test, function (matche) {
                    matche.setAttribute("colspan", "11");
                });
            }, 100);

        } else {
            Swal.fire({ icon: 'error', title: 'Opps!', text: 'Seleccione un producto' })
        }
    }

    const [editingStateColumnExtensions] = useState([
        { columnName: 'sku', editingEnabled: false },
        { columnName: 'productName', editingEnabled: false },
        { columnName: 'productUnits', editingEnabled: false },
        { columnName: 'productBrand', editingEnabled: false },
        { columnName: 'productModel', editingEnabled: false },
        { columnName: 'saldoDisponible', editingEnabled: false },
        { columnName: 'cantidadSolicitada', editingEnabled: false },
        { columnName: 'observacion', editingEnabled: false },
        { columnName: 'cantidadCompra', editingEnabled: false },
        { columnName: 'status', editingEnabled: false },
    ]);
    const [editingStateColumnExtensionsTwo] = useState([
        { columnName: 'sku', editingEnabled: false },
        { columnName: 'productName', editingEnabled: false },
        { columnName: 'productUnits', editingEnabled: false },
        { columnName: 'productBrand', editingEnabled: false },
        { columnName: 'productModel', editingEnabled: false },
        { columnName: 'saldoDisponible', editingEnabled: false },
        { columnName: 'cantidadCompra', editingEnabled: false },
        { columnName: 'status', editingEnabled: false },
    ]);

    const [statusToShowButton, setStatusToShowButton] = useState("")
    const [projectId, setProjectId] = useState("")
    const [startEditAction, setStartEditAction] = useState('click');
    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);


    useEffect(() => {
        // console.log(location.state)
        // if (location.state.redirect) {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/showTable`, config)
            .then((res) => {
                console.log(res.data.materialRequest)
                let response = []
                for (let i = 0; i < res.data.materialRequest.length; i++) {
                    const element = res.data.materialRequest[i];
                    response.push({
                        "sku": element.materialId.sku,
                        "productName": element.materialId.productName,
                        "category": element.materialId.category ? element.materialId.category : "",
                        "subcategory": element.materialId.subcategory ? element.materialId.subcategory : "",
                        "productUnits": element.materialId.productUnits,
                        "productBrand": element.materialId.productBrand,
                        "productModel": element.materialId.productModel,
                        "saldoDisponible": element.materialId.saldoDisponible,
                        "cantidadSolicitada": element.cantidadSolicitada,
                        "observacion": element.observacion,
                        "status": element.status,
                        "cantidadCompra": element.cantidadCompra,
                        "materialId": element.materialId._id,
                        "_id": element._id,
                    })
                }
                setData(response)

                var ver = document.querySelectorAll('button#edit.dx-g-bs4-table-edit-command-cell');
                [].forEach.call(ver, function (matche) {
                    matche.innerHTML = 'ver';
                });

                var borrar = document.querySelectorAll('button#delete.dx-g-bs4-table-edit-command-cell');
                [].forEach.call(borrar, function (matche) {
                    matche.innerHTML = 'eliminar';
                });

                var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                [].forEach.call(test, function (matche) {
                    matche.setAttribute("colspan", "11");
                });

            })
            .catch(error => {
                // console.log(error)
            })
        // }
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data);
                setStatusToShowButton(res.data.status)
                setProjectId(res.data.projectId)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    function FieldGroup({ id, label, ...props }) {
        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...props} />
            </Form.Group>
        );
    }

    const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
        // const { id } = useParams();
        // const token = useSelector(state => state.auth.token)
        useEffect(() => {
            setCurrentName(row.productName);
        }, [row._id])
        const [currentName, setCurrentName] = useState("");
        return (
            <Modal show={open} onHide={onCancelChanges} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header id="form-dialog-title">
                    Editar Material
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm={6} className="px-2">
                                <FieldGroup
                                    label="Nombre Actual"
                                    value={currentName}
                                    disabled
                                />
                            </Col>
                            <Col sm={6} className="px-2">
                                <FieldGroup
                                    name="productName"
                                    label="Nuevo Nombre"
                                    value={row.productName}
                                    onChange={onChange}
                                    required
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCancelChanges} variant="secondary" css={css`color:white;`}>
                        Cancelar
                    </Button>
                    {' '}
                    <Button onClick={() => {
                        if (row.productName === "") {
                            Swal.fire({ icon: 'error', title: 'Error!', text: 'El nombre no puede estar vacio' })
                            return
                        }
                        if (currentName.toLowerCase().includes("no considerado") || currentName.toLowerCase().includes("no definido")) {
                            let body = {
                                productName: row.productName,
                            }
                            const config = {
                                headers: { 'Authorization': "Bearer " + token }
                            };
                            clienteAxios.put(`/api/project/${idProject}/updateTableRow/${row.materialId}`, body, config)
                                .then((res) => {
                                    console.log(res.data)
                                    onApplyChanges()
                                    setCurrentName(row.productName)
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        } else {
                            Swal.fire({ icon: 'error', title: 'Error!', text: 'Solo es posible editar el nombre si es "No Considerado"' })
                            return
                        }
                    }} variant="warning">
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    // (
    // <Modal className="p-5" show={open} onHide={onCancelChanges} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
    //     <Modal.Header>
    //         <h2>Información</h2>
    //     </Modal.Header>
    //     <Modal.Body>
    //         <Container>
    //             <Row>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         name="sku"
    //                         label="Sku"
    //                         value={row.sku}
    //                         onChange={onChange}
    //                         disabled={true}
    //                     />
    //                 </Col>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         name="productName"
    //                         label="Nombre"
    //                         value={row.productName}
    //                         onChange={onChange}
    //                         disabled={false}
    //                         onBlur={()=> console.log("hola")}
    //                     />
    //                 </Col>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         name="productUnits"
    //                         label="Unidades"
    //                         value={row.productUnits}
    //                         onChange={onChange}
    //                         disabled={true}
    //                     />
    //                 </Col>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         name="productBrand"
    //                         label="Marca"
    //                         value={row.productBrand}
    //                         onChange={onChange}
    //                         disabled={true}
    //                     />
    //                 </Col>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         name="productModel"
    //                         label="Modelo"
    //                         value={row.productModel}
    //                         onChange={onChange}
    //                         disabled={true}
    //                     />
    //                 </Col>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         type="number"
    //                         name="saldoDisponible"
    //                         label="Saldo Disponible"
    //                         value={row.saldoDisponible}
    //                         onChange={onChange}
    //                         disabled={true}
    //                     />
    //                 </Col>
    //                 <Col sm={6} className="px-2">
    //                     <FieldGroup
    //                         type="number"
    //                         name="cantidadSolicitada"
    //                         label="Cantidad Solicitada"
    //                         value={row.cantidadSolicitada}
    //                         onChange={onChange}
    //                         disabled={statusToShowButton === "aprobada" || statusToShowButton === "rechazada" || statusToShowButton === "cerradas" || statusToShowButton === "enviada" ? true : false}
    //                     />
    //                 </Col>
    //                 {statusToShowButton !== "pendiente" && (
    //                     <Col sm={6} className="px-2">
    //                         <FieldGroup
    //                             type="number"
    //                             name="cantidadCompra"
    //                             label="Cantidad Compra"
    //                             value={row.cantidadCompra}
    //                             onChange={onChange}
    //                             disabled={true}
    //                         />
    //                     </Col>
    //                 )}
    //                 {statusToShowButton !== "pendiente" && (
    //                     <Col sm={6} className="px-2">
    //                         <FieldGroup
    //                             name="status"
    //                             label="status"
    //                             value={row.status}
    //                             onChange={onChange}
    //                             disabled={true}
    //                         />
    //                     </Col>
    //                 )}
    //                 <Col sm={12} className="px-2">
    //                     <FieldGroup
    //                         as="textarea"
    //                         row={5}
    //                         name="observacion"
    //                         label="Observación"
    //                         value={row.observacion}
    //                         onChange={onChange}
    //                         disabled={statusToShowButton === "aprobada" || statusToShowButton === "rechazada" || statusToShowButton === "cerradas" || statusToShowButton === "enviada" ? true : false}
    //                     />
    //                 </Col>
    //             </Row>
    //         </Container>
    //     </Modal.Body>
    //     <Modal.Footer>
    //         <Button onClick={onCancelChanges} className="text-light" variant="secondary">
    //             Cancelar
    //         </Button>
    //         {' '}
    //         <Button onClick={onApplyChanges} className="text-light" variant="warning">
    //             Guardar
    //         </Button>
    //     </Modal.Footer>
    // </Modal>

    // );


    const PopupEditing = React.memo(({ popupComponent: Popup }) => (
        <Plugin>
            <Template name="popupEditing">
                <TemplateConnector>
                    {(
                        {
                            rows,
                            getRowId,
                            addedRows,
                            editingRowIds,
                            createRowChange,
                            rowChanges,
                        },
                        {
                            changeRow, changeAddedRow, commitChangedRows, commitAddedRows,
                            stopEditRows, cancelAddedRows, cancelChangedRows,
                        },
                    ) => {
                        const isNew = addedRows.length > 0;
                        let editedRow;
                        let rowId;
                        if (isNew) {
                            rowId = 0;
                            editedRow = addedRows[rowId];
                        } else {
                            [rowId] = editingRowIds;
                            const targetRow = rows.filter(row => getRowId(row) === rowId)[0];
                            editedRow = { ...targetRow, ...rowChanges[rowId] };
                        }

                        const processValueChange = ({ target: { name, value } }) => {
                            const changeArgs = {
                                rowId,
                                change: createRowChange(editedRow, value, name),
                            };
                            if (isNew) {
                                changeAddedRow(changeArgs);
                            } else {
                                changeRow(changeArgs);
                            }
                        };
                        const rowIds = isNew ? [0] : editingRowIds;
                        const applyChanges = () => {
                            if (isNew) {
                                commitAddedRows({ rowIds });
                            } else {
                                stopEditRows({ rowIds });
                                commitChangedRows({ rowIds });
                            }
                        };
                        const cancelChanges = () => {
                            if (isNew) {
                                cancelAddedRows({ rowIds });
                            } else {
                                stopEditRows({ rowIds });
                                cancelChangedRows({ rowIds });
                            }
                        };

                        const open = editingRowIds.length > 0 || isNew;
                        return (
                            <Popup
                                open={open}
                                row={editedRow}
                                onChange={processValueChange}
                                onApplyChanges={applyChanges}
                                onCancelChanges={cancelChanges}
                            />
                        );
                    }}
                </TemplateConnector>
            </Template>
            <Template name="root">
                <TemplatePlaceholder />
                <TemplatePlaceholder name="popupEditing" />
            </Template>
        </Plugin>
    ));

    const recounGroup = () => {
        // console.log('test')
        setTimeout(() => {
            var test = document.querySelectorAll('.dx-g-bs4-group-cell');
            [].forEach.call(test, function (matche) {
                matche.setAttribute("colspan", "11");
            });
        }, 100);
    }
    const [scrollY, setScrollX] = useState(0);

    function logit() {
        let px = document.querySelector('.table-responsive').scrollLeft;
        setScrollX(px)
        var row = document.querySelectorAll('.dx-g-bs4-group-cell');
        [].forEach.call(row, function (matche) {
            matche.setAttribute("colspan", "11");
        });
    }

    useEffect(() => {
        return () => {
            document.querySelector('.table-responsive').addEventListener("scroll", logit);
        };
    });

    return (
        <Layout>
            <>
                <Topbar title={`Solicitud de material`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12} className="position-relative">
                        <div className="">
                            <Grid rows={data} columns={statusToShowButton === "pendiente" ? columnsCreate : columnsUpdate} getRowId={getRowId}>
                                <GroupingState onExpandedGroupsChange={() => recounGroup()} grouping={[{ columnName: 'category' }, { columnName: 'subcategory' }]} />
                                <IntegratedGrouping />
                                <EditingState onCommitChanges={commitChanges} editingCells={editingCells} onEditingCellsChange={setEditingCells} addedRows={[]} onAddedRowsChange={addEmptyRow} columnExtensions={statusToShowButton === "enviada" ? editingStateColumnExtensions : editingStateColumnExtensionsTwo} />
                                <SearchState />
                                <IntegratedFiltering />
                                <Table cellComponent={FocusableCell} />
                                <VirtualTable height={670} cellComponent={Cell} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <TableGroupRow />
                                <TableInlineCellEditing
                                    startEditAction={startEditAction}
                                    selectTextOnEditStart={selectTextOnEditStart}
                                />
                                {statusToShowButton === "aprobada" || statusToShowButton === "rechazada" || statusToShowButton === "cerradas" || statusToShowButton === "enviada" ?
                                    <TableEditColumn showEditCommand />
                                    :
                                    <TableEditColumn showDeleteCommand showEditCommand />
                                }
                                <AutoComplete addItemToTable={addItemToTable} dataGet={data} statusToShowButton={statusToShowButton} />
                                <SearchPanel />
                                <PopupEditing popupComponent={Popup} />
                            </Grid>
                        </div>
                        <p className="mt-2">*Cantidad solicitada ya se encuentra descontada del saldo disponible.</p>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar saveUpdate
                    //send
                    showButtonByUpdateIsTrue={true}
                    dataToPostMaterialRequest={data}
                    paramsMaterialRequest={id}
                    statusToShowButton={statusToShowButton}
                    history={history}
                    projectId={projectId}
                    projectName={location.state ? location.state.projectName : ""}
                    deleteMaterialRequest
                    showButtonDeleteMaterialRequest={data.length > 0 ? false : true}
                />
            </>
        </Layout>
    );
}

export default MaterialRequestUpdate;