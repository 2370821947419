import React, { useState, useEffect } from "react"
// alert
import Swal from 'sweetalert2';
// redux
import { useDispatch, useSelector } from 'react-redux';
// bootstrap
import { Button, Modal, Col, Form } from "react-bootstrap";
//Actions
import { UploadProductsFileAction } from '../../redux/actions/CreateProjectAction';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

import clienteAxios from './../../config/axios';

const CreateProjectPopup = (props) => {
    const token = useSelector(state => state.auth.token)
    const companyId = useSelector(state => state.auth.company)
    const role = useSelector(state => state.auth.user_role)
    const [options, setOptions] = useState([])
    const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
    const [buyersOptions, setBuyersOptions] = useState([])

    const [projectNumber, setProjectNumber] = useState("")
    const [projectName, setProjectName] = useState("")
    const [projectComment, setProjectComment] = useState("")
    const [supervisedById, setSupervisedById] = useState("")
    const [clientId, setClientId] = useState("")
    const [buyerId, setBuyerById] = useState("")
    const [collaboratorsSelected, setCollaboratorsSelected] = useState([])
    const [tagsSelected, setTagsSelected] = useState([])
    const [tagsOption, setTagsOptions] = useState([])
    const [clients, setClients] = useState([])
    const [proyectoPadre, setProyectoPadre] = useState("")
    const [plazoEjecucion, setPlazoEjecucion] = useState("")
    const [ventaPresupuestada, setVentaPresupuestada] = useState("")
    const [manoObraPresupuestada, setManoObraPresupuestada] = useState("")
    const [vigencia, setVigencia] = useState("")
    const [projectNumberSelect, setProjectNumberSelect] = useState([])
    const [loadingNumberProject, setLoadingNumberProject] = useState(false)

    const [supervisedBy, setSupervisedBy] = useState("")
    const [buyerBy, setBuyerBy] = useState("")
    const [collaborators, setCollaborators] = useState("")
    const [tags, setTags] = useState("")





    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const CreateProjectFn = (formdata, history, token) => dispatch(UploadProductsFileAction(formdata, history, token));


    const handleSubmit = async () => {
        if (projectNumber === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Numero del proyecto requerido' })
        } else if (projectName === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del proyecto requerido' })
        } else if (projectComment === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Comentario del proyecto requerido' })
        } else if (supervisedById === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Supervisor del proyecto requerido' })
        } else if (buyerId === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Comprador del proyecto requerido' })
        }
        // else if (clientId === "") {
        //     Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Cliente del proyecto requerido' })
        // } 
        else if (plazoEjecucion === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Plazo de ejecucion requerido' })
        } else if (proyectoPadre === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Proyecto padre requerido' })
        } else if (ventaPresupuestada === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Venta presupuestada requerido' })
        } else if (manoObraPresupuestada === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Mano de obra presupuestada requerido' })
        } else if (vigencia === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Vigencia requerido' })
        }
        else {
            let collaborators = [];
            if (Array.isArray(collaboratorsSelected)) {
                for (let i = 0; i < collaboratorsSelected.length; i++) {
                    let collaborator = collaboratorsSelected[i];
                    collaborators.push(collaborator.value)
                }
            } else {
                let array = [collaboratorsSelected];
                for (let i = 0; i < array.length; i++) {
                    let collaborator = array[i];
                    collaborators.push(collaborator._id)
                }
            }

            let areas = [];
            if (Array.isArray(tagsSelected)) {
                for (let i = 0; i < tagsSelected.length; i++) {
                    let area = tagsSelected[i];
                    areas.push(area.value)
                }
            } else {
                let array = [tagsSelected];
                for (let i = 0; i < array.length; i++) {
                    let area = array[i];
                    areas.push(area._id)
                }
            }

            let formdata = {
                "projectNumber": Number(projectNumber),
                projectName,
                projectComment,
                "supervisedById": supervisedById ? supervisedById._id ? supervisedById._id : supervisedById : "",
                collaborators,
                "buyerId": buyerId ? buyerId._id ? buyerId._id : buyerId : "",
                "clientId": clientId ? clientId._id ? clientId._id : clientId : undefined,
                areas,
                proyectoPadre,
                ventaPresupuestada: Number(ventaPresupuestada),
                manoObraPresupuestada: Number(manoObraPresupuestada),
                vigencia,
                plazoEjecucion
            }
            console.log(formdata)
            CreateProjectFn(formdata, props.history, token)
        }
    }


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        if (role !== "superAdmin") {
            clienteAxios.get(`/api/company/${companyId}/supervisors`, config)
                .then((res) => {
                    // console.log(res.data)
                    let respon = res.data;
                    let finalData = [];
                    for (let i = 0; i < respon.length; i++) {
                        const element = respon[i];
                        finalData.push(
                            { "value": element._id, "label": element.name + " " + element.lastname },
                        )
                    }
                    // promiseOptions(finalData)
                    setOptions(finalData)
                })
                .catch(error => {
                    // console.log(error)
                })
            clienteAxios.get(`/api/company/${companyId}/collaborators`, config)
                .then((res) => {
                    // console.log(res.data)
                    let respon = res.data;
                    let finalData = [];
                    for (let i = 0; i < respon.length; i++) {
                        const element = respon[i];
                        finalData.push(
                            { "value": element._id, "label": element.name + " " + element.lastname },
                        )
                    }
                    setCollaboratorsOptions(finalData)
                })
                .catch(error => {
                    // console.log(error)
                })
            clienteAxios.get(`/api/company/${companyId}/buyers`, config)
                .then((res) => {
                    // console.log(res.data)
                    let respon = res.data;
                    let finalData = [];
                    for (let i = 0; i < respon.length; i++) {
                        const element = respon[i];
                        finalData.push(
                            { "value": element._id, "label": element.name + " " + element.lastname },
                        )
                    }
                    setBuyersOptions(finalData)
                })
                .catch(error => {
                    // console.log(error)
                })
            clienteAxios.get(`/api/area`, config)
                .then((res) => {
                    // console.log(res.data)
                    let respon = res.data;
                    let finalData = [];
                    for (let i = 0; i < respon.length; i++) {
                        const element = respon[i];
                        finalData.push(
                            { "value": element._id, "label": element.area },
                        )
                    }
                    setTagsOptions(finalData)
                })
                .catch(error => {
                    // console.log(error)
                })
        }
    }, [])


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.get(`/api/client`, config)
            .then((res) => {
                console.log(res.data)
                let respon = res.data;
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        { "value": element._id, "label": element.clientRazonSocial },
                    )
                }
                setClients(finalData)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        setLoadingNumberProject(true)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.get(`/api/options/project`, config)
            .then((res) => {
                setLoadingNumberProject(false)
                console.log(res.data)
                let respon = res.data;
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        {
                            ...element,
                            "value": element.number,
                            "label": element.number,
                        }
                    )
                }
                setProjectNumberSelect(finalData)
            })
            .catch(error => {
                setLoadingNumberProject(false)
                // console.log(error)
            })
    }, [])

    const onSelectNumerProject = (e) => {

        console.log(e)

        if (e?.number) {

            setProjectNumber(e.value ? e.value : "")
            setProjectName(e.project ? e.project : "")

            setSupervisedBy(e.supervisor ? e.supervisor?.name + " " + e.supervisor?.lastname : "")
            setSupervisedById(e.supervisor ? e.supervisor : "")

            setBuyerBy(e.buyer ? e.buyer?.name + " " + e.buyer?.lastname : "")
            setBuyerById(e.buyer ? e.buyer : "")

            setCollaborators({
                "label": e.collaborator ? e.collaborator?.name + " " + e.collaborator?.lastname : "",
                "value": e.collaborator ? e.collaborator?._id : ""
            })
            setCollaboratorsSelected(e.collaborator ? e.collaborator : "")

            setTags({
                "label": e.area ? e.area?.area : "",
                "value": e.area ? e.area?._id : ""
            })
            setTagsSelected(e.area ? e.area : "")

            setPlazoEjecucion(e.PlazoEjecucion ? e.PlazoEjecucion : "")
            setProyectoPadre(e.proyectoPadre ? e.proyectoPadre : "")
            setVentaPresupuestada(e.ventaPresupuestada ? e.ventaPresupuestada : "")
            setManoObraPresupuestada(e.manoObraPresupuestada ? e.manoObraPresupuestada : "")
            setVigencia(e.vigencia ? e.vigencia : "")


        } else if (e?.value) {
            setProjectNumber(e.value)
        }

    }


    return (
        <React.Fragment>
            <Modal
                {...props}
                backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title><strong>Crear proyecto</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="col-12 d-md-flex align-items-start">
                        <Col xs={6}>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>Número del proyecto</Form.Label>
                                <CreatableSelect
                                    isClearable
                                    onChange={(e) => onSelectNumerProject(e)}
                                    options={projectNumberSelect}
                                    createOptionPosition="first"
                                />
                            </Form.Group>

                            <Form.Group controlId="projectName">
                                <Form.Label>Proyecto</Form.Label>
                                <Form.Control type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="supervisor">
                                <Form.Label>Supervisor asignado</Form.Label>
                                <Select
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="supervisedById"
                                    inputValue={supervisedBy}
                                    options={options}
                                    onInputChange={(e) => {
                                        console.log(e)
                                        setSupervisedBy(e)
                                    }}
                                    onChange={(e) => setSupervisedById(e?.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="buyer">
                                <Form.Label>Comprador</Form.Label>
                                <Select
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="buyerId"
                                    inputValue={buyerBy}
                                    options={buyersOptions}
                                    onInputChange={(e) => {
                                        console.log(e)
                                        setBuyerBy(e)
                                    }}
                                    onChange={(e) => setBuyerById(e?.value)}

                                />
                            </Form.Group>
                            <Form.Group controlId="colaborators">
                                <Form.Label>Colaboradores</Form.Label>
                                <Select
                                    isMulti
                                    name="colors"
                                    options={collaboratorsOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    inputValue={collaborators?.label}
                                    onInputChange={(e) => {
                                        console.log(e)
                                        setCollaborators(e)
                                    }}
                                    onChange={(e) => setCollaboratorsSelected(e)}
                                />
                            </Form.Group>
                            <Form.Group controlId="Tags">
                                <Form.Label>Areas</Form.Label>
                                <Select
                                    isMulti
                                    name="colors"
                                    options={tagsOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    inputValue={tags?.label}
                                    onInputChange={(e) => {
                                        console.log(e)
                                        setTags(e)
                                    }}
                                    onChange={(e) => setTagsSelected(e)}
                                />
                            </Form.Group>
                            <Form.Group controlId="additionalRequirements">
                                <Form.Label>Comentarios adicionales</Form.Label>
                                <Form.Control as="textarea" rows={3} value={projectComment} onChange={(e) => setProjectComment(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group controlId="supervisor">
                                <Form.Label>Cliente</Form.Label>
                                {/* <Form.Control type="text" placeholder="Carlos Andrade" name="supervisedById" onChange={readFormData} /> */}
                                <Select
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="clientId"
                                    options={clients}
                                    onChange={(e) => setClientId(e.value)}
                                // inputValue={supervisedById}
                                />
                            </Form.Group>
                            <Form.Group controlId="plazoEjecucion">
                                <Form.Label>Plazo de ejecución</Form.Label>
                                <Form.Control type="number" onChange={(e) => setPlazoEjecucion(e.target.value)} value={plazoEjecucion} />
                            </Form.Group>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>Proyecto padre</Form.Label>
                                <Form.Control type="text" onChange={(e) => setProyectoPadre(e.target.value)} value={proyectoPadre} />
                            </Form.Group>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>Venta presupuestada</Form.Label>
                                <Form.Control type="number" onChange={(e) => setVentaPresupuestada(e.target.value)} value={ventaPresupuestada} />
                            </Form.Group>
                            <Form.Group controlId="projectNumber">
                                <Form.Label>Mano de obra presupuestada</Form.Label>
                                <Form.Control type="number" onChange={(e) => setManoObraPresupuestada(e.target.value)} value={manoObraPresupuestada} />
                            </Form.Group>
                            <Form.Group controlId="projectNumber" onChange={(e) => setVigencia(e.target.value)} value={vigencia} >
                                <Form.Label>Vigencia</Form.Label>
                                <Form.Control as="select">
                                    <option value={vigencia}> {vigencia !== "" ? vigencia : "Seleccionar"}</option>
                                    {vigencia !== "abierto" &&
                                        <option value="abierto">Abierto</option>
                                    }
                                    {vigencia !== "cerrado" &&
                                        <option value="cerrado">Cerrado</option>
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="justify-content-center align-items-center">
                    <Col xs={5} className="px-0">
                        <Button variant="warning" className="col-12" onClick={handleSubmit}>Agregar</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        </React.Fragment >

    );
}

export default CreateProjectPopup;