
import React, { useEffect, useState } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col } from 'react-bootstrap'
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
// redux
import { useSelector } from 'react-redux';
import {
    PagingState,
    IntegratedPaging,
    DataTypeProvider,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableColumnResizing,
    Toolbar,
    SearchPanel,
    VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import Layout from "../structures/Layout"

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;
const getRowId = row => row.projectId;

const PercentFormatter = ({ value }) => (
    <span>
        {value}
        %
    </span>
);

const PercentTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={PercentFormatter}
        {...props}
    />
);

const Projects = ({ history }) => {
    const token = useSelector(state => state.auth.token)
    const [graphProjectsGeneralData, setGraphProjectsGeneralData] = useState([])
    const [searchValue, setSearchState] = useState('');

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get('/api/project', config)
            .then((res) => {
                // console.log(res.data)
                const body = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    body.push({
                        projectNumber: element.projectNumber,
                        projectName: element.projectName,
                        purchasePercentage: element.purchasePercentage ? element.purchasePercentage : "0",
                        projectId: element._id,
                    })
                }
                setGraphProjectsGeneralData(body)
            })
            .catch(error => {
            })
    }, [])

    const [columns] = useState([
        { name: 'projectNumber', title: 'Número Proyecto' },
        { name: 'projectName', title: 'Proyecto' },
        { name: 'purchasePercentage', title: 'Porcentaje de compra' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'projectNumber', width: 180 },
        { columnName: 'projectName', width: 150 },
        { columnName: 'purchasePercentage', width: 220 },
    ]);

    const TableRow = ({ row, ...restProps }) => {
        return (<Table.Row {...restProps} onClick={() => history.push(`/proyectos/solicitud-de-material/${row.projectId}`)} style={{ cursor: 'pointer' }} />)
    };

    const TabCell = props => (
        <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    );

    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (<p>{value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </p>)
        } else {
            return (<p>{value}</p>)
        }
    };

    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['totalBudgetAmount', 'totalAmountPurchased']);

    const [percentColumns] = useState([
        'purchasePercentage',
    ]);

    return (
        <Layout>
            <>
                <Topbar title="Proyectos" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card" style={{ height: '720px' }}>
                            <Grid rows={graphProjectsGeneralData} columns={columns} getRowId={getRowId} rootComponent={Root}>
                                <CurrencyTypeProvider for={currencyColumns} />
                                <PercentTypeProvider for={percentColumns} />
                                <SearchState value={searchValue} onValueChange={setSearchState} />
                                <IntegratedFiltering />
                                <Table />
                                <VirtualTable
                                    height={670} height="auto" cellComponent={TabCell} rowComponent={TableRow}
                                />
                                {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
                                <TableHeaderRow />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Projects;