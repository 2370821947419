import React, { useState, useEffect } from "react"
import { Link,useHistory } from 'react-router-dom';
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//Images
import LogoDefaena from './../../resources/images/page/logo_defaena.svg'
import SignInImage from './../../resources/images/login/login.jpg'
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { LoginAction } from '../../redux/actions/AuthAction';
// alert
import Swal from 'sweetalert2';
// router
import { useParams } from "react-router";
import clienteAxios from "../../config/axios";

// styled
/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
// import styled from '@emotion/styled';
const Login = () => {

    const loading = useSelector(state => state.auth.loading)
    const token = useSelector(state => state.auth.token)

    const { urlName } = useParams();

    const [logo, setLogo] = useState("")
    const [background, setBackground] = useState("")



    let history = useHistory();




    const [formdata, setFormData] = useState({
        email: '',
        password: '',
    })

    const { email, password } = formdata;

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const LoginFn = (formdata, history) => dispatch(LoginAction(formdata, history));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        if (email === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Email del administrador requerido' })
        } else if (password === "") {
            if (!password.length > 4 && !password.length < 20) {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Contraseña del administrador requerido' })
            }
        } else {
            LoginFn(formdata, history)
            // console.log(formdata)
        }
    }

    useEffect(() => {
        if (urlName) {
            clienteAxios.get(`/api/companyByName/${urlName}`)
                .then((res) => {
                    // console.log(res.data)
                    if (res.data.length === 0) {
                        setLogo(LogoDefaena)
                        setBackground(SignInImage)
                    } else {
                        setLogo(res.data[0].logo)
                        setBackground(res.data[0].SignInImage)
                    }
                    setBackground(res.data[0].background)
                })
                .catch(error => {
                    // console.log(error)
                })
        }
    }, [])

    return (
        <React.Fragment>
            <Container fluid className="bg_white w-100">
                <Row className="d-lg-flex align-items-center justify-content-center login" css={css`border-radius:10px;`}>
                    <Col xs={12} md={7} lg={5} xl={6} className="text-center justify-content-center pt-5 pt-lg-0">

                        <img src={urlName ? logo : LogoDefaena} className="pb-5 logo" alt="Logo Company" />

                        <Form className="col-lg-11 col-xl-8 mx-auto text-left">
                            <Form.Group controlId="email" className="mb-4">
                                <Form.Control type="email" placeholder="Email" name="email" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="password" className="mb-4">
                                <Form.Control type="password" placeholder="Contraseña" name="password" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group as={Row} controlId="extraInputs" className="mb-4">
                                {/* <Col sm={5}>
                                <Form.Group>
                                    <Form.Check type="checkbox" label="Recordarme" />
                                </Form.Group>
                            </Col> */}
                                {/* <Col sm={7}>
                                <Form.Group className="text-sm-right">
                                    <Link to="/recuperar-contrasena">¿Olvidaste tu contraseña?</Link>
                                </Form.Group>
                            </Col> */}
                            </Form.Group>
                            <Button variant="warning" onClick={handleSubmit} className="col-12 py-3">
                                iniciar sesión
                                {loading && (
                                    <div className="spinner-border text-light ml-2" css={css`width: 1.2rem; height: 1.2rem;`} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </Button>
                        </Form>
                        <p className="text_orange text-center pt-3">No estas registrado? <span><Link to="/registro-empresa">Registrate</Link></span></p>
                        <p className="text_orange text-center pt-5">Todos los Derechos Reservados defaena.</p>
                    </Col>
                    <Col xs={12} md={10} lg={7} xl={6} className="px-0 py-4 py-lg-0 bg_register text-center">
                        <img src={SignInImage} className="img-fluid col-12 px-0 cover_image d-lg-none" alt="Cover" />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default Login;