import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { EditingState, PagingState, IntegratedPaging, DataTypeProvider, SearchState, IntegratedFiltering, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableInlineCellEditing, Toolbar, TableColumnResizing, TableFixedColumns, TableEditRow, PagingPanel, SearchPanel, TableGroupRow, VirtualTable, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { Plugin, Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { UpdatingTableProductsAction } from '../../redux/actions/UploadFileProductActions';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Layout from "../structures/Layout";

const getRowId = row => row._id;

// #FOLD_BLOCK
const StartEditActionSelector = (props) => {
    const { defaultAction, changeAction } = props;
    return (
        <div>
            {'Iniciar edición:'}
            &nbsp;
            <select
                defaultValue={defaultAction}
                onChange={e => changeAction(e.target.value)}
                className="dropdown"
            >
                <option value="click">Click</option>
                <option value="doubleClick">Doble Click</option>
            </select>
        </div>
    );
};

// #FOLD_BLOCK
const SelectTextChecker = (props) => {
    const { isSelectText, changeSelectText } = props;
    return (
        <div
            style={{
                padding: '0em 1em',
            }}
        >
            <label htmlFor="selectTextChecker" className="form-check-label">
                <input
                    type="checkbox"
                    checked={isSelectText}
                    id="selectTextChecker"
                    name="selectTextChecker"
                    className="form-check-input"
                    onChange={e => changeSelectText(e.target.checked)}
                />
                Seleccionar texto al activar
            </label>
        </div>
    );
};

const EditPropsPanel = props => (
    <Plugin name="EditPropsPanel">
        <Template name="toolbarContent">
            <SelectTextChecker {...props} />
            <TemplatePlaceholder />
            <StartEditActionSelector {...props} />
        </Template>
    </Plugin>
);

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);

const PercentFormatter = ({ value }) => (
    <span>
        {value}
        %
    </span>
);

const PercentTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={PercentFormatter}
        {...props}
    />
);

const LoadingTable = styled.div`
    left:0;
    right:0;
    bottom:0;
    top:0;
    margin:auto;
    display: flex;
    justify-content: center;
    z-index: 100;
    align-items: center;
    background: rgba(0,0,0, 0.5);
    div{
        width: 4rem;
        height: 4rem;
    }
`;

const AdminProjectProducts = () => {
    const token = useSelector(state => state.auth.token)
    // ID del parametro enviado por url, pertenece al id del proyecto que se esta viendo.
    const { id } = useParams();
    // actions que actualiza la tabla del state general cuando un row es editado
    const updateTableProductOfState = (changedRows) => dispatch(UpdatingTableProductsAction(changedRows));
    // state local que muestrea el boton de actualizar al detectarse un cambo en un row "onblur"
    const [onEditTable, setOnEditTable] = useState(false)
    const [loadingTable, setLoadingTable] = useState(true)
    const [CSVData, setCSVData] = useState(null);
    const [filterCategory, setFilterCategory] = useState([]);
    const [categoriesAndSUbcategories, setCategoriesAndSUbcategories] = useState([]);
    //DISPATCH
    const dispatch = useDispatch();
    // obteniendo los productos del proyecto almacenados en el estate general
    const ProductsData = useSelector(state => state.uploadFileProduct);

    const headers = [
        { label: "sku", key: "sku" },
        { label: "Nombre", key: "productName" },
        { label: "Unidades", key: "productUnits" },
        { label: "Marca", key: "productBrand" },
        { label: "Tipo", key: "producType" },
        { label: "Modelo", key: "productModel" },
        { label: "Cantidad presupuestada", key: "cantidadPresupuestada" },
        { label: "Cantidad compra", key: "cantidadCompra" },
        { label: "Saldo disponible", key: "saldoDisponible" },
        { label: "Monto presupuestado", key: "montoPresupuestado" },
        { label: "Monto compra", key: "montoCompra" },
        { label: "Total Presupuestado", key: "totalPresupuestado" },
        { label: "Total compra", key: "totalCompra" },
        { label: "Porcentaje compra", key: "porcentajeCompra" },
    ];

    const [columns] = useState([
        { name: 'category', title: 'Categoría' },
        { name: 'subcategory', title: 'Subcategoría' },
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Nombre' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        // { name: 'producType', title: 'Tipo' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'cantidadPresupuestada', title: 'Cantidad presupuestada' },
        { name: 'cantidadCompra', title: 'Cantidad compra' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'montoPresupuestado', title: 'Monto presupuestado' },
        { name: 'montoCompra', title: 'Monto compra' },
        { name: 'totalPresupuestado', title: 'Total Presupuestado' },
        { name: 'totalCompra', title: 'Total compra' },
        { name: 'porcentajeCompra', title: 'Porcentaje compra' },
    ]);

    useEffect(() => {
        setData(ProductsData.listProducts)
        setCSVData(ProductsData.listProducts)
    }, [ProductsData])

    useEffect(() => {
        setLoadingTable(true)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/${id}/showTable`, config)
            .then((res) => {
                let data = []
                for (let i = 0; i < res.data.products.length; i++) {
                    const item = res.data.products[i];
                    data.push(item.category, item.subcategory)
                }
                let unicos = Array.from(new Set(data))
                // console.log(unicos)
                // console.log(res.data)
                setCategoriesAndSUbcategories(unicos)
                setFilterCategory(res.data.filter)
                setCSVData(res.data.products)
                setLoadingTable(false)

                // let body = []
                // for (let i = 0; i < res.data.products.length; i++) {
                //     const product = res.data.products[i];

                //     body.push({
                //         cantidadCompra: product.cantidadCompra,
                //         cantidadPresupuestada:  product.cantidadPresupuestada,
                //         category:  product.category,
                //         disponiblePresupuesto:  body.disponiblePresupuesto,
                //         montoCompra:  product.montoCompra,
                //         montoPresupuestado:  product.montoPresupuestado,
                //         porcentajeCompra:  product.porcentajeCompra + "%",
                //         producType:  product.producType,
                //         productBrand:  product.productBrand,
                //         productModel:  product.productModel,
                //         productName:  product.productName,
                //         productUnits:  product.productUnits,
                //         projectId:  product.projectId,
                //         saldoDisponible:  product.saldoDisponible,
                //         sku:  product.sku,
                //         subcategory:  product.subcategory,
                //         totalCompra:  product.totalCompra,
                //         totalPresupuestado:  product.totalPresupuestado,
                //     })
                // }

                setTimeout(() => {
                    // console.log('se ejecuto')
                    setData(res.data.products)
                }, 2000);
            })
            .catch(error => {
                // console.log(error)
                setLoadingTable(false)
            })
    }, [])

    const [data, setData] = useState([])

    const [tableColumnExtensions] = useState([
        { columnName: 'category', width: 200 },
        { columnName: 'subcategory', width: 200 },
        { columnName: 'sku', width: 80 },
        { columnName: 'productName', width: 150 },
        { columnName: 'productUnits', width: 100 },
        { columnName: 'productBrand', width: 100 },
        { columnName: 'producType', width: 100 },
        { columnName: 'productModel', width: 100 },
        { columnName: 'saldoDisponible', width: 120 },
        { columnName: 'cantidadPresupuestada', width: 100 },
        { columnName: 'cantidadCompra', width: 100 },
        { columnName: 'montoPresupuestado', width: 120 },
        { columnName: 'montoCompra', width: 120 },
        { columnName: 'totalCompra', width: 120 },
        { columnName: 'totalPresupuestado', width: 120 },
        { columnName: 'porcentajeCompra', width: 120 },
    ]);

    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = data.length > 0 ? data[data.length - 1]._id + 1 : 0;
            changedRows = [
                ...data,
                ...added.map((row, index) => ({
                    _id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            changedRows = data.map(row => (changed[row._id] ? { ...row, ...changed[row._id] } : row));
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = data.filter(row => !deletedSet.has(row._id));
        }
        setData(changedRows);
        updateTableProductOfState(changedRows)
        setOnEditTable(true)
    };

    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (<p>{value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>)
        } else {
            return (<p>{value}</p>)
        }
    };

    const TableRow = ({ row, ...restProps }) => {
        // console.log(row)
        return (
            <Table.Row
                {...restProps}
                // eslint-disable-next-line no-alert
                onClick={() => console.log(row)}
                style={{
                    color: row.saldoDisponible < 0 ? '#DD3434' : "#000",
                    fontWeight: row.saldoDisponible < 0 ? 'bold' : "normal"
                }}
            />
        )
    };


    // --------------------------------------------------------------------------
    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['montoPresupuestado', 'totalPresupuestado', 'totalCompra', 'montoCompra']);
    // --------------------------------------------------------------------------
    const [startEditAction, setStartEditAction] = useState('click');
    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);

    const [percentColumns] = useState([
        'porcentajeCompra'
    ]);


    return (
        <Layout>
            <>
                <Topbar gestionProducts setData={setData} onEditTable={onEditTable} title="productos" filterCategory={filterCategory} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12} className="position-relative">
                        {loadingTable && (
                            <LoadingTable className="position-absolute">
                                <div className="spinner-border  text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </LoadingTable>
                        )}
                        <div className="">
                            <Grid rows={data} columns={columns} getRowId={getRowId} >
                                <CurrencyTypeProvider for={currencyColumns} />
                                <PercentTypeProvider for={percentColumns} />
                                <GroupingState grouping={[{ columnName: 'category' }, { columnName: 'subcategory' }]} defaultExpandedGroups={categoriesAndSUbcategories} />
                                <IntegratedGrouping />
                                <EditingState onCommitChanges={commitChanges} />
                                <SearchState />
                                <IntegratedFiltering />
                                <Table cellComponent={FocusableCell} />
                                <VirtualTable height={670} rowComponent={TableRow} />
                                <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
                                <TableHeaderRow />
                                <Toolbar />
                                <TableGroupRow />
                                <EditPropsPanel
                                    defaultAction={startEditAction}
                                    changeAction={setStartEditAction}
                                    isSelectText={selectTextOnEditStart}
                                    changeSelectText={setSelectTextOnEditStart}
                                />
                                <SearchPanel />
                                <TableInlineCellEditing
                                    startEditAction={startEditAction}
                                    selectTextOnEditStart={selectTextOnEditStart}
                                />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar
                    downloadExcel
                    ButtonExcel
                    CSVData={CSVData}
                    headers={headers}
                />
            </>
        </Layout>
    );
}

export default AdminProjectProducts;