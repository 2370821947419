import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { NavLink } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import InputRange from "../extras/InputRange";
import InputRangeDays from "../extras/InputRangeDays";

//Bootstrap
import { Col, Form, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux"
import clienteAxios from "../../config/axios"

const Sidebar = ({ RunRequestFilter, formdata, setFormData, defaultStateGet, isManagerPurchaseMaterial }) => {
    const token = useSelector(state => state.auth.token)

    const [project, setProject] = useState([])
    const [users, setUsers] = useState([])
    const [data, setData] = useState([])
    const [values, setValues] = useState(0)
    const [valuesDays, setValuesDays] = useState(0)


    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        // console.log(formdata)
        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };
        clienteAxios.post(`/api/getMaterialRequestByStatus`, formdata, config)
            .then((res) => {
                // console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])





    useEffect(() => {
        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };
        clienteAxios.get(`/api/log/projectsName`, config)
            .then((res) => {
                // console.log(res.data)
                let resultProject = []
                for (let i = 0; i < res.data.length; i++) {
                    let element = res.data[i];
                    resultProject.push({
                        value: element._id,
                        label: element.projectName,
                    })
                }
                // console.log(resultProject)
                setProject(resultProject)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };
        clienteAxios.get(`/api/users`, config)
            .then((res) => {
                // console.log(res.data)
                let resultUsers = []
                for (let i = 0; i < res.data.length; i++) {
                    let element = res.data[i];
                    resultUsers.push({
                        value: element._id,
                        label: element.name + " " + element.lastname,
                    })
                }
                // console.log(resultUsers)
                setUsers(resultUsers)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])


    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const GetValueDate = (value) => {
        // console.log(value)
        setFormData({
            status: formdata.status,
            projectId: formdata.projectId,
            requestedBy: formdata.requestedBy,
            endOfDay: value[0].endDate,
            startOfDay: value[0].startDate,
            advancePercentageStart: formdata.advancePercentageStart,
            advancePercentageEnd: formdata.advancePercentageEnd,
            daysStart: formdata.daysStart,
            daysEnd: formdata.daysEnd
        })
        // (formdata)
    }

    const setCurrentValue = (value) => {
        setValues(value)
        setFormData({
            status: formdata.status,
            projectId: formdata.projectId,
            requestedBy: formdata.requestedBy,
            endOfDay: formdata.endOfDay,
            startOfDay: formdata.startOfDay,
            startOfDay: formdata.startOfDay,
            advancePercentageStart: formdata.advancePercentageStart,
            advancePercentageEnd: value[0],
            daysStart: formdata.daysStart,
            daysEnd: formdata.daysEnd
        })
    }

    const CurrentValueDays = (value) => {
        setValuesDays(value)
        setFormData({
            status: formdata.status,
            projectId: formdata.projectId,
            requestedBy: formdata.requestedBy,
            endOfDay: formdata.endOfDay,
            startOfDay: formdata.startOfDay,
            startOfDay: formdata.startOfDay,
            advancePercentageStart: formdata.advancePercentageStart,
            advancePercentageEnd: formdata.advancePercentageEnd,
            daysStart: formdata.daysStart,
            daysEnd: value[0],
        })
    }

    return (
        <Col xs={12} className="justify-content-center options">
            <Col sm={8} md={12} className="pb-3 mb-3 pt-lg-3 pb-lg-4">
                <p className="text_big font-weight-bold">Filtros</p>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Control as="select" className="side" name="status" onChange={readFormData}>
                    <option value={defaultStateGet}>Estado</option>
                    {/* <option value={'pendiente'}>Pendiente</option> */}
                    <option value={'enviada'}>Enviada</option>
                    <option value={'aprobada'}>Aprobada</option>
                    {isManagerPurchaseMaterial && (
                        <option value={'cerradas'}>Cerrada</option>
                    )}
                    <option value={'rechazada'}>Rechazada</option>
                </Form.Control>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Control as="select" name="projectId" onChange={readFormData} className="side">
                    <option value="">Proyecto</option>
                    {project.map((item, i) => (
                        <option key={i} value={item.value}>{item.label}</option>
                    ))}
                </Form.Control>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Control as="select" name="requestedBy" onChange={readFormData} className="side">
                    <option value="">Solicitado por</option>
                    {users.map((item, i) => (
                        <option key={i} value={item.value}>{item.label}</option>
                    ))}

                </Form.Control>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">

            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Group controlId="formBasicRange">
                    <Form.Label>Días | {valuesDays}</Form.Label>
                    <div className="d-flex align-items-center">
                        <p className="min_range_value mr-3">0</p>
                        {/* <InputRangeDays CurrentValueDays={CurrentValueDays} rangeMax={31} /> */}
                        <input type="range" min="0" max="31" step="1" value={valuesDays} onChange={(e) => {
                            // console.log(e.target.value)
                            setValuesDays(e.target.value)
                            // CurrentValueDays(e.target.value)
                        }} />
                        <p className="max_range_value ml-3">31</p>
                    </div>
                </Form.Group>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Group controlId="formBasicRange">
                    <Form.Label>Avance | {values}%</Form.Label>
                    <div className="d-flex align-items-center">
                        <p className="min_range_value mr-3">0</p>
                        {/* <InputRange setCurrentValue={setCurrentValue} rangeMax={100} /> */}
                        <input type="range" min="0" max="100" step="1" value={values} onChange={(e) => {
                            // console.log(e.target.value)
                            setValues(e.target.value)
                            // CurrentValueDays(e.target.value)
                        }} />
                        <p className="max_range_value ml-3">100</p>
                    </div>
                </Form.Group>
            </Col>
            <Col sm={8} md={12} className="pb-3 px-0 mx-auto text-center">
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                        setDate([item.selection])
                        GetValueDate([item.selection])
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                />
            </Col>
        </Col >
    );
}


export default Sidebar;