import React, { useState, useEffect } from "react"
//Navbar
import Navbar from '../navigation/Navbar'
//Bootstrap
import { Container, Row, Col, Button } from "react-bootstrap";
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBars
} from '@fortawesome/free-solid-svg-icons'

const Layout = ({ history, children, asideWidth }) => {

    const [isArray, setIsArray] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [showSidebar, setShowSidebar] = useState(false)

    useEffect(() => {
        setIsArray(Array.isArray(children))
    }, [children])


    const NavbarContainerResponsive = styled(Col)`
        @media (max-width:992px){
            position:fixed !important;
            left:0;
            top:0;
            transition:all .2s;
            z-index: 9999;
            width:100px;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
            padding-left:0 !important;
            padding-right:0 !important;
            height:100%;
            background-color: #F5F5F5;
        }
    `
    const SidebarContainerResponsive = styled(Col)`
        @media (max-width:992px){
            position:fixed !important;
            right:0;
            top:0;
            transition:all .2s;
            z-index: 9999;
            width:auto;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
            padding-left:0 !important;
            padding-right:0 !important;
            height:100%;
            background-color: #F5F5F5;
        }
    `
    const ButtonNav = styled.div`
        position: absolute;
        top: 10px;
        right: -5rem;
        z-index: 999!important;
    `
    const ButtonSide = styled.div`
        position: absolute;
        top: 10px;
        left: -7rem;
        z-index: 999!important;
    `
    return (
        <Container className="bg_main">
            <Row>
                <NavbarContainerResponsive lg={1} className="py-3 px-0 position-relative" css={css` @media (max-width:992px){transform:${showFilter ? 'translateX(0%)' : 'translateX(-99.99%)'};} `} >
                    <Navbar history={history} />
                    <ButtonNav className="d-block d-lg-none">
                        <Button onClick={() => setShowFilter(showFilter ? false : true)} variant="warning" size="sm">Menu <FontAwesomeIcon icon={faBars} /></Button>
                    </ButtonNav>
                </NavbarContainerResponsive>
                <Col lg={children[1] ? asideWidth ? 8 : 10 : 11} className="content border-left border-lg-right py-3">
                    {isArray ? children[0] : children}
                </Col>
                {children[1] && (
                    <SidebarContainerResponsive lg={asideWidth ? asideWidth : 1} className="pt-3 pb-3 pt-lg-3 px-0 border-left border-lg-0 ml-auto position-relative" css={css` @media (max-width:992px){transform:${showSidebar ? 'translateX(0%)' : 'translateX(99.99%)'};} `} >
                        {children[1]}
                        <ButtonSide className="d-block d-lg-none">
                            <Button onClick={() => setShowSidebar(showSidebar ? false : true)} variant="warning" size="sm">Acciones <FontAwesomeIcon icon={faBars} /></Button>
                        </ButtonSide>
                    </SidebarContainerResponsive>
                )}
            </Row>
        </Container>
    );
}

export default Layout;