import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from "react-redux";
import clienteAxios from "../../config/axios";
import {
    PagingState,
    IntegratedPaging,
    DataTypeProvider,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    VirtualTable,
    TableFixedColumns,
    TableColumnResizing,
    SearchPanel,
    Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';

const ManagerReport = ({ history }) => {
    const [searchValue, setSearchState] = useState('');
    const token = useSelector(state => state.auth.token)
    const [graphProjectsGeneralData, setGraphProjectsGeneralData] = useState([])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/graphProjectsGeneralData`, config)
            .then((res) => {
                // console.log(res.data)
                const body = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    body.push({
                        projectNumber: element.projectNumber,
                        projectName: element.projectName,
                        solicitudes: element.solicitudes,
                        pendiente: element.pendiente,
                        enviada: element.enviada,
                        aprobada: element.aprobada,
                        cerradas: element.cerradas,
                        diasPromedio: element.diasPromedio.toFixed(1),
                        purchasePercentage: element.purchasePercentage + "%",
                        totalAmountPurchased: element.totalAmountPurchased,
                        totalBudgetAmount: element.totalBudgetAmount,
                        projectId: element.projectId,
                    })
                }
                // console.log(res.data)
                setGraphProjectsGeneralData(body)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const [leftColumns] = useState(['projectName']);

    const [columns] = useState([
        { name: 'projectNumber', title: 'Número Proyecto' },
        { name: 'projectName', title: 'Proyecto' },
        { name: 'solicitudes', title: 'Cantidad de solicitudes' },
        { name: 'pendiente', title: 'Pendiente' },
        { name: 'enviada', title: 'Enviada' },
        { name: 'aprobada', title: 'Aprobada' },
        { name: 'cerradas', title: 'Cerrada' },
        { name: 'diasPromedio', title: 'Días promedio de entrega' },
        { name: 'purchasePercentage', title: 'Porcentaje de compra' },
        { name: 'totalAmountPurchased', title: 'Monto total comprado' },
        { name: 'totalBudgetAmount', title: 'Monto total presupuestado' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'projectNumber', width: 100 },
        { columnName: 'projectName', width: 120 },
        { columnName: 'solicitudes', width: 150 },
        { columnName: 'pendiente', width: 120 },
        { columnName: 'enviada', width: 120 },
        { columnName: 'aprobada', width: 120 },
        { columnName: 'cerradas', width: 120 },
        { columnName: 'diasPromedio', width: 150 },
        { columnName: 'purchasePercentage', width: 150 },
        { columnName: 'totalAmountPurchased', width: 150 },
        { columnName: 'totalBudgetAmount', width: 150 },
    ]);

    const TableRow = ({ row, ...restProps }) => {
        // console.log(row)
        return (
            <Table.Row
                {...restProps}
                // eslint-disable-next-line no-alert
                onClick={() => history.push(`/informe/productos-de-proyecto/${row.projectId}`)}
                style={{
                    cursor: 'pointer',
                }}
            />
        )
    };

    const TabCell = props => (
        <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    );


    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (
                <p>
                    {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </p>
            )
        } else {
            return (
                <p>
                    {value}
                </p>
            )
        }
    };

    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['totalAmountPurchased', 'totalBudgetAmount']);

    return (
        <Layout>
            <>
                <Topbar title="Informes" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card">
                            <Grid
                                rows={graphProjectsGeneralData}
                                columns={columns}
                            >
                                <CurrencyTypeProvider
                                    for={currencyColumns}
                                />
                                <SearchState value={searchValue} onValueChange={setSearchState} />
                                <IntegratedFiltering />
                                <Table />
                                <VirtualTable height={670} cellComponent={TabCell} rowComponent={TableRow} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <TableFixedColumns
                                    leftColumns={leftColumns}
                                />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar
                    DownloadInformes
                    DownloadInformesData={graphProjectsGeneralData}
                />
            </>
        </Layout>
    );
}

export default ManagerReport;