import React, { useState, useEffect } from 'react';
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// redux
import { useDispatch, useSelector } from 'react-redux';

import Selectx from 'react-select';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Swal from 'sweetalert2';

const ButtonAction = styled(Button)`
    padding: 0px 30px!important;
    height: 40px;
    color: white;
    margin-left: 20px;
`;
const Select = styled(Form.Control)`
     -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
`;
const SelectWithLigtherBackground = styled(Select)`

`;

const Autocomplet = ({ addItemToTable, dataGet, statusToShowButton, openAllsCategory, OpenAlls }) => {
    const [valueFilter, setValueFilter] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { id } = useParams();
    const user_role = useSelector(state => state.auth.user_role)
    const token = useSelector(state => state.auth.token)
    // const id = useSelector(state => state.saveProyectIDToMaterialRequest.id)
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const pluginDependencies = [
        { name: 'Toolbar' }
    ];

    const [options, setOptions] = useState([])
    const [dataGeneral, setDataGeneral] = useState([])
    const [currentItemSelectedData, setCurrentItemSelectedData] = useState(null)
    const [dataTOFilter, setDataToFilter] = useState([])
    const [idProject, setProjectId] = useState()
    const [loadingFilter, setLoadingFilter] = useState(false)
    const [deleteFilter, setDeleteFilter] = useState(1)
    const [isFilter, setIsfilter] = useState(false)

    const config = { headers: { 'Authorization': "Bearer " + token } };

    useEffect(() => {
        setIsfilter(false)
        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data);
                setProjectId(res.data.projectId)
                clienteAxios.get(`/api/project/${res.data.projectId}/showTable`, config)
                    .then((res) => {
                        let respon = res.data.products;
                        // console.log(respon)
                        setDataGeneral(respon)
                        let finalData = [];
                        for (let i = 0; i < respon.length; i++) {
                            const element = respon[i];
                            finalData.push(
                                { "value": element._id, "label": element.productName },
                            )
                        }
                        setOptions(finalData)
                    })
                    .catch(error => { })

                clienteAxios.get(`/api/project/${res.data.projectId}/categoryAndSubcategory`, config)
                    .then((res) => {
                        // console.log(res.data)
                        let filterCategory = res.data
                        let data = []
                        for (var key1 in filterCategory) {
                            let subcategory = [];
                            for (var key2 in filterCategory[key1]) {
                                subcategory.push(filterCategory[key1][key2])
                            }
                            data.push({
                                category: key1,
                                subcategory
                            })
                        }
                        setDataToFilter(data)
                    })
                    .catch(error => { })
            })
            .catch(error => { })




    }, [deleteFilter])

    const getDataValue = (data, curretArr) => {
        // console.log(data)
        let dataTest = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            for (let j = 0; j < curretArr.length; j++) {
                const element2 = curretArr[j];

                if (element._id === element2.value) {
                    // console.log(element)
                    // setCurrentItemSelectedData(element)
                    let id = i;
                    let body = {
                        cantidadPresupuestada: element.cantidadPresupuestada,
                        cantidadSolicitada: element.cantidadSolicitada ? element.cantidadSolicitada : "0",
                        cantidadCompra: element.cantidadCompra,
                        montoPresupuestado: element.montoPresupuestado,
                        category: element.category,
                        subcategory: element.subcategory,
                        montoCompra: element.montoCompra,
                        totalPresupuestado: element.totalPresupuestado,
                        disponiblePresupuesto: element.disponiblePresupuesto,
                        ocerp: element.ocerp ? element.ocerp : "No definido",
                        Documento: element.Documento ? element.Documento : "No definido",
                        observacion: element.observacion ? element.observacion : "No definido",
                        status: element.status ? element.status : "pendiente",
                        // Documento: element.Documento,
                        productBrand: element.productBrand,
                        productModel: element.productModel,


                        productName: element.productName,
                        productUnits: element.productUnits,
                        saldoDisponible: element.saldoDisponible,
                        sku: element.sku,
                        materialId: element._id,
                        _id: id.toString()
                    }
                    dataTest.push(body)

                }
            }
        }
        // console.log(dataTest)
        setCurrentItemSelectedData(dataTest)
    }

    const filter = () => {
        // console.log(valueFilter)

        let Ccategory = valueFilter.split('*&')[0]
        let Csubcategory = valueFilter.split('*&')[1]
        let body = {
            "category": Ccategory,
            "subcategory": Csubcategory,
        }
        // console.log(body)
        setLoadingFilter(true)
        clienteAxios.post(`/api/project/${idProject}/showTable`, body, config)
            .then((res) => {
                setShow(false)
                // console.log(res.data)
                let respon = res.data.products;
                // console.log(respon)
                setDataGeneral(respon)
                setLoadingFilter(false)
                setIsfilter(true)
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        { "value": element._id, "label": element.productName },
                    )
                }
                setOptions(finalData)
            })
            .catch(error => { setLoadingFilter(false) })



        // const inputOptions = new Promise((resolve) => {
        //     setTimeout(() => {
        //         resolve(dataTOFilter)
        //     }, 1000)
        // })

        // const { value: item, value } = await Swal.fire({
        //     title: 'Categorías y Subcategorías',
        //     input: 'select',
        //     cancelButtonText: "Cancelar",
        //     confirmButtonText: "Aceptar",
        //     inputOptions: inputOptions,
        //     inputPlaceholder: 'Seleccionar',
        //     showCancelButton: true,
        //     inputValidator: (value) => {
        //         return new Promise((resolve) => {
        //             if (value) {
        //                 resolve()
        //             } else {
        //                 resolve('Debe seleccionar un elemento')
        //             }
        //         })
        //     }
        // })

        // if (item) {
        // let body = {
        //     "description": item
        // }
        // console.log(body)
        // setLoadingFilter(true)
        // clienteAxios.post(`/api/project/${idProject}/showTable`, body, config)
        //     .then((res) => {
        //         console.log(res.data)
        //         let respon = res.data.products;
        //         // console.log(respon)
        //         setDataGeneral(respon)
        //         setLoadingFilter(false)
        //         setIsfilter(true)
        //         let finalData = [];
        //         for (let i = 0; i < respon.length; i++) {
        //             const element = respon[i];
        //             finalData.push(
        //                 { "value": element._id, "label": element.productName },
        //             )
        //         }
        //         setOptions(finalData)
        //     })
        //     .catch(error => { setLoadingFilter(false) })
        // }
    }

    return (

        <Plugin name="ToolbarAutocomplet" dependencies={pluginDependencies}>
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                <Form.Group controlId="products" className="w-100">
                    <Form.Label>Productos disponibles</Form.Label>
                    {/* <Form.Control type="text" placeholder="Carlos Andrade" name="supervisedById" onChange={readFormData} /> */}
                    <Row className="d-flex align-items-center w-100">
                        <Col lg={5} xs={12} className="pr-0">
                            <Selectx
                                isLoading={loadingFilter}
                                closeMenuOnSelect={false}
                                isMulti
                                name="productID"
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => getDataValue(dataGeneral, e)}
                            />
                        </Col>

                        {statusToShowButton !== "enviada" ?
                            user_role === "gerente" || user_role === "supervisor" || user_role === "comprador" ?
                                <Col className="d-flex px-0 mt-2 mt-lg-0">
                                    <ButtonAction variant="secondary" onClick={() => addItemToTable(currentItemSelectedData)}> Agregar</ButtonAction>
                                    <div className="btn-group btn-group-toggle mx-3" data-toggle="buttons">
                                        <label className="btn btn-secondary text-white">
                                            <input type="radio" name="options" onClick={() => setShow(true)} id="option1" autocomplete="off" /> Filtrar
                                        </label>
                                        {isFilter && (
                                            <label className="btn btn-warning text-white">
                                                <input type="radio" name="options" id="option2" onClick={() => {
                                                    setDeleteFilter(deleteFilter + 1)
                                                    Toast.fire({ icon: 'success', title: 'Filtado eliminado' })
                                                }} autocomplete="off" />X
                                            </label>
                                        )}
                                    </div>

                                    {openAllsCategory && (
                                        <Button variant="warning" className="borderRadiusNone" onClick={() => OpenAlls()}>abrir todos </Button>
                                    )}
                                </Col>
                                :
                                <div></div>
                            :
                            <div></div>
                        }

                    </Row>
                </Form.Group>
            </Template>
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Categorias y Subcategorias</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SelectWithLigtherBackground as="select" id="edition" onChange={(e) => setValueFilter(e.target.value)}>
                        <option value="">Seleccionar</option>
                        {dataTOFilter.map((item, i) => {
                            return (
                                <>
                                    <option className="font-weight-bold" key={i} value={item.category}>{item.category}</option>
                                    {item.subcategory.map((sub, i2) => (
                                        <option key={i2} value={item.category + "*&" + sub}>&nbsp;&nbsp;&nbsp;{sub}</option>
                                    ))}
                                </>
                            )
                        })}
                    </SelectWithLigtherBackground>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="text-white">
                        Cancelar
                    </Button>
                    <Button variant="warning" onClick={() => filter()} className="text-white">
                        Filtrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Plugin>
    );
}

export default Autocomplet;