import React, { useState, useEffect } from 'react';
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Form, Button } from 'react-bootstrap';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// redux
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Swal from 'sweetalert2';
import styled from '@emotion/styled';

const ButtonAction = styled(Button)`
    padding: 0px 30px!important;
    height: 40px;
    color: white;
    margin-left: 20px;
`;

const Autocomplet = ({ addItemToTable, statusToShowButton }) => {
    const { id } = useParams();
    const user_role = useSelector(state => state.auth.user_role)
    const token = useSelector(state => state.auth.token)
    // const id = useSelector(state => state.saveProyectIDToMaterialRequest.id)

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const pluginDependencies = [
        { name: 'Toolbar' }
    ];

    const [options, setOptions] = useState([])
    const [dataGeneral, setDataGeneral] = useState([])
    const [currentItemSelectedData, setCurrentItemSelectedData] = useState("")
    const [dataTOFilter, setDataToFilter] = useState()
    const [idProject, setProjectId] = useState()
    const [loadingFilter, setLoadingFilter] = useState(false)
    const [deleteFilter, setDeleteFilter] = useState(1)
    const [isFilter, setIsfilter] = useState(false)

    const config = {
        headers: { 'Authorization': "Bearer " + token }
    };
    useEffect(() => {
        setIsfilter(false)
        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data);
                setProjectId(res.data.projectId)
                clienteAxios.get(`/api/project/${res.data.projectId}/showTable`, config)
                    .then((res) => {
                        let respon = res.data.products;
                        setDataGeneral(respon)


                        let finalData = [];
                        for (let i = 0; i < respon.length; i++) {
                            const element = respon[i];
                            finalData.push(
                                { "value": element._id, "label": element.productName },
                            )
                        }
                        setOptions(finalData)
                    })
                    .catch(error => { })

                clienteAxios.get(`/api/project/${res.data.projectId}/categoryAndSubcategory`, config)
                    .then((res) => {
                        // console.log(res.data)
                        setDataToFilter(res.data)
                    })
                    .catch(error => { })
            })
            .catch(error => {
                // console.log(error)
            })

    }, [deleteFilter])

    // const getDataValue = (data, currentItem) => {
    //     for (let i = 0; i < data.length; i++) {
    //         const element = data[i];
    //         if (element._id === currentItem) {
    //             // console.log(element)
    //             setCurrentItemSelectedData(element)
    //         }
    //     }
    // }
    const getDataValue = (data, curretArr) => {

        let dataTest = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            for (let j = 0; j < curretArr.length; j++) {
                const element2 = curretArr[j];

                if (element._id === element2.value) {
                    dataTest.push(element)
                }
            }
        }
        // console.log(dataTest)
        setCurrentItemSelectedData(dataTest)
    }




    const filter = async () => {
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
                resolve(dataTOFilter)
            }, 1000)
        })

        const { value: item } = await Swal.fire({
            title: 'Categorías y Subcategorías',
            input: 'select',
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
            inputOptions: inputOptions,
            inputPlaceholder: 'Seleccionar',
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve()
                    } else {
                        resolve('Debe seleccionar un elemento')
                    }
                })
            }
        })

        if (item) {
            let body = {
                "description": item
            }
            // console.log(body)
            setLoadingFilter(true)
            setIsfilter(true)
            clienteAxios.post(`/api/project/${idProject}/showTable`, body, config)
                .then((res) => {
                    // console.log(res.data)
                    let respon = res.data.products;
                    // console.log(respon)
                    setDataGeneral(respon)
                    setLoadingFilter(false)
                    let finalData = [];
                    for (let i = 0; i < respon.length; i++) {
                        const element = respon[i];
                        finalData.push(
                            { "value": element._id, "label": element.productName },
                        )
                    }
                    setOptions(finalData)
                })
                .catch(error => { setLoadingFilter(false) })
        }
    }
    // setDefaultData(defaultData + 1)
    return (

        <Plugin name="ToolbarAutocomplet" dependencies={pluginDependencies}>
            <Template name="toolbarContent">
                <TemplatePlaceholder />
                <Form.Group controlId="products" className="w-100">
                    <Form.Label>Productos disponibles</Form.Label>
                    {/* <Form.Control type="text" placeholder="Carlos Andrade" name="supervisedById" onChange={readFormData} /> */}
                    <div className="d-flex align-items-center w-100" css={css`
                        .select__control{
                            min-width: 400px;
                            max-width: 600px;
                        }
                    `}>
                        <Select
                            isLoading={loadingFilter}
                            closeMenuOnSelect={false}
                            isMulti
                            name="productID"
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => getDataValue(dataGeneral, e)}
                        />
                        {statusToShowButton !== "enviada" ?
                            user_role === "gerente" || user_role === "supervisor" ?
                                <div className="d-flex">
                                    <ButtonAction variant="secondary" onClick={() => addItemToTable(currentItemSelectedData)}> Agregar</ButtonAction>

                                    <div className="btn-group btn-group-toggle mx-3" data-toggle="buttons">
                                        <label className="btn btn-secondary text-white">
                                            <input type="radio" name="options" onClick={() => filter()} id="option1" autocomplete="off" /> Filtrar
                                        </label>
                                        {isFilter && (
                                            <label className="btn btn-warning text-white">
                                                <input type="radio" name="options" id="option2" onClick={() => {
                                                    setDeleteFilter(deleteFilter + 1)
                                                    Toast.fire({ icon: 'success', title: 'Filtado eliminado' })
                                                }} autocomplete="off" />X
                                            </label>
                                        )}
                                    </div>
                                </div>
                                :
                                <div></div>
                            :
                            <div></div>
                        }
                    </div>
                </Form.Group>
            </Template>
        </Plugin>
    );
}

export default Autocomplet;