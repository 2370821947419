import React, { useState, useEffect } from "react"
// alert
import Swal from 'sweetalert2';
// redux
import { useDispatch, useSelector } from 'react-redux';
// bootstrap
import { Button, Modal, Col, Form } from "react-bootstrap";

import Select from 'react-select';

import clienteAxios from './../../config/axios';

function ModalFn(props) {
    const token = useSelector(state => state.auth.token)
    const companyId = useSelector(state => state.auth.company)
    const [collaboratorsOptions, setCollaboratorsOptions] = useState([])
    const [collaboratorsSelected, setCollaboratorsSelected] = useState([])


    const config = {
        headers: { 'Authorization': "Bearer " + token }
    };

    useEffect(() => {
        clienteAxios.get(`/api/company/${companyId}/collaborators`, config)
            .then((res1) => {
  

                clienteAxios.get(`/api/collaborators/project/${props.projectId}`, config)
                    .then((res2) => {


                        const collaboratorsOptions = res1.data.filter(collaborator => {
                            return !res2.data.find(collaboratorProject => collaboratorProject.collaboratorId._id === collaborator._id)
                        })


                        let options = []
                        for (let i = 0; i < collaboratorsOptions.length; i++) {
                            const element = collaboratorsOptions[i];
                            options.push(
                                { "value": element._id, "label": element.name + " " + element.lastname },
                            )
                        }

                        setCollaboratorsOptions(options)

                    })
                    .catch(error => {
                        console.log('error 2')
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log('error 1')
                console.log(error)
            })
    }, [props.updateCollaborators])


    useEffect(() => {
        console.log(props)
    }, [props])



    const handleSubmit = (e) => {
        e.preventDefault();
        let collaborators = [];
        for (let i = 0; i < collaboratorsSelected.length; i++) {
            let collaborator = collaboratorsSelected[i];
            collaborators.push(collaborator.value)
        }

        console.log(collaborators)

        let body = {
            collaborators
        }

        clienteAxios.post(`/api/collaborator/project/${props.projectId}`, body, config)
            .then((res) => {
                console.log(res.data)
                props.setUpdateCollaborators(props.updateCollaborators + 1)
                props.onHide()
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Agregar colaboradores
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="colaborators">
                    <Form.Label>Colaboradores</Form.Label>
                    {/* <Form.Control type="text" placeholder="Carlos Andrade" name="supervisedById" onChange={readFormData} /> */}
                    <Select
                        // defaultValue={[colourOptions[2], colourOptions[3]]}
                        isMulti
                        name="colors"
                        options={collaboratorsOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => setCollaboratorsSelected(e)}
                    />
                </Form.Group>
                <Button variant="warning" onClick={handleSubmit}>Agregar</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="secondary" className="text-light" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalFn;
