import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';

import { EditingState, PagingState, IntegratedPaging, DataTypeProvider, SearchState, IntegratedFiltering, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableInlineCellEditing, Toolbar, TableColumnResizing, TableFixedColumns, TableEditRow, PagingPanel, SearchPanel, TableGroupRow, VirtualTable, TableEditColumn } from '@devexpress/dx-react-grid-bootstrap4';
import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AutoComplete from './../extras/autocomplet';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import Layout from "../structures/Layout";
const getRowId = row => row._id;

// #FOLD_BLOCK
const StartEditActionSelector = (props) => {
    const { defaultAction, changeAction } = props;
    return (
        <div>
            {'Iniciar edición:'}
            &nbsp;
            <select
                defaultValue={defaultAction}
                onChange={e => changeAction(e.target.value)}
                className="dropdown"
            >
                <option value="click">Click</option>
                <option value="doubleClick">Doble Click</option>
            </select>
        </div>
    );
};

// #FOLD_BLOCK
const SelectTextChecker = (props) => {
    const { isSelectText, changeSelectText } = props;
    return (
        <div
            style={{
                padding: '0em 1em',
            }}
        >
            <label htmlFor="selectTextChecker" className="form-check-label">
                <input
                    type="checkbox"
                    checked={isSelectText}
                    id="selectTextChecker"
                    name="selectTextChecker"
                    className="form-check-input"
                    onChange={e => changeSelectText(e.target.checked)}
                />
                Seleccionar texto al activar
            </label>
        </div>
    );
};

const EditPropsPanel = props => (
    <Plugin name="EditPropsPanel">
        <Template name="toolbarContent">
            <SelectTextChecker {...props} />
            <TemplatePlaceholder />
            <StartEditActionSelector {...props} />
        </Template>
    </Plugin>
);

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);



function FieldGroup({ id, label, ...props }) {
    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control {...props} />
        </Form.Group>
    );
}

const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => {
    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    useEffect(() => {
        setCurrentName(row.productName);
    }, [row._id])
    const [currentName, setCurrentName] = useState("");

    return (
        <Modal show={open} onHide={onCancelChanges} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header id="form-dialog-title">
                Editar Material
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col sm={6} className="px-2">
                            <FieldGroup
                                label="Nombre Actual"
                                value={currentName}
                                disabled
                            />
                        </Col>
                        <Col sm={6} className="px-2">
                            <FieldGroup
                                name="productName"
                                label="Nuevo Nombre"
                                value={row.productName}
                                onChange={onChange}
                                required
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancelChanges} variant="secondary" css={css`color:white;`}>
                    Cancelar
                </Button>
                {' '}
                <Button onClick={() => {
                    if (row.productName === "") {
                        Swal.fire({ icon: 'error', title: 'Error!', text: 'El nombre no puede estar vacio' })
                        return
                    }
                    if (currentName.toLowerCase().includes("no considerado") || currentName.toLowerCase().includes("no definido")) {
                        let body = {
                            productName: row.productName,
                        }
                        const config = {
                            headers: { 'Authorization': "Bearer " + token }
                        };
                        clienteAxios.put(`/api/project/${row.projectId}/updateTableRow/${row._id}`, body, config)
                            .then((res) => {
                                console.log(res.data)
                                onApplyChanges()
                                setCurrentName(row.productName)
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    } else {
                        Swal.fire({ icon: 'error', title: 'Error!', text: 'Solo es posible editar el nombre si es "No Considerado"' })
                        return
                    }
                }} variant="warning">
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const PopupEditing = React.memo(({ popupComponent: Popup }) => (
    <Plugin>
        <Template name="popupEditing">
            <TemplateConnector>
                {(
                    {
                        rows,
                        getRowId,
                        addedRows,
                        editingRowIds,
                        createRowChange,
                        rowChanges,
                    },
                    {
                        changeRow, changeAddedRow, commitChangedRows, commitAddedRows,
                        stopEditRows, cancelAddedRows, cancelChangedRows,
                    },
                ) => {
                    const isNew = addedRows.length > 0;
                    let editedRow;
                    let rowId;
                    if (isNew) {
                        rowId = 0;
                        editedRow = addedRows[rowId];
                    } else {
                        [rowId] = editingRowIds;
                        const targetRow = rows.filter(row => getRowId(row) === rowId)[0];
                        editedRow = { ...targetRow, ...rowChanges[rowId] };
                    }

                    const processValueChange = ({ target: { name, value } }) => {
                        const changeArgs = {
                            rowId,
                            change: createRowChange(editedRow, value, name),
                        };
                        if (isNew) {
                            changeAddedRow(changeArgs);
                        } else {
                            changeRow(changeArgs);
                        }
                    };
                    const rowIds = isNew ? [0] : editingRowIds;
                    const applyChanges = () => {
                        if (isNew) {
                            commitAddedRows({ rowIds });
                        } else {
                            stopEditRows({ rowIds });
                            commitChangedRows({ rowIds });
                        }
                    };
                    const cancelChanges = () => {
                        if (isNew) {
                            cancelAddedRows({ rowIds });
                        } else {
                            stopEditRows({ rowIds });
                            cancelChangedRows({ rowIds });
                        }
                    };

                    const open = editingRowIds.length > 0 || isNew;
                    return (
                        <Popup
                            open={open}
                            row={editedRow}
                            onChange={processValueChange}
                            onApplyChanges={applyChanges}
                            onCancelChanges={cancelChanges}
                        />
                    );
                }}
            </TemplateConnector>
        </Template>
        <Template name="root">
            <TemplatePlaceholder />
            <TemplatePlaceholder name="popupEditing" />
        </Template>
    </Plugin>
));



const MaterialRequest = ({ location, history }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    //DISPATCH
    const dispatch = useDispatch();

    const [data, setData] = useState([])

    const [columns] = useState([
        { name: 'category', title: 'Categoría' },
        { name: 'subcategory', title: 'Subcategoría' },
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Nombre' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        { name: 'observacion', title: 'Observacion' },
    ]);
    const [editingStateColumnExtensions] = useState([
        { columnName: 'sku', editingEnabled: false },
        { columnName: 'productName', editingEnabled: false },
        { columnName: 'productBrand', editingEnabled: false },
        { columnName: 'productModel', editingEnabled: false },
        { columnName: 'productUnits', editingEnabled: false },
        { columnName: 'saldoDisponible', editingEnabled: false },
    ]);

    const [tableColumnExtensions] = useState([
        { columnName: 'category', width: 200 },
        { columnName: 'subcategory', width: 200 },
        { columnName: 'sku', width: 80 },
        { columnName: 'productName', width: 150 },
        { columnName: 'productUnits', width: 120 },
        { columnName: 'productBrand', width: 120 },
        { columnName: 'productModel', width: 120 },
        { columnName: 'saldoDisponible', width: 170 },
        { columnName: 'cantidadSolicitada', width: 180 },
        { columnName: 'cantidadCompra', width: 120 },
        { columnName: 'status', width: 120 },
        { columnName: 'observacion', width: 150 },
    ]);


    const HighlightedCell = ({ value, style, ...restProps }) => {
        return (
            <Table.Cell {...restProps}>
                <span style={{
                    color: Number(value) < restProps.row.saldoDisponible ? "#000" : '#DD3434',
                    fontWeight: Number(value) < restProps.row.saldoDisponible ? "normal" : 'bold'
                }}>
                    {value}
                </span>
            </Table.Cell>
        )
    };

    const Cell = (props) => {
        const { column, row } = props;
        if (column.name === 'cantidadSolicitada') {
            return <HighlightedCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };


    const commitChanges = ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = data.length > 0 ? data[data.length - 1]._id + 1 : 0;
            changedRows = [
                ...data,
                ...added.map((row, index) => ({
                    _id: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            let isHigher = false;
            changedRows = data.map(row => (changed[row._id] ? { ...row, ...changed[row._id] } : row));
            data.map(row => (changed[row._id]
                ? changed[row._id].cantidadSolicitada > row.saldoDisponible ? isHigher = true : isHigher = false
                : row
            ));
            if (isHigher) {
                Swal.fire({ icon: 'warning', title: 'Información!', text: 'La cantidad solicitada no se encuentra disponible.' })
            }
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = data.filter(row => !deletedSet.has(row._id));
        }
        setData(changedRows);
        validateQty(changedRows)
    };



    const validateQty = (data) => {
        console.log(data)
        let stop = false
        let count = 0
        let dataMaterialRequest = []
        for (let i = 0; i < data.length; i++) {
            let element = data[i];
            if (element.cantidadSolicitada) {
                count = count + 1
                dataMaterialRequest.push(element)
            }
        }


        if (count == 10) {
            Swal.fire({ icon: 'warning', title: '¡Atención!', text: 'Cantidad de materiales solicitados (10). Solo puede agregar 5 productos más.' })
            stop = true;
        } else {
            Toast.fire({
                icon: 'success',
                title: `Se han agregado ${count} productos.`
            })
        }
        if (count == 15) {
            Swal.fire({ icon: 'warning', title: '¡Atención!', text: 'Cantidad de materiales solicitados (15). Has alcanzado el máximo de materiales.' })
            stop = true;
        }

    }

    // --------------------------------------------------------------------------
    const [startEditAction, setStartEditAction] = useState('click');
    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);

    const [statusToShowButton, setStatusToShowButton] = useState("")
    const [projectId, setProjectId] = useState("")

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                setProjectId(res.data.projectId)
                clienteAxios.get(`/api/project/${res.data.projectId}/showTable`, config)
                    .then((res) => {
                        let respon = res.data.products;
                        console.log(respon)
                        setData(respon)

                        var test = document.querySelectorAll('.dx-g-bs4-group-cell');
                        [].forEach.call(test, function (matche) {
                            matche.setAttribute("colspan", "11");
                        });
                    })
                    .catch(error => { })
            })
            .catch(error => { })

        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                setStatusToShowButton(res.data.status)
                setProjectId(res.data.projectId)
            })
            .catch(error => { })
    }, [])

    const recounGroup = () => {
        // console.log('test')
        setTimeout(() => {
            var test = document.querySelectorAll('.dx-g-bs4-group-cell');
            [].forEach.call(test, function (matche) {
                matche.setAttribute("colspan", "11");
            });
        }, 100);
    }
    const [scrollY, setScrollX] = useState(0);

    function logit() {
        let px = document.querySelector('.table-responsive').scrollLeft;
        setScrollX(px)
        var row = document.querySelectorAll('.dx-g-bs4-group-cell');
        [].forEach.call(row, function (matche) {
            matche.setAttribute("colspan", "11");
        });
    }

    useEffect(() => {
        return () => {
            document.querySelector('.table-responsive').addEventListener("scroll", logit);
        };
    });


    return (
        <Layout>
            <>
                <Topbar title={`Solicitud de material`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12} className="position-relative">
                        <div className="">
                            <Grid rows={data} columns={columns} getRowId={getRowId} >
                                <GroupingState onExpandedGroupsChange={() => recounGroup()} grouping={[{ columnName: 'category' }, { columnName: 'subcategory' }]} />
                                <IntegratedGrouping />
                                <EditingState onCommitChanges={commitChanges} columnExtensions={editingStateColumnExtensions} />
                                <SearchState />
                                <IntegratedFiltering />
                                <Table cellComponent={FocusableCell} />
                                <VirtualTable height={670} cellComponent={Cell} />
                                <TableColumnResizing defaultColumnWidths={tableColumnExtensions} />
                                <TableHeaderRow />
                                <Toolbar />
                                <TableGroupRow />
                                <EditPropsPanel
                                    defaultAction={startEditAction}
                                    changeAction={setStartEditAction}
                                    isSelectText={selectTextOnEditStart}
                                    changeSelectText={setSelectTextOnEditStart}
                                />
                                <SearchPanel />
                                <TableInlineCellEditing
                                    startEditAction={startEditAction}
                                    selectTextOnEditStart={selectTextOnEditStart}
                                />
                                <TableEditColumn
                                    showEditCommand
                                />
                                <PopupEditing popupComponent={Popup} />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar save send
                    dataToPostMaterialRequest={data}
                    paramsMaterialRequest={id}
                    statusToShowButton={statusToShowButton}
                    history={history}
                    projectId={projectId}
                />
            </>
        </Layout>
    );
}

export default MaterialRequest;