import { NEW_MATERIAL_REQUEST_START, NEW_MATERIAL_REQUEST_SUCCESS, NEW_MATERIAL_REQUEST_FAIL} from '../actions/ActionsTypes';

const initialState = {
    success:false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NEW_MATERIAL_REQUEST_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case NEW_MATERIAL_REQUEST_SUCCESS:
            return {
                ...state,
                success:true,
                error: null,
                loading: false
            }
        case NEW_MATERIAL_REQUEST_FAIL:
            return {
                ...state,
                success:false,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}