import styled from '@emotion/styled';
import { Form } from 'react-bootstrap';

export const Input = styled(Form.Control)`
height:5rem;
border-radius: 1rem;
font-size:1.8rem;
background: rgba(234, 234, 234, 0.4) !important;
border: none !important;
margin-bottom: 2rem !important;

&:focus {
  border: none !important;
    box-shadow: none !important;
}
&:focus-visible {
    text-shadow: none !important;
    border: none !important;
}
`;

export const Label = styled(Form.Label)`
  font-size:1.8rem !important;
`;

export const ContainerInputFile = styled.div`
	label{
		padding:4rem 10rem;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='black' stroke-width='4' stroke-dasharray='6%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		border-radius: 15px;
		cursor:pointer;
		background-color: rgba(234, 234, 234, 0.4);
		width:100%;
		text-align:center;
	}
	p{
		font-size: 1.4rem!important;
	}
`;