import { NEW_MATERIAL_REQUEST_START, NEW_MATERIAL_REQUEST_SUCCESS, NEW_MATERIAL_REQUEST_FAIL, SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function NewMaterialRequest(idProject, userId, history, token, projectName) {
    return (dispatch) => {
        let body = {
            userId: userId
        }

        dispatch(NewMaterialRequestStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post(`/api/project/${idProject}/materialRequest`, body, config)
            .then((res) => {
                // console.log(res.data)
                // Swal.fire({ icon: 'success', title: 'Exito!', text: 'Solicitud creada correctamente.' })
                dispatch(NewMaterialRequestSuccess())
                dispatch(SaveProjectId(res.data.projectId))

                history.push(`/proyectos/solicitud-de-material/crear/${res.data._id}`, { projectName })
            })
            .catch(error => {
                dispatch(NewMaterialRequestFail())
                // console.log(error)
            })
    }
}




export const NewMaterialRequestStart = () => {
    return {
        type: NEW_MATERIAL_REQUEST_START
    };
};

export const NewMaterialRequestSuccess = () => ({
    type: NEW_MATERIAL_REQUEST_SUCCESS
})

export const NewMaterialRequestFail = () => ({
    type: NEW_MATERIAL_REQUEST_FAIL
})




export const SaveProjectId = (id) => ({
    type: SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS,
    payload: id
})