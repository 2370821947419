import { CREATE_PROJECT_START, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAIL} from '../actions/ActionsTypes';

const initialState = {
    success:false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_PROJECT_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                success:true,
                error: null,
                loading: false
            }
        case CREATE_PROJECT_FAIL:
            return {
                ...state,
                success:false,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}