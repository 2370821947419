import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { NavLink } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import InputRange from "../extras/InputRange";
import InputRangeDays from "../extras/InputRangeDays";

//Bootstrap
import { Col, Form, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux"
import clienteAxios from "../../config/axios"
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
const Sidebar = ({ setBody, body }) => {

    const [users, setUsers] = useState([])
    const [projectName, setProjectName] = useState([])
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);

    const token = useSelector(state => state.auth.token)


    useEffect(() => {
        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };
        clienteAxios.get(`/api/users`, config)
            .then((res) => {
                // console.log(res.data)
                let resultUsers = []
                for (let i = 0; i < res.data.length; i++) {
                    let element = res.data[i];
                    resultUsers.push({
                        value: element._id,
                        label: element.name + " " + element.lastname,
                    })
                }
                // console.log(resultUsers)
                setUsers(resultUsers)
            })
            .catch(error => {
                // console.log(error)
            })
        clienteAxios.get(`/api/log/projectsName`, config)
            .then((res) => {
                // console.log(res.data)
                let resultProject = []
                for (let i = 0; i < res.data.length; i++) {
                    let element = res.data[i];
                    resultProject.push({
                        value: element._id,
                        label: element.projectName,
                    })
                }
                // console.log(resultProject)
                setProjectName(resultProject)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const GetValueDate = (value) => {
        // console.log(value)
        setBody({
            startOfDay: value[0].startDate,
            endOfDay: value[0].endDate,
            userId: body.userId,
            projectName: body.projectName
        })
        // (formdata)
    }

    const GetUser = (value) => {
        // console.log(value)
        setBody({
            startOfDay: body.startOfDay,
            endOfDay: body.endOfDay,
            userId: value,
            projectName: body.projectName
        })
    }
    const GetProject = (value) => {
        // console.log(value)
        setBody({
            startOfDay: body.startOfDay,
            endOfDay: body.endOfDay,
            userId: body.userId,
            projectName: value
        })
    }


    return (
        <Col xs={12} className="justify-content-center options">
            <Col sm={8} md={12} className="pb-3 mb-3 pt-lg-3 pb-lg-4">
                <p className="text_big font-weight-bold">Filtros</p>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Control as="select" className="side" onChange={(e) => GetUser(e.target.value)}>
                    <option value="">Usuario</option>
                    {users.map((item, i) => (
                        <option value={item.value}>{item.label}</option>
                    ))}
                </Form.Control>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto">
                <Form.Control as="select" className="side" onChange={(e) => GetProject(e.target.value)}>
                    <option value="">Proyecto</option>
                    {projectName.map((item, i) => (
                        <option value={item.label}>{item.label}</option>
                    ))}
                </Form.Control>
            </Col>
            <Col sm={8} md={12} className="pb-3 mx-auto" css={css`justify-content: center;display: flex;`}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                        setDate([item.selection])
                        GetValueDate([item.selection])
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                />
            </Col>

        </Col >
    );
}


export default Sidebar;