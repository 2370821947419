import React, { useState, useEffect } from "react"

//Navbar
import Navbar from '../navigation/Navbar'
import Topbar from '../navigation/Topbar'
import Sidebar from '../navigation/SidebarOptions'
//Bootstrap
import { Button, Container, Row, Col, Form } from "react-bootstrap";
// alert
import Swal from 'sweetalert2';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { UpdateProjectAction } from '../../redux/actions/UpdateProjectAction';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
import Select from 'react-select';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
import Layout from "../structures/Layout";

import ModalCollaborator from '../extras/ModalAddCollaborators';

const AdminCreateProject = ({ history }) => {
    const token = useSelector(state => state.auth.token)
    const companyId = useSelector(state => state.auth.company)
    // ID del parametro enviado por url, pertenece al id del proyecto que se esta viendo.
    const { id } = useParams();

    const [showSelectToAddTags, setShowSelectToAddTags] = useState(false)
    const [tagsOption, setTagsOptions] = useState([])
    const [tagsSelected, setTagsSelected] = useState([])
    const [options, setOptions] = useState([])
    const [collaborators, setCollaborator] = useState([])
    const [buyer, setBuyer] = useState("")
    const [buyer_Id, setBuyer_Id] = useState("")
    const [areas, setAreas] = useState([])
    const [cliente, setCliente] = useState([])
    const [clientes, setClientes] = useState([])
    const [supervisorsOptions, setSupervisorsOptions] = useState([])
    const [updateDataTags, setUpdateDataTags] = useState(1)
    const [updateCollaborators, setUpdateCollaborators] = useState(1)

    const [projectNumber, setProjectNumber] = useState("")
    const [projectName, setProjectName] = useState("")
    const [projectComment, setProjectComment] = useState("")
    const [supervisedById, setSupervisedById] = useState("")
    const [defaultPositionSelect, setDefaultPositionSelect] = useState(0)
    const [supervisedName, setSupervisedName] = useState("")
    const [buyersOptions, setBuyersOptions] = useState([])

    const [proyectoPadre, setProyectoPadre] = useState("")
    const [ventaPresupuestada, setVentaPresupuestada] = useState("")
    const [manoObraPresupuestada, setManoObraPresupuestada] = useState("")
    const [vigencia, setVigencia] = useState("")
    const [plazoEjecucion, setPlazoEjecucion] = useState("")

    const [modalShow, setModalShow] = React.useState(false);


    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateProjectFn = (formdata, id, token) => dispatch(UpdateProjectAction(formdata, id, token));


    const handleSubmit = () => {
        if (projectNumber === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Numero del proyecto requerido' })
        } else if (projectName === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del proyecto requerido' })
        } else if (projectComment === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Comentario del proyecto requerido' })
        } else if (supervisedById === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Supervisor del proyecto requerido' })
        } else if (buyer === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Comprador del proyecto requerido' })
        }
        // else if (cliente.value === "") {
        //     Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Cliente requerido' })
        // } 
        else if (proyectoPadre === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Proyecto padre requerido' })
        } else if (ventaPresupuestada === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Venta presupuestada requerido' })
        } else if (manoObraPresupuestada === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Mano de obra presupuestada requerido' })
        } else if (vigencia === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Vigencia requerido' })
        } else if (plazoEjecucion === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Plazo de ejecución requerido' })
        }
        else {
            let formdata = {
                projectNumber,
                projectName,
                projectComment,
                supervisedById,
                buyerId: buyer_Id ? buyer_Id : buyer,
                clientId: cliente.value === "" ? undefined : cliente.value,
                proyectoPadre,
                ventaPresupuestada: Number(ventaPresupuestada),
                manoObraPresupuestada: Number(manoObraPresupuestada),
                vigencia,
                plazoEjecucion
            }
            console.log(formdata)
            UpdateProjectFn(formdata, id, token)
        }
    }


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/company/${companyId}/supervisors`, config)
            .then((res) => {
                // console.log(res.data)
                let respon = res.data;
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        { "value": element._id, "label": element.name + " " + element.lastname },
                    )
                }
                setOptions(finalData)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/company/${companyId}/buyers`, config)
            .then((res) => {
                // console.log(res.data)
                let respon = res.data;
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        { "value": element._id, "label": element.name + " " + element.lastname },
                    )
                }
                setBuyersOptions(finalData)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])
    // -----------------------------------
    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/client`, config)
            .then((res) => {
                console.log(res.data)
                setClientes(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/${id}`, config)
            .then((res) => {
                console.log(res.data)
                setProjectNumber(res.data.projectNumber)
                setProjectName(res.data.projectName)
                setProjectComment(res.data.projectComment)
                setSupervisedById(res.data.supervisedById._id)
                setSupervisedName(res.data.supervisedById)
                setCliente({
                    value: res.data.clientId ? res.data.clientId?._id : "",
                    name: res.data.clientId ? res.data.clientId?.contactName + " " + res.data.clientId?.contactLastname : "",
                })
                setBuyer(res.data.buyerId.name + " " + res.data.buyerId.lastname)
                setBuyer_Id(res.data.buyerId._id)
                setProyectoPadre(res.data.proyectoPadre)
                setVentaPresupuestada(res.data.ventaPresupuestada)
                setManoObraPresupuestada(res.data.manoObraPresupuestada)
                setVigencia(res.data.vigencia)
                setPlazoEjecucion(res.data.plazoEjecucion)
            })
            .catch(error => {
                // console.log(error)
            })

        // clienteAxios.get(`/api/buyers/project/${id}`, config)
        //     .then((res) => {
        //         console.log(res.data)
        //         setBuyer(res.data)
        //     })
        //     .catch(error => {
        //         // console.log(error)
        //     })


        clienteAxios.get(`/api/project/${id}/projectsArea`, config)
            .then((res) => {
                // console.log(res.data)
                setAreas(res.data)
            })
            .catch(error => {
                // console.log(error)
            })


        clienteAxios.get(`/api/area`, config)
            .then((res) => {
                // console.log(res.data)
                let respon = res.data;
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        { "value": element._id, "label": element.area },
                    )
                }
                setTagsOptions(finalData)
            })
            .catch(error => {
                // console.log(error)
            })

    }, [updateDataTags])



    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/collaborators/project/${id}`, config)
            .then((res) => {
                console.log(res.data)
                setCollaborator(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [updateCollaborators])


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/company/${companyId}/supervisors`, config)
            .then((res) => {
                console.log(res.data)
                let respon = res.data;
                let finalData = [];
                for (let i = 0; i < respon.length; i++) {
                    const element = respon[i];
                    finalData.push(
                        { "value": element._id, "label": element.name + " " + element.lastname },
                    )
                }
                // promiseOptions(finalData)
                setSupervisorsOptions(finalData)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])



    const deleteTagas = (projectId, projectAreaId) => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.delete(`/api/project/${projectId}/projectsArea/${projectAreaId}`, config)
            .then((res) => {
                // console.log(res.data)
                setUpdateDataTags(updateDataTags + 1)
            })
            .catch(error => {
                // console.log(error)
            })
    }

    const addTags = (currentId, areas) => {
        // console.log(currentId, areas)
        for (let i = 0; i < areas.length; i++) {
            const item = areas[i];
            if (item.areaId._id === currentId) {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'El area ya se encuentra agregada' })
                return;
            }
        }

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        let body = {
            areaId: currentId
        }
        // console.log(body)
        clienteAxios.post(`/api/project/${id}/projectsArea`, body, config)
            .then((res) => {
                // console.log(res.data)
                setUpdateDataTags(updateDataTags + 1)
            })
            .catch(error => {
                // console.log(error)
            })
    }

    const DeleteCollaborator = (item) => {
        console.log(item)

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        // console.log(body)
        clienteAxios.delete(`/api/collaborator/${item.collaboratorId._id}/project/${id}`, config)
            .then((res) => {
                console.log(res.data)
                setUpdateCollaborators(updateCollaborators + 1)
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <Layout>
            {/* main */}
            <>
                <Topbar title={`Ver/Editar Proyecto ${projectName}`} />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start">
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group controlId="projectNumber">
                                        <Form.Label>Número del proyecto</Form.Label>
                                        <Form.Control type="text" placeholder="8090" value={projectNumber} onChange={(e) => setProjectNumber(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group controlId="projectName">
                                        <Form.Label>Proyecto</Form.Label>
                                        <Form.Control type="text" placeholder="8090 CEN" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    {/* <Form.Group controlId="supervisor">
                                        <Form.Label>Supervisor asignado</Form.Label>
                                        <Form.Control type="text" value={supervisedName.name + " " + supervisedName.lastname} disabled />
                                    </Form.Group> */}
                                    <Form.Label>Supervisor asignado</Form.Label>
                                    <Form.Control as="select" onChange={(e) => {
                                        console.log(e.target.value)
                                        setSupervisedById(e.target.value)
                                    }}>
                                        <option css={css`visibility:hidden;`} value={supervisedName.value}>{supervisedName ? supervisedName.name + " " + supervisedName.lastname : ""}</option>
                                        {supervisorsOptions.map((item) => (
                                            <option value={item.value}>{item.label}</option>
                                        ))}
                                    </Form.Control>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group controlId="supervisor">
                                        <Form.Label>Comprador</Form.Label>
                                        <Form.Control as="select" onChange={(e) => {
                                            setBuyer(e.target.value)
                                            setBuyer_Id(null)
                                        }}>
                                            <option css={css`visibility:hidden;`} value={buyer_Id}>{buyer}</option>
                                            {buyersOptions.map((item) => (
                                                <option value={item.value} name={item.label}>{item.label}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="d-flex flex-column">
                                <Form.Label>Colaboradores</Form.Label>
                                <Button css={css`
                                    margin-left: 5px;
                                    margin-bottom: 5px;
                                `} size="sm" variant="secondary" onClick={() => setModalShow(true)} className="text-light w-50">Agregar colaboradores</Button>
                                <Container>
                                    <Row>
                                        {collaborators.map((item, i) => (
                                            <Col xs={4} className="px-0 position-relative">
                                                <Form.Control className="mt-2" key={i} type="text" value={item.collaboratorId.name + " " + item.collaboratorId.lastname} disabled />
                                                <span className="position-absolute" onClick={() => DeleteCollaborator(item)}
                                                    css={css`
                                                        z-index:10;
                                                        top:5px;
                                                        right:0px;
                                                        background-color: rgb(105, 109, 111);
                                                        padding:0px 5px;
                                                        border-radius:50%;
                                                        color:white;
                                                        cursor: pointer;
                                                        font-size: 12px;
                                                    `}>x</span>
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </Form.Group>


                            <Form.Group className="d-flex flex-column">
                                <Form.Label>Tags</Form.Label>
                                {!showSelectToAddTags && (
                                    <Button size="sm" onClick={() => setShowSelectToAddTags(showSelectToAddTags ? false : true)} variant="secondary" className="text-light w-50">Agregar</Button>
                                )}
                                <Container>
                                    <Row>
                                        {areas.map((item, i) => (
                                            <Col xs={4} className="px-0 position-relative">
                                                <Form.Control className="mt-2" key={i} type="text" value={item.areaId.area} disabled />
                                                <span className="position-absolute" onClick={() => deleteTagas(item.projectId, item._id)}
                                                    css={css`
                                                    z-index:10;
                                                    top:5px;
                                                    right:0px;
                                                    background-color: rgb(105, 109, 111);
                                                    padding:0px 5px;
                                                    border-radius:50%;
                                                    color:white;
                                                    cursor: pointer;
                                                    font-size: 12px;
                                                `}>x</span>
                                            </Col>
                                        ))}

                                        {showSelectToAddTags && (
                                            <Col xs={12} className="px-0 mt-4">
                                                {tagsOption.map((tag, i) => (
                                                    <Button onClick={() => addTags(tag.value, areas)} size="sm" className="m-1 text-light" variant="secondary" >{tag.label}</Button>
                                                ))}
                                            </Col>
                                        )}
                                    </Row>
                                </Container>
                            </Form.Group>

                            <Form.Group controlId="additionalRequirements">
                                <Form.Label>Comentarios adicionales</Form.Label>
                                <Form.Control as="textarea" rows={10} value={projectComment} onChange={(e) => setProjectComment(e.target.value)} />
                            </Form.Group>

                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group controlId="supervisor">
                                        <Form.Label>Cliente</Form.Label>
                                        <Form.Control as="select" onChange={(e) => {
                                            setCliente({
                                                name: e.target.name,
                                                value: e.target.value
                                            })
                                        }}>
                                            <option css={css`visibility:hidden;`} value={cliente.value}>{cliente.name}</option>
                                            {clientes.map((item) => (
                                                // <option value={item._id} name={item.contactName + " " + item.contactLastname}>{item.contactName + " " + item.contactLastname}</option>
                                                <option value={item._id} name={item.clientRazonSocial}>{item.clientRazonSocial}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group controlId="supervisor">
                                        <Form.Label>Proyecto padre</Form.Label>
                                        <Form.Control type="text" value={proyectoPadre} onChange={(e) => setProyectoPadre(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="plazoEjecucion">
                                <Form.Label>Plazo de ejecución</Form.Label>
                                <Form.Control type="number" value={plazoEjecucion} onChange={(e) => setPlazoEjecucion(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="supervisor">
                                <Form.Label>Venta Presupuestada</Form.Label>
                                <Form.Control type="number" value={ventaPresupuestada} onChange={(e) => setVentaPresupuestada(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="supervisor">
                                <Form.Label>Mano de obra presupuestada</Form.Label>
                                <Form.Control type="number" value={manoObraPresupuestada} onChange={(e) => setManoObraPresupuestada(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="supervisor">
                                <Form.Label>Vigencia</Form.Label>
                                <Form.Control as="select" onChange={(e) => setVigencia(e.target.value)}>
                                    <option css={css`visibility:hidden;`} value={vigencia}>{vigencia}</option>
                                    <option value="abierto">Abierto</option>
                                    <option value="cerrado">Cerrado</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>

                    </Form>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button variant="warning" className="mx-3" onClick={() => handleSubmit()}>Actualizar</Button>
                    </Col>
                </Row>

                <ModalCollaborator
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    projectId={id}
                    updateCollaborators={updateCollaborators}
                    setUpdateCollaborators={setUpdateCollaborators}
                    collaborators={collaborators}
                />
            </>
            {/* sidebar right */}
            <>
                <Sidebar dateProject products categoruesSubcategories deleteProject history={history} />
            </>
        </Layout >
    );
}

export default AdminCreateProject;