import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, LOGOUT_START, LOGOUT_SUCCESS, LOGOUT_FAIL } from '../actions/ActionsTypes';

const initialState = {
    success: false,
    token: null,
    avatar: null,
    company: null,
    user_name: null,
    user_lastname: null,
    user_role: null,
    user_id: null,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                success: true,
                token: action.payload.token,
                avatar: action.payload.user.avatar,
                company: action.payload.user.company,
                user_name: action.payload.user.name,
                user_lastname: action.payload.user.lastname,
                user_role: action.payload.user.role,
                user_id: action.payload.user._id,
                error: null,
                loading: false
            }
        case AUTH_FAIL:
            return {
                ...state,
                success: false,
                token: null,
                avatar: null,
                company: null,
                user_name: null,
                user_lastname: null,
                user_role: null,
                user_id: null,
                error: true,
                loading: false,
            }
        case LOGOUT_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                success: false,
                token: null,
                company: null,
                avatar:null,
                user_name: null,
                user_lastname: null,
                user_role: null,
                user_id: null,
                error: null,
                loading: false,
            }
        case LOGOUT_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
            }
        default:
            return state;
    }
}