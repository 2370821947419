import React, { useEffect, useState } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarNotifications'
//Structures
import CarouselProjectStatus from './../structures/CarouselProjectStatus'
import Layout from './../structures/Layout'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// redux
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from "../../config/axios";
import ChartsMonthlyProjects from "../extras/chartsMonthlyProjects"
import ChartProjectsGeneralData from '../extras/chartProjectsGeneralData'
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
import formatNumber from '../extras/numberFormater'

const AdminDashboard = ({ history }) => {

    const [supervisorWithMoreProject, setSupervisorWithMoreProject] = useState([])
    const [higherPurchasePercentage, setHigherPurchasePercentage] = useState([])
    const [biggestBudget, setBiggestBudget] = useState([])
    const [usersQuantity, setUsersQuantity] = useState([])
    const [quotes, setQuotes] = useState("")
    const [graphProjectsGeneralData, setGraphProjectsGeneralData] = useState("")
    const [totalRequest, setTotalRequest] = useState("")
    const [currentProject, setCurrentProject] = useState("")
    const [companyName, setCompanyName] = useState("")


    const token = useSelector(state => state.auth.token)

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/supervisorWithMoreProjects`, config)
            .then((res) => {
                // console.log(res.data)
                setSupervisorWithMoreProject(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/higherPurchasePercentage`, config)
            .then((res) => {
                // console.log(res.data)
                setHigherPurchasePercentage(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/biggestBudget`, config)
            .then((res) => {
                // console.log(res.data)
                setBiggestBudget(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/usersQuantity`, config)
            .then((res) => {
                // console.log(res.data)
                setUsersQuantity(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/quotes`, config)
            .then((res) => {
                // console.log(res.data)
                setQuotes(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/companyName`, config)
            .then((res) => {
                // console.log(res.data)
                setCompanyName(res.data.companyName)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/statistics/graphProjectsGeneralData`, config)
            .then((res) => {
                // console.log(res.data)
                let result = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    result.push({
                        projectName: element.projectName,
                        aprobada: element.aprobada,
                        cerradas: element.cerradas,
                        enviada: element.enviada,
                        pendiente: element.pendiente,
                        solicitudes: element.solicitudes,
                        diasPromedio: element.diasPromedio,
                    })
                }
                let totalRequest = 0
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // console.log(element.solicitudes)
                    totalRequest = totalRequest + element.solicitudes
                }
                setTotalRequest(totalRequest)
                setCurrentProject(res.data.length)
                setGraphProjectsGeneralData(result)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const llamar = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/updateSaldoDisponibleV2`, config)
        // clienteAxios.get(`/api/updateSaldoDisponible`, config)
            .then((res) => {
                console.log(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }
    const llamar2 = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/updateNcNames`, config)
            .then((res) => {
                console.log(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }

    return (
        <Layout>
            <>
                <Topbar title="Dashboard" WelcomeName />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={6} lg={4}>
                        <p className="text_orange">Cantidad de usuarios</p>
                        <p className="pb-3 text_big"><strong>{usersQuantity.users}</strong></p>
                        <p className="text_orange">Empresa</p>
                        <p className="text_big"><strong>{companyName}</strong></p>
                    </Col>
                    <Col xs={6} lg={4}>
                        <p className="text_orange">Proyectos en curso</p>
                        <p className="pb-3 text_big"><strong>{currentProject}</strong></p>
                        <p className="text_orange">Total de solicitudes</p>
                        <p className="text_big"><strong>{totalRequest}</strong></p>
                    </Col>
                    <Col lg={4} className="pt-3 pt-lg-0">
                        <p className="text_orange">Mayor presupuesto</p>
                        <p className="text_big"><strong>{biggestBudget.length > 0 ? biggestBudget[0].data[0].projectName : ""} | {biggestBudget.length > 0 ? formatNumber.new(biggestBudget[0].totalBudgetAmount, "$") : "0"}</strong></p>
                    </Col>
                </Row>
                <Row className="d-flex align-items-start">
                    <Col xs={12} xl={8} className="pr-xl-0">
                        <Row>
                            <Col md={6} className="pr-lg-1 pb-3">
                                <Card className="border-0 py-3 cardDash">
                                    <div className="px-2"><p className="m-0"><strong>Proyectos mensuales</strong></p></div>
                                    <hr />
                                    <ChartsMonthlyProjects />
                                </Card>
                            </Col>
                            <Col md={6} className="pl-lg-1 pb-3">
                                <Card className="border-0 py-3 cardDash">
                                    <div className="px-2"><p className="m-0"><strong>Información por proyecto</strong></p></div>
                                    <hr />
                                    <ChartProjectsGeneralData graphProjectsGeneralData={graphProjectsGeneralData} />
                                </Card>
                            </Col>
                            <Col md={6} className="pr-lg-1">
                                <Card className="border-0 py-3 bg_image_quote cardDash d-flex align-items-center h-100">
                                    <div className="p-3 h-100" css={css`
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                        `}>
                                        <p className="text-center px-4 text_big pb-2">{quotes.quote}</p>
                                        <p className="text-right"><strong>{quotes.author}</strong></p>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={6} className="pl-lg-1 pt-3 pt-md-0">
                                <Card className="border-0 py-3 cardDash">
                                    <div className="px-2"><p className="m-0"><strong>Status de proyectos</strong></p></div>
                                    <hr />
                                    <div className="px-2">
                                        <CarouselProjectStatus />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} xl={4} className="pt-3 pt-xl-0">
                        <Row>
                            <Col md={6} xl={12} className="pr-3 pr-md-1 pr-xl-3">
                                <Card className="border-0 py-3 cardDash">
                                    <div className="px-2"><p className="m-0"><strong>Supervisor con más proyectos</strong></p></div>
                                    <hr />
                                    <Row className="cards-padding">
                                        {supervisorWithMoreProject.map((supervisor, i) => (
                                            <Col key={i} xs={12} className="d-flex align-items-center">
                                                <Col xs={2}>
                                                    <p className="bg_orange border-radius text-center">{i + 1}</p>
                                                </Col>
                                                <Col xs={7} className="px-0">
                                                    <p><strong><span className="text-uppercase">{supervisor._id.name.charAt(0)}</span>. {supervisor._id.lastname}</strong></p>
                                                </Col>
                                                <Col xs={3}>
                                                    <p className="bg_gray border-radius text-center">{supervisor.total}</p>
                                                </Col>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                            </Col>
                            <Col md={6} xl={12} className="pt-3 pt-md-0 pt-xl-3 pl-3 pl-md-1 pl-xl-3">
                                <Card className="border-0 py-3 cardDash">
                                    <div className="px-2"><p className="m-0"><strong>Mayor porcentaje de compras</strong></p></div>
                                    <hr />
                                    <Row className="cards-padding">
                                        {higherPurchasePercentage.map((purchase, i) => (
                                            <Col key={i} xs={12} className="d-flex align-items-center">
                                                <Col xs={2}>
                                                    <p className="bg_orange border-radius text-center">{i + 1}</p>
                                                </Col>
                                                <Col xs={6} className="px-0">
                                                    <p><strong>{purchase.data[0].projectName}</strong></p>
                                                </Col>
                                                <Col xs={4}>
                                                    <p className="bg_gray border-radius text-center">{purchase.purchasePercentage ? purchase.purchasePercentage : "0"}%</p>
                                                </Col>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* <p onClick={() => llamar()}>llamada</p> */}
                    <br/>
                    {/* <p onClick={() => llamar2()}>llamada2</p> */}
                </Row>
            </>
        </Layout>
    );
}

export default AdminDashboard;