import * as React from "react"

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

//Images
import LogoDefaena from './../../resources/images/page/logo_defaena.svg'
import SignInImage from './../../resources/images/login/login.jpg'

const RecoverPassword = () => {

    return (
        <Container fluid className="bg_white w-100">
            <Row className="d-lg-flex align-items-center justify-content-center h_100 login">
                <Col xs={12} md={7} lg={5} xl={6} className="text-center justify-content-center pt-5 pt-lg-0">
                    <img src={LogoDefaena} className="pb-5" alt="Logo Company" />
                    <h1 className="text-center pb-5">Recuperar Contraseña</h1>
                    <Form className="col-lg-11 col-xl-8 mx-auto text-left">
                        <Form.Group controlId="email" className="mb-4">
                            <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                        <Button variant="warning" type="submit" className="col-12 py-3">
                            recuperar
                        </Button>
                    </Form>
                    <p className="text_orange text-center pt-5">Todos los Derechos Reservados defaena.</p>
                </Col>
                <Col xs={12} md={10} lg={7} xl={6} className="px-0 py-4 py-lg-0 bg_login text-center">
                    <img src={SignInImage} className="img-fluid col-12 px-0 cover_image d-lg-none" alt="Cover" />
                </Col>
            </Row>
        </Container>
    );
}

export default RecoverPassword;