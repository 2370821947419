import React, { useEffect, useState } from "react"

//Navbar
import Navbar from '../navigation/Navbar'
import Topbar from '../navigation/Topbar'
import Sidebar from '../navigation/SidebarFilters'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';

// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { SaveProjectIdToMaterialRequestAction } from '../../redux/actions/SaveProyectIDToMaterialRequest';
import {
    PagingState,
    IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    VirtualTable,
    PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import Layout from "../structures/Layout";


const MaterialRequest = ({ history, location }) => {

    const { id } = useParams();
    //DISPATCH
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token)

    const [data, setData] = useState([])
    const [projectName, setProjectName] = useState("")

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/${id}/materialRequest`, config)
            .then((res) => {
                console.log(res.data)
                let dataToShow = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    dataToShow.push({
                        advancePercentage: element.advancePercentage.toFixed(0) + "%",
                        companyId: element.companyId,
                        days: element.days.toFixed(0),
                        number: element.number,
                        projectId: element.projectId,
                        requestedBy: element.requestedBy,
                        startingDate: element.startingDate,
                        status: element.status,
                        _id: element._id,
                    })
                }
                // console.log(dataToShow)
                setData(dataToShow)
                // // console.log(res.data)
                // setData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])
    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                setProjectName(res.data.projectName)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])
    // useEffect(() => {
    //     const config = {
    //         headers: { 'Authorization': "Bearer " + token }
    //     };
    //     clienteAxios.get(`/api/project/materialRequest/${id}/ProjectDataAndNumber`, config)
    //         .then((res) => {
    //             console.log(res.data)
    //             // setMaterialRequestNumber(res.data.materialRequestNumber)
    //             // setProjectName(res.data.project.projectName)
    //         })
    //         .catch(error => {
    //             // console.log(error)
    //         })
    // }, [])
    // actions
    const saveAction = (projectId, materialRequestId, history, id) => dispatch(SaveProjectIdToMaterialRequestAction(projectId, materialRequestId, history, id));


    //     // qui la ides es guardar en la url el id del materialrequest y el del proyecto (que se utiliza en la tabla para agregar los productos autocompletados), almacenarlos en el redux,
    //     // de esta forma no dara probelmas al recargar la pagina y se pierda la info necesarias para los request
    const saveFn = (projectId, materialRequestId, history, id) => {
        // console.log(projectId, materialRequestId)
        saveAction(projectId, materialRequestId, history, id)
    }

    const [columns] = useState([
        { name: 'number', title: 'Número Proyecto' },
        { name: 'status', title: 'Estado del proyecto' },
        { name: 'advancePercentage', title: 'Avance' },
        { name: 'days', title: 'Días' },
    ]);

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => saveFn(row.projectId, row._id, history, id)}
            className="hoverTable"
            style={{
                cursor: 'pointer',
            }}
        />
    );

    return (
        <Layout>
            <>
                <Topbar title={`Proyecto:  ${projectName}| Solicitud de material`} materialRequest idMaterialRequest={id} history={history} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card" >
                            <Grid
                                rows={data}
                                columns={columns}
                            >
                                {/* <PagingState
                                        defaultCurrentPage={0}
                                        pageSize={12}
                                    /> */}
                                {/* <IntegratedPaging /> */}
                                <Table />
                                <VirtualTable height={670} rowComponent={TableRow} />
                                <TableHeaderRow />
                                {/* <PagingPanel /> */}
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
        </Layout >
    );
}

export default MaterialRequest;