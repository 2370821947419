import { QUOTE_SAVE_START, QUOTE_SAVE_SUCCESS, QUOTE_SAVE_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function SaveQuotePDFAction(body, token, projectId, history, gestionar) {
    return (dispatch) => {
        dispatch(SaveQuoteStart())

        // console.log(body)
        let stop = false
        for (let i = 0; i < body.products.length; i++) {
            const element = body.products[i];
            if (
                // gestionar ?
                //     element.cantidadSolicitada === "" || element.cantidadSolicitada === "0" || element.cantidadSolicitada === 0 ||
                //     element.cantidadCompra === "" || element.cantidadCompra === "0" || element.cantidadCompra === 0 ||
                //     element.montoCompra === "" || element.montoCompra === "0" || element.montoCompra === 0
                //     :
                //     element.cantidadSolicitada === "" || element.cantidadSolicitada === "0" || element.cantidadSolicitada === 0
                gestionar ?
                element.cantidadSolicitada === "" ||
                element.cantidadCompra === "" ||
                element.montoCompra === "" 
                :
                element.cantidadSolicitada === "" || element.cantidadSolicitada === "0" || element.cantidadSolicitada === 0
            ) {
                gestionar ?
                    // Swal.fire({ icon: 'error', title: 'Error!', text: 'Cantidad solicitada, Cantidad compra y Monto compra son inputs requerdos y deben ser mayor a 0' })
                    Swal.fire({ icon: 'error', title: 'Error!', text: 'Cantidad solicitada, Cantidad compra y Monto compra son inputs requerdos' })
                    :
                    Swal.fire({ icon: 'error', title: 'Error!', text: 'Cantidad solicitada es requerdo y debe ser mayor a 0' })
                stop = true;
                break;
            }
        }

        if (stop) {
            return;
        } else {
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };
            clienteAxios.put(`/api/project/${projectId}/materialRequest/updateTable`, body, config)
                .then((res) => {
                    dispatch(SaveQuoteSuccess())
                    // console.log(res)
                    Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha guardado correctamente.' })
                    // history.push('/gerente-solicitud-de-material')
                })
                .catch(error => {
                    dispatch(SaveQuoteFail())
                    // console.log(error)
                })
        }

    }
}




export const SaveQuoteStart = () => {
    return {
        type: QUOTE_SAVE_START
    };
};

export const SaveQuoteSuccess = () => ({
    type: QUOTE_SAVE_SUCCESS,
})

export const SaveQuoteFail = () => ({
    type: QUOTE_SAVE_FAIL,
})
