import { SEND_NEW_MATERIAL_REQUEST_TABLE_START, SEND_NEW_MATERIAL_REQUEST_TABLE_SUCCESS, SEND_NEW_MATERIAL_REQUEST_TABLE_FAIL} from '../actions/ActionsTypes';

const initialState = {
    success:false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEND_NEW_MATERIAL_REQUEST_TABLE_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case SEND_NEW_MATERIAL_REQUEST_TABLE_SUCCESS:
            return {
                ...state,
                success:true,
                error: null,
                loading: false
            }
        case SEND_NEW_MATERIAL_REQUEST_TABLE_FAIL:
            return {
                ...state,
                success:false,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}