import { UPDATE_TABLE_PRODUCT_START, UPDATE_TABLE_PRODUCT_SUCCESS, UPDATE_TABLE_PRODUCT_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UpdateTableProductAction(ProductsData, id, token) {
    return (dispatch) => {
        dispatch(UpdateTableProductStart())
        console.log(ProductsData)

        // let dataClear = []
        // for (let i = 0; i < ProductsData.length; i++) {
        //     const element = ProductsData[i];
        //     dataClear.push({
        //         sku: element.sku,
        //         productName: element.productName,
        //         productUnits: element.productUnits,
        //         productBrand: element.productBrand,
        //         producType: element.producType,
        //         productModel: element.productModel,
        //         cantidadPresupuestada: element.cantidadPresupuestada,
        //         cantidadCompra: element.cantidadCompra,
        //         saldoDisponible: element.saldoDisponible,
        //         montoPresupuestado: element.montoPresupuestado,
        //         montoCompra: element.montoCompra,
        //         totalPresupuestado: element.totalPresupuestado,
        //         totalCompra: element.totalCompra,
        //         porcentajeCompra: element.porcentajeCompra,
        //     })
        // }
        // console.log(dataClear)
        console.log(`/api/project/${id}/updateTable`)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.put(`/api/project/${id}/updateTable`, ProductsData, config)
            .then((res) => {
                // console.log(res)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha actualizado la tabla correctamente.' })
                dispatch(UpdateTableProductSuccess())
            })
            .catch(error => {
                dispatch(UpdateTableProductFail())
                // console.log(error)
            })
    }
}




export const UpdateTableProductStart = () => {
    return {
        type: UPDATE_TABLE_PRODUCT_START
    };
};

export const UpdateTableProductSuccess = () => ({
    type: UPDATE_TABLE_PRODUCT_SUCCESS,
})

export const UpdateTableProductFail = () => ({
    type: UPDATE_TABLE_PRODUCT_FAIL,
})
