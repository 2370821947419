import { CREATE_USER_MANAGER_START, CREATE_USER_MANAGER_SUCCESS, CREATE_USER_MANAGER_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function CreateUserManagerAction(body, history, token) {
    return (dispatch) => {
        dispatch(CreateUserManagerStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.post('/api/users/signup', body, config)
            .then((res) => {
                // console.log(res)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha creado el usuario correctamente.' })
                dispatch(CreateUserManagerSuccess())
                history.push(`/gestion-de-usuarios`)
            })
            .catch(error => {
                dispatch(CreateUserManagerFail())
                // console.log(error)
            })
    }
}




export const CreateUserManagerStart = () => {
    return {
        type: CREATE_USER_MANAGER_START
    };
};

export const CreateUserManagerSuccess = () => ({
    type: CREATE_USER_MANAGER_SUCCESS,
})

export const CreateUserManagerFail = () => ({
    type: CREATE_USER_MANAGER_FAIL,
})
