import React, { useState, useEffect } from "react"

//Navbar
import Navbar from '../navigation/Navbar'
import Topbar from '../navigation/Topbar'
import Sidebar from '../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import {
    Plugin, Template, TemplateConnector, TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import { EditingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, VirtualTable, TableInlineCellEditing, Toolbar, TableEditColumn, TableColumnResizing, TableEditRow } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AutoComplete from '../extras/autocompleteUpdate';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import Layout from "../structures/Layout";



const getRowId = row => row.itemId;

const Tags = ({ location, history }) => {

    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    const projectID = useSelector(state => state.saveProyectIDToMaterialRequest.id)
    const companyId = useSelector(state => state.auth.company)
    //DISPATCH
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const [afterDelete, serAfterDelete] = useState(1)

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    const [columns] = useState([
        { name: 'area', title: 'Area' },
    ]);

    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            let item = [{ area: added[0].area, companyId }]//body array
            const config = { headers: { 'Authorization': "Bearer " + token } };//confi axios
            let body = { tagsArray: item, companyIdReqBody: companyId }//body to post

            clienteAxios.post(`/api/area`, body, config)
                .then((res) => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Etiqueta agregada correctamente'
                    })
                })
                .catch(error => { console.log(error) })

            const startingAddedId = data.length > 0 ? data[data.length - 1].itemId + 1 : 0;
            changedRows = [
                ...data,
                ...added.map((row, index) => ({
                    itemId: startingAddedId + index,
                    ...row,
                })),
            ];
        }
        if (changed) {
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };

            let idArea;
            let curretArea;
            for (var key1 in changed) {
                // console.log(key1);
                idArea = key1
                for (var key2 in changed[key1]) {
                    curretArea = changed[key1][key2]
                }
            }
            let body = {
                area: curretArea
            }

            clienteAxios.put(`/api/area/${idArea}`, body, config)
                .then((res) => {
                    // console.log(res.data)
                    Toast.fire({
                        icon: 'success',
                        title: 'Area actualizada'
                    })
                })
                .catch(error => {
                    // console.log(error)
                })

            changedRows = data.map(row => (changed[row.itemId] ? { ...row, ...changed[row.itemId] } : row));
        }
        if (deleted) {
            // console.log(deleted)
            // const config = {
            //     headers: { 'Authorization': "Bearer " + token }
            // };
            // clienteAxios.delete(`/api/area/${deleted[0]}`, config)
            //     .then((res) => {
            //         // console.log(res.data)
            //         Toast.fire({
            //             icon: 'success',
            //             title: 'Area eliminada'
            //         })
            //     })
            //     .catch(error => {
            //         // console.log(error.response)
            //         Toast.fire({
            //             icon: 'error',
            //             title: 'Esta area esta en uso'
            //         })
            //     })

            // const deletedSet = new Set(deleted);
            // changedRows = data.filter(row => !deletedSet.has(row.itemId));

            let promise = new Promise((resolve, reject) => {
                Swal.fire({
                    title: 'Estas seguro?',
                    text: "Esta acción no se puede revertir!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, eliminar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const config = {
                            headers: { 'Authorization': "Bearer " + token }
                        };

                        clienteAxios.delete(`/api/area/${deleted[0]}`, config)
                            .then((res) => {
                                // console.log(res.data)

                                const deletedSet = new Set(deleted);
                                changedRows = data.filter(row => !deletedSet.has(row.itemId));

                                Toast.fire({ icon: 'success', title: 'Eliminado correctamente' })

                                resolve("done!")
                            })
                            .catch(error => {
                                Toast.fire({
                                    icon: 'error',
                                    title: 'Esta area esta en uso'
                                })
                                reject()
                            })
                    }
                })
            });

            let result = await promise; // wait until the promise resolves (*)
            // console.log(result); // "done!"

            serAfterDelete(afterDelete + 1)
        }
        // console.log(changedRows)
        setData(changedRows ? changedRows : data);
    };

    useEffect(() => {

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        clienteAxios.get(`/api/area`, config)
            .then((res) => {
                let response = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    response.push({
                        area: element.area,
                        companyId: element.companyId,
                        itemId: element._id,
                    })
                }
                setData(response)

                // var ver = document.querySelectorAll('button#edit.dx-g-bs4-table-edit-command-cell');
                // [].forEach.call(ver, function (matche) {
                //     matche.innerHTML = 'edit';
                // });

                // var borrar = document.querySelectorAll('button#delete.dx-g-bs4-table-edit-command-cell');
                // [].forEach.call(borrar, function (matche) {
                //     matche.innerHTML = 'eliminar';
                // });

                // var nuevo = document.querySelectorAll('button#add.dx-g-bs4-table-edit-command-cell');
                // [].forEach.call(nuevo, function (matche) {
                //     matche.innerHTML = 'nuevo';
                // });

            })
            .catch(error => {
                // console.log(error)
            })
    }, [afterDelete])


    return (
        <Layout>
            <>
                <Topbar title={`Areas`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12} className="position-relative">
                        <div className="card">
                            <Grid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                            >
                                <EditingState
                                    onCommitChanges={commitChanges}
                                />
                                <Table />
                                <VirtualTable height={670} />
                                <TableHeaderRow />
                                <TableEditRow />
                                <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Tags;