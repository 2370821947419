import { SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_START, SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS, SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_FAIL } from '../actions/ActionsTypes';

const initialState = {
    id: null,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS:
            return {
                ...state,
                id: action.payload,
                error: null,
                loading: false
            }
        case SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_FAIL:
            return {
                ...state,
                id: null,
                status:null,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}