import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Modal, Row, Col, Button } from "react-bootstrap"
import clienteAxios from './../../config/axios';
import { useSelector } from 'react-redux';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';
import {
    PagingState,
    IntegratedPaging,
    DataTypeProvider,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableColumnResizing,
    Toolbar,
    SearchPanel,
    VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;
const getRowId = row => row.projectId;




function MyVerticallyCenteredModal(props) {
    console.log(props.data)
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Clientes agregados
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table class="table table-sm table-hover table-responsive">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Rut</th>
                            <th>Razón Social Cliente</th>
                            <th>Teléfono</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data && props.data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.rut}</td>
                                <td>{item.clientRazonSocial}</td>
                                <td>{item.phoneNumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" css={css`color:white;`} onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}


const Clients = ({ history }) => {

    const token = useSelector(state => state.auth.token);
    // obteniendo la data de los proyectos creados
    const [data, setData] = useState([])
    const [newDataUpdate, setNewDataUpdate] = useState(null)
    const [searchValue, setSearchState] = useState('');
    const [modalShow, setModalShow] = React.useState(false);


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get('/api/client', config)
            .then((res) => {
                console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [newDataUpdate])

    // const handlerProduct = (id) => {
    //     history.push(`/gestion-de-usuarios/editar/${id}`)
    // }


    const [columns] = useState([
        { name: 'rut', title: 'RUT' },
        { name: 'clientRazonSocial', title: 'Razón Social' },
        { name: 'addressComuna', title: 'Dirección de comuna' },
        { name: 'addressCity', title: 'Ciudad' },
        { name: 'addressCountry', title: 'País' },
        { name: 'phoneNumber', title: 'Teléfono' },
        { name: 'contactName', title: 'Nombre' },
        { name: 'contactLastname', title: 'Apellido' },
        { name: 'contactEmail', title: 'Email' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'rut', width: 200 },
        { columnName: 'clientRazonSocial', width: 200 },
        { columnName: 'addressComuna', width: 200 },
        { columnName: 'addressCity', width: 200 },
        { columnName: 'addressCountry', width: 200 },
        { columnName: 'phoneNumber', width: 200 },
        { columnName: 'contactName', width: 200 },
        { columnName: 'contactLastname', width: 200 },
        { columnName: 'contactEmail', width: 200 },
    ]);

    const TableRow = ({ row, ...restProps }) => {
        // return (<Table.Row {...restProps} onClick={() => console.log(row)} style={{cursor:"pointer"}}/>)
        return (<Table.Row {...restProps} onClick={() => history.push(`/clientes/${row._id}`)} style={{ cursor: 'pointer' }} />)
    };

    const TabCell = props => (
        <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    );

    return (
        <Layout>
            <>
                <Topbar title="Gestión de clientes" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card" style={{ height: '720px' }}>
                            <Grid rows={data} columns={columns} getRowId={getRowId} rootComponent={Root}>
                                {/* <CurrencyTypeProvider for={currencyColumns} /> */}
                                <SearchState value={searchValue} onValueChange={setSearchState} />
                                <IntegratedFiltering />
                                <Table />
                                <VirtualTable height={670} cellComponent={TabCell} rowComponent={TableRow} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>

                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        data={newDataUpdate}
                    />
                </Row>
            </>
            <>
                <Sidebar updateCLient setDataToCLienteAfterUpdate={setNewDataUpdate} setModalShowAfterUpdtaeCLient={setModalShow} />
            </>
        </Layout>
    );
}

export default Clients;