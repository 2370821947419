import * as React from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Layout from "../structures/Layout";

const MaterialRequestSentDetails = () => {

    return (
        <Layout>
            <>
                <Topbar />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>Producto</th>
                                    <th>Unidad</th>
                                    <th>Saldo<br />Disponible</th>
                                    <th>Cantidad</th>
                                    <th>Observación</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>200</td>
                                    <td>Pintada roja</td>
                                    <td>Cotizando</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>200</td>
                                    <td>Pintada roja</td>
                                    <td>OC Enviada</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>200</td>
                                    <td>Pintada roja</td>
                                    <td>En Bodega</td>
                                </tr>
                                <tr>
                                    <td>1a</td>
                                    <td>Cañería 4" SCH40</td>
                                    <td>m</td>
                                    <td>6.000</td>
                                    <td>200</td>
                                    <td>Pintada roja</td>
                                    <td>Recibido</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar />
            </>
        </Layout>
    );
}

export default MaterialRequestSentDetails;