import React, { useEffect, useState } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarFIlterLogs'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import clienteAxios from './../../config/axios';
import { useSelector } from 'react-redux';
import {
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    SearchPanel,
    Toolbar,
    TableColumnResizing,
    VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';


const Logs = ({ history }) => {
    const [searchValue, setSearchState] = useState('');

    const token = useSelector(state => state.auth.token);
    // obteniendo la data de los proyectos creados
    const [data, setData] = useState([])
    const [body, setBody] = useState({
        startOfDay: null,
        endOfDay: null,
        userId: null,
        projectName: null
    })

    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        // console.log(body)
        clienteAxios.post('/api/log', body, config)
            .then((res) => {
                // console.log(res.data)
                let result = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    result.push({
                        action: element.action,
                        date: element.date.split('T')[0],
                        projectName: element.projectName,
                        projectNumber: element.projectNumber,
                        usuario: element.userId.name + " " + element.userId.lastname,
                    })
                }
                setData(result)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [body])

    const [columns] = useState([
        { name: 'action', title: 'Acción' },
        { name: 'date', title: 'Fecha' },
        { name: 'projectName', title: 'Proyecto' },
        { name: 'projectNumber', title: 'Numero de proyecto' },
        { name: 'usuario', title: 'Usuario' },
    ]);
    //   const [rows] = useState(generateRows({ length: 8 }));
    const [defaultColumnWidths] = useState([
        { columnName: 'action', width: 180 },
        { columnName: 'date', width: 180 },
        { columnName: 'projectName', width: 180 },
        { columnName: 'projectNumber', width: 240 },
        { columnName: 'usuario', width: 240 },
    ]);
    return (
        <Layout asideWidth={3}>
            <>
                <Topbar title="Logs de transacciones" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card">
                            <Grid rows={data} columns={columns} >
                                <SearchState value={searchValue} onValueChange={setSearchState} />
                                <IntegratedFiltering />
                                <Table />
                                <VirtualTable height={670} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar setBody={setBody} body={body} />
            </>
        </Layout>
    );
}

export default Logs;