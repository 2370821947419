import { UPDATE_PROJECT_START, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UpdateProjectAction(body, id, token) {
    return (dispatch) => {
        dispatch(UpdateProjectStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.put(`/api/project/${id}`, body, config)
            .then((res) => {
                // console.log(res)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha actualizado el proyecto correctamente.' })
                dispatch(UpdateProjectSuccess())
                // history.push(`/admin-datos-de-proyecto/${res.data._id}`)
            })
            .catch(error => {
                dispatch(UpdateProjectFail())
                Swal.fire({ icon: 'error', title: 'Exito!', text: 'Error al actualizar' })
            })
    }
}




export const UpdateProjectStart = () => {
    return {
        type: UPDATE_PROJECT_START
    };
};

export const UpdateProjectSuccess = () => ({
    type: UPDATE_PROJECT_SUCCESS,
})

export const UpdateProjectFail = () => ({
    type: UPDATE_PROJECT_FAIL,
})
