import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarFilters'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
    PagingState,
    IntegratedPaging,
    DataTypeProvider,
    SearchState,
    IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableColumnResizing,
    Toolbar,
    SearchPanel,
    VirtualTable
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import Layout from "../structures/Layout";

const Root = props => <Grid.Root {...props} style={{ height: '100%' }} />;
const getRowId = row => row.projectId;


const ManagerMaterialPurchase = ({ history }) => {
    const [graphProjectsGeneralData, setGraphProjectsGeneralData] = useState([])
    const [searchValue, setSearchState] = useState('');

    const [formdata, setFormData] = useState({
        status: ['aprobada'],
        projectId: null,
        requestedBy: null,
        endOfDay: null,
        startOfDay: null,
        advancePercentage: null,
        advancePercentageStart: 0,
        advancePercentageEnd: null,
        daysStart: 0,
        daysEnd: null
    })

    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token)
    const [data, setData] = useState([])

    const { status, projectId, requestedBy, endOfDay, startOfDay, advancePercentageStart, advancePercentageEnd, daysStart, daysEnd } = formdata

    useEffect(() => {
        // console.log(formdata)

        let body = {
            status: status === "Estado" ? ['aprobada'] : status,
            projectId: projectId === "" ? null : projectId,
            requestedBy: requestedBy === "" ? null : requestedBy,
            endOfDay: endOfDay === "" ? null : endOfDay,
            startOfDay: startOfDay === "" ? null : startOfDay,
            advancePercentageStart: advancePercentageEnd === 0 ? null : 0,
            advancePercentageEnd: advancePercentageEnd === 0 ? null : advancePercentageEnd,
            daysStart: daysEnd === 0 ? null : 0,
            daysEnd: daysEnd === 0 ? null : daysEnd,
        }
        // console.log(body)

        const config = {
            headers: {
                'Authorization': "Bearer " + token,
            }
        };
        clienteAxios.post(`/api/getMaterialRequestByStatus`, body, config)
            .then((res) => {
                // console.log(res.data)
                const body = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    // console.log(element.projectId.projectName)
                    body.push({
                        number: element.number,
                        projectName: element.projectId.projectName,
                        supervisedByName: element.requestedBy.name + " " + element.requestedBy.lastname,
                        status: element.status,
                        advancePercentage: element.advancePercentage + "%",
                        days: element.days,
                        projectId: element._id,
                    })
                }
                setData(body)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [formdata])


    const [columns] = useState([
        { name: 'number', title: 'Solicitud' },
        { name: 'projectName', title: 'Proyecto' },
        { name: 'supervisedByName', title: 'Supervisor' },
        { name: 'status', title: 'Estado' },
        { name: 'advancePercentage', title: 'Avance general' },
        { name: 'days', title: 'Días' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'number', width: 180 },
        { columnName: 'projectName', width: 150 },
        { columnName: 'supervisedByName', width: 150 },
        { columnName: 'status', width: 200 },
        { columnName: 'advancePercentage', width: 180 },
        { columnName: 'days', width: 220 },
    ]);

    const TableRow = ({ row, ...restProps }) => {
        return (<Table.Row {...restProps} onClick={() => {
            // console.log(row)
            let projectName = row.projectName;
            let id = row.projectId;

            if (status === "cerradas") {
                history.push(`/gestionar-compras-de-material/material/view/${id}`)
            } else {
                history.push(`/gestionar-compras-de-material/material/${id}`, { projectName })
            }

        }} style={{ cursor: 'pointer' }} />)
    };

    const TabCell = props => (
        <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    );

    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (<p>{value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </p>)
        } else {
            return (<p>{value}</p>)
        }
    };

    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['totalBudgetAmount', 'totalAmountPurchased', 'purchasePercentage']);



    return (
        <Layout asideWidth={3}>
            <>
                <Topbar title="Proyectos" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card" style={{ height: '720px' }}>
                            <Grid rows={data} columns={columns} getRowId={getRowId} rootComponent={Root}>
                                <CurrencyTypeProvider for={currencyColumns} />
                                <SearchState value={searchValue} onValueChange={setSearchState} />
                                <IntegratedFiltering />
                                <Table />
                                <VirtualTable height={670} cellComponent={TabCell} rowComponent={TableRow} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <SearchPanel />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar
                    // data={data}
                    // RunRequestFilter={RunRequestFilter}
                    formdata={formdata}
                    setFormData={setFormData}
                    defaultStateGet={null}
                    isManagerPurchaseMaterial
                />
            </>
        </Layout>
    );
}

export default ManagerMaterialPurchase;