import React, { useEffect, useState } from "react"
import { NavLink } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'
//Structures
import Navlinks from './NavLinks'
//Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap'
//Images
import Logo from './../../resources/images/page/MiniLogo.svg'
// redux
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from "../../config/axios";
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';


const NavbarNavigation = () => {
    const icon = useSelector(state => state.uploadImageStyledCompanRequest.icon)
    const companyId = useSelector(state => state.auth.company)
    const user_role = useSelector(state => state.auth.user_role)
    const token = useSelector(state => state.auth.token)
    const iconGet = useSelector(state => state.companyInfoRequest.icon)
    let history = useHistory();


    return (
        <React.Fragment>
            <Container className="navbar px-0 ">
                <Row className="position-relative">
                    <Col xs={12} className="text-center px-0">
                        <NavLink exact to={`${user_role === "gerente" ? '/Admin-dashboard' : "#"}`}><img src={icon ? icon : iconGet ? iconGet : Logo} className="logoNavbar img-fluid rounded-circle " alt="Grand Canyon Patios Logo" /></NavLink>
                    </Col>
                    <Navlinks />
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default NavbarNavigation;