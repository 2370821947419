import React, { useState } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// redux
import { useSelector, useDispatch } from 'react-redux'
// alert
import Swal from 'sweetalert2';
import { CreateUserManagerAction } from './../../redux/actions/CreateUserManagerAction';
import Fn from './../extras/validateRUT';
import Layout from "../structures/Layout";
const ManagerCreateUser = ({ history }) => {
    const token = useSelector(state => state.auth.token)

    const [formdata, setFormData] = useState({
        role: '',
        name: '',
        lastname: '',
        rut: '',
        email: '',
    })

    const { role, name, lastname, rut, email } = formdata;

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const CreateUserManagerFn = (formdata, history, token) => dispatch(CreateUserManagerAction(formdata, history, token));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        if (role === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Rol del usuario requerido' })
        } else if (name === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del usuario requerido' })
        } else if (lastname === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Apellido del usuario requerido' })
        } else if (rut === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'RUT del usuario requerido' })
        } else if (email === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Email del usuario requerido' })
        } else {
            let newRut = rut.split('-')
            let body = {
                role,
                name,
                lastname,
                rut: newRut[0] + newRut[1],
                email,
            }
            CreateUserManagerFn(body, history, token)
            // console.log(body)
        }
    }

    const checkRut = (e) => {
        let rutCheck = e.target.value;
        // console.log(rutCheck)

        if (rutCheck !== "" && rutCheck.includes("-")) {
            if (rutCheck.split('-')[1].toUpperCase() === "K") {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Si el digito verificador termina en "K" reemplazar con "0". Ingrese nuevamente el RUT.' })
            } else {
                if (!Fn.validaRut(rut)) {
                    Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de administrador invalido' })
                } else {
                    setFormData({
                        ...formdata,
                        rut: rutCheck,
                    })
                }
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut de administrador invalido' })
        }

    }

    return (
        <Layout>
            <>
                <Topbar title="Crear usuario / Gerencia" />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="userRol">
                                <Form.Label>Rol</Form.Label>
                                <Form.Control as="select" name="role" onChange={readFormData}>
                                    <option value="">Seleccionar</option>
                                    <option value="Comprador">Comprador</option>
                                    <option value="Aprobador">Aprobador</option>
                                    <option value="Supervisor">Supervisor</option>
                                    <option value="Visualizador">Visualizador</option>
                                    <option value="Colaborador">Colaborador</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="userName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" name="name" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="userLastname">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control type="text" placeholder="Apellido" name="lastname" onChange={readFormData} />
                            </Form.Group>
                            <Form.Group controlId="userRUT">
                                <Form.Label>RUT</Form.Label>
                                <Form.Control type="text" placeholder="12345678-0" name="rut" value={rut} onChange={readFormData} onBlur={checkRut} />
                            </Form.Group>
                            <Form.Group controlId="userEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="correo@correo.com" name="email" onChange={readFormData} />
                            </Form.Group>
                            <Button onClick={handleSubmit} variant="warning" className="mt-5">Crear usuario</Button>
                        </Col>
                    </Form>
                </Row>
            </>
        </Layout>
    );
}

export default ManagerCreateUser;