import { UPDATE_COMPANY_START, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UpdateCompanyFnAction(formdata, history, id, token) {
    return (dispatch) => {
        dispatch(UpdateCompanyStart())
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.put(`/api/company/${id}`, formdata, config)
            .then((res) => {
                dispatch(UpdateCompanySuccess())
                // console.log(res)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha actualizado correctamente.' })
                // history.push('/gerente-solicitud-de-material')
            })
            .catch(error => {
                dispatch(UpdateCompanyFail())
                // console.log(error)
            })
    }
}




export const UpdateCompanyStart = () => {
    return {
        type: UPDATE_COMPANY_START
    };
};

export const UpdateCompanySuccess = () => ({
    type: UPDATE_COMPANY_SUCCESS,
})

export const UpdateCompanyFail = () => ({
    type: UPDATE_COMPANY_FAIL,
})
