import {
    UPLOAD_ICON_COMPANY_FILE_PRODUCTS_START,
    UPLOAD_ICON_COMPANY_FILE_PRODUCTS_SUCCESS,
    UPLOAD_ICON_COMPANY_FILE_PRODUCTS_FAIL,
    REMOVE_ICON_COMPANY_FILE_PRODUCTS_SUCCESS,
    UPLOAD_BG_COMPANY_FILE_PRODUCTS_START,
    UPLOAD_BG_COMPANY_FILE_PRODUCTS_SUCCESS,
    UPLOAD_BG_COMPANY_FILE_PRODUCTS_FAIL,
    UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_START,
    UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_SUCCESS,
    UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_FAIL,
} from '../actions/ActionsTypes';

const initialState = {
    icon: false,
    backgound: false,
    logo: false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPLOAD_ICON_COMPANY_FILE_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case UPLOAD_ICON_COMPANY_FILE_PRODUCTS_SUCCESS:
            return {
                ...state,
                icon: action.payload,
                error: null,
                loading: false
            }
        case UPLOAD_ICON_COMPANY_FILE_PRODUCTS_FAIL:
            return {
                ...state,
                icon: false,
                error: null,
                loading: false,
            }
        // ----------------------------------
        case UPLOAD_BG_COMPANY_FILE_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case UPLOAD_BG_COMPANY_FILE_PRODUCTS_SUCCESS:
            return {
                ...state,
                backgound: action.payload,
                error: null,
                loading: false
            }
        case UPLOAD_BG_COMPANY_FILE_PRODUCTS_FAIL:
            return {
                ...state,
                backgound: false,
                error: null,
                loading: false,
            }
        // ----------------------------------
        case UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_SUCCESS:
            return {
                ...state,
                logo: action.payload,
                error: null,
                loading: false
            }
        case UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_FAIL:
            return {
                ...state,
                logo: false,
                error: null,
                loading: false,
            }
        // ----------------------------------
        case REMOVE_ICON_COMPANY_FILE_PRODUCTS_SUCCESS:
            return {
                ...state,
                icon: null,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}