import React, { useEffect } from 'react';
// store -------------------------------------------------
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/state/createStore';

//Components --------------------------------
import Login from './components/pages/Login'
import Register from './components/pages/Register'
import RecoverPassword from './components/pages/RecoverPassword'
import UpdatePassword from './components/pages/UpdatePassword'
import AdminDashboard from './components/pages/AdminDashboard'
import AdminProjects from './components/pages/AdminProjects'
import AdminDateProject from './components/pages/AdminDataProject'
import AdminUploadProducts from './components/pages/AdminUploadProducts'
import AdminProjectProducts from './components/pages/AdminProjectProducts'
import Projects from './components/pages/Projects'
import Logs from './components/pages/Logs'
import ProjectMaterialRequest from './components/pages/ProjectMaterialRequest'
import MaterialRequest from './components/pages/MaterialRequest'
import MaterialRequestUpdate from './components/pages/MaterialRequestUpdate'
import MaterialRequestPendingDetails from './components/pages/MaterialRequestPendingDetails'
import MaterialRequestSentDetails from './components/pages/MaterialRequestSentDetails'
import ManagerMaterialRequest from './components/pages/ManagerMaterialRequest'
import ManagerProjectProducts from './components/pages/ManagerProjectProducts'
import BuyerProjects from './components/pages/BuyerProjects'
import BuyerMaterialRequest from './components/pages/BuyerMaterialRequest'
import BuyerProjectProducts from './components/pages/BuyerProjectProducts'
import ManagerReport from './components/pages/ManagerReport'
import TransactionLogs from './components/pages/TransactionLogs'
import ManagerUsers from './components/pages/ManagerUsers'
import ManagerCreateUser from './components/pages/ManagerCreateUser'
import ManagerUpdateUser from './components/pages/ManagerUpdateUser'
import CompanyManagment from './components/pages/CompanyManagment'
import CreateCompany from './components/pages/CreateCompany'
import UpdateCompany from './components/pages/UpdateCompany'
import EditStyleCompany from './components/pages/EditStyleCompany'
import ManageMaterialPurchase from './components/pages/ManageMaterialPurchase'
import ManagerMaterialPurchaseView from './components/pages/ManagerMaterialPurchaseView';
import ProjectProductReport from './components/pages/ProjectProductReport';
import OrderPurchaseReport from './components/pages/OrderPurchaseReport';
import ManagerMaterialPurchaseViewDisabled from './components/pages/ManagerMaterialPurchaseViewDisabled';
import Tags from './components/pages/Tags';
import CategoriesSubcategories from './components/pages/CategoriesSubcategories';
import ManagerVistaByUser from './components/pages/ManagerVistaByUser';
import Clients from './components/pages/Clients';
import ViewEditClient from './components/pages/ViewEditClient';

// import NotFound from './components/NotFound'
import NotFound2 from './components/NotFound2'

function DafaenaApp() {
  return (
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ScrollToTop>
            <main>
              <Switch>
                <Route exact path="/" component={Login} />
                {/* <Route exact path="/login/:urlName" component={Login} /> */}
                <Route exact path="/registro-empresa" component={Register} />
                <Route exact path="/recuperar-contrasena" component={RecoverPassword} />
                <Route exact path="/actualizar-contrasena" component={UpdatePassword} />
                <Route exact path="/admin-dashboard" component={AdminDashboard} />
                <Route exact path="/admin-proyectos" component={AdminProjects} />
                <Route exact path="/admin-proyectos/datos-de-proyecto/:id" component={AdminDateProject} />
                <Route exact path="/admin-proyectos/datos-de-proyecto/:id/categoria-y-subcategorias" component={CategoriesSubcategories} />
                <Route exact path="/admin-proyectos/datos-de-proyecto/productos/:id" component={AdminProjectProducts} />
                <Route exact path="/admin-cargar-productos" component={AdminUploadProducts} />
                <Route exact path="/proyectos" component={Projects} />
                <Route exact path="/logs" component={Logs} />
                <Route exact path="/proyectos/solicitud-de-material/:id" component={ProjectMaterialRequest} />
                <Route exact path="/proyectos/solicitud-de-material/crear/:id" component={MaterialRequest} />
                <Route exact path="/proyectos/solicitud-de-material/actualizar/:id/:idProject" component={MaterialRequestUpdate} />
                <Route exact path="/solicitud-de-material-pendiente" component={MaterialRequestPendingDetails} />
                <Route exact path="/solicitud-de-material-enviado" component={MaterialRequestSentDetails} />
                <Route exact path="/aprobar-solicitud-de-material" component={ManagerMaterialRequest} />
                <Route exact path="/aprobar-solicitud-de-material/material/:id" component={ManagerProjectProducts} />
                <Route exact path="/gestionar-compras-de-material" component={ManageMaterialPurchase} />
                <Route exact path="/gestionar-compras-de-material/material/:id" component={ManagerMaterialPurchaseView} />
                <Route exact path="/gestionar-compras-de-material/material/view/:id" component={ManagerMaterialPurchaseViewDisabled} />
                <Route exact path="/comprador-proyectos" component={BuyerProjects} />
                <Route exact path="/comprador-solicitud-de-material" component={BuyerMaterialRequest} />
                <Route exact path="/comprador-productos-del-proyecto" component={BuyerProjectProducts} />
                <Route exact path="/informe" component={ManagerReport} />
                <Route exact path="/informe/productos-de-proyecto/:id" component={ProjectProductReport} />
                <Route exact path="/informe/ordenes-de-compra/material/:materialId/:idProject" component={OrderPurchaseReport} />
                <Route exact path="/registro-de-transacciones" component={TransactionLogs} />
                <Route exact path="/gestion-de-usuarios" component={ManagerUsers} />
                <Route exact path="/gestion-de-usuarios/crear" component={ManagerCreateUser} />
                <Route exact path="/gestion-de-usuarios/editar/:id" component={ManagerUpdateUser} />
                <Route exact path="/gestion-de-empresas" component={CompanyManagment} />
                <Route exact path="/gestion-de-empresas/crear" component={CreateCompany} />
                <Route exact path="/gestion-de-empresas/editar/:id" component={UpdateCompany} />
                <Route exact path="/editar-estilo-empresa" component={EditStyleCompany} />
                <Route exact path="/agregar-etiquetas" component={Tags} />
                <Route exact path="/gestionar-vistas-por-usuarios" component={ManagerVistaByUser} />
                <Route exact path="/clientes" component={Clients} />
                <Route exact path="/clientes/:id" component={ViewEditClient} />
                {/* <Route exact path="/404" component={NotFound} /> */}
                <Route exact path="/404" component={NotFound2} />
                <Redirect to="/404" />
              </Switch>
            </main>
          </ScrollToTop>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default DafaenaApp;
