import React, { useState, useEffect, useCallback, useRef } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

import { EditingState, PagingState, IntegratedPaging, DataTypeProvider, SearchState, IntegratedFiltering, GroupingState, IntegratedGrouping, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableInlineCellEditing, Toolbar, TableColumnResizing, TableFixedColumns, TableEditRow, PagingPanel, SearchPanel, TableGroupRow, VirtualTable, TableEditColumn, ExportPanel,GroupingPanel } from '@devexpress/dx-react-grid-bootstrap4';
import { Plugin, Template, TemplatePlaceholder, TemplateConnector } from '@devexpress/dx-react-core';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { UpdatingTableProductsAction } from '../../redux/actions/UploadFileProductActions';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';

const getRowId = row => row._id;
// #FOLD_BLOCK
const StartEditActionSelector = (props) => {
    const { defaultAction, changeAction } = props;
    return (
        <div>
            {'Editar con:'}
            &nbsp;
            <select
                defaultValue={defaultAction}
                onChange={e => changeAction(e.target.value)}
                className="dropdown"
            >
                <option value="click">Click</option>
                <option value="doubleClick">Doble Click</option>
            </select>
        </div>
    );
};

// #FOLD_BLOCK
const SelectTextChecker = (props) => {
    const { isSelectText, changeSelectText } = props;
    return (
        <div
            style={{
                padding: '0em 1em',
            }}
        >
            <label htmlFor="selectTextChecker" className="form-check-label">
                <input
                    type="checkbox"
                    checked={isSelectText}
                    id="selectTextChecker"
                    name="selectTextChecker"
                    className="form-check-input"
                    onChange={e => changeSelectText(e.target.checked)}
                />
                Seleccionar texto en foco
            </label>
        </div>
    );
};

const EditPropsPanel = props => (
    <Plugin name="EditPropsPanel">
        <Template name="toolbarContent">
            <SelectTextChecker {...props} />
            <TemplatePlaceholder />
            <StartEditActionSelector {...props} />
        </Template>
    </Plugin>
);
const PercentFormatter = ({ value }) => (
    <span>
        {value}
        %
    </span>
);

const PercentTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={PercentFormatter}
        {...props}
    />
);


const AdminProjectProducts = ({ history }) => {
    const token = useSelector(state => state.auth.token)
    // ID del parametro enviado por url, pertenece al id del proyecto que se esta viendo.
    const { id } = useParams();
    // actions que actualiza la tabla del state general cuando un row es editado
    const updateTableProductOfState = (changedRows) => dispatch(UpdatingTableProductsAction(changedRows));
    // state local que muestrea el boton de actualizar al detectarse un cambo en un row "onblur"
    const [onEditTable, setOnEditTable] = useState(false)
    const [loadingTable, setLoadingTable] = useState(true)
    const [CSVData, setCSVData] = useState(null);
    //DISPATCH
    const dispatch = useDispatch();
    // obteniendo los productos del proyecto almacenados en el estate general
    const ProductsData = useSelector(state => state.uploadFileProduct);

    const headers = [
        { label: "sku", key: "sku" },
        { label: "Nombre", key: "productName" },
        { label: "Unidades", key: "productUnits" },
        { label: "Marca", key: "productBrand" },
        { label: "Tipo", key: "producType" },
        { label: "Modelo", key: "productModel" },
        { label: "Cantidad presupuestada", key: "cantidadPresupuestada" },
        { label: "Cantidad compra", key: "cantidadCompra" },
        { label: "Saldo disponible", key: "saldoDisponible" },
        { label: "Monto presupuestado", key: "montoPresupuestado" },
        { label: "Monto compra", key: "montoCompra" },
        { label: "Total Presupuestado", key: "totalPresupuestado" },
        { label: "Total compra", key: "totalCompra" },
        { label: "Porcentaje compra", key: "porcentajeCompra" },
    ];

    const [columns] = useState([
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Nombre' },
        { name: 'category', title: 'Categoria' },
        { name: 'subcategory', title: 'Subcategory' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'productBrand', title: 'Marca' },
        // { name: 'producType', title: 'Tipo' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'cantidadPresupuestada', title: 'Cantidad presupuestada' },
        { name: 'cantidadCompra', title: 'Cantidad compra' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'montoPresupuestado', title: 'Monto presupuestado' },
        { name: 'montoCompra', title: 'Monto compra' },
        { name: 'totalPresupuestado', title: 'Total Presupuestado' },
        { name: 'totalCompra', title: 'Total compra' },
        { name: 'porcentajeCompra', title: 'Porcentaje compra' },
    ]);

    useEffect(() => {
        setData(ProductsData.listProducts)
        setCSVData(ProductsData.listProducts)
    }, [ProductsData])

    useEffect(() => {
        setLoadingTable(true)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/${id}/showTable`, config)
            .then((res) => {
                // console.log(res.data.products)
                setData(res.data.products)
                setCSVData(res.data.products)
                setLoadingTable(false)
            })
            .catch(error => {
                // console.log(error)
                setLoadingTable(false)
            })
    }, [])


    const [data, setData] = useState([])
    const [startEditAction, setStartEditAction] = useState('click');
    const [selectTextOnEditStart, setSelectTextOnEditStart] = useState(true);

    const [leftColumns] = useState(['sku', 'productName']);


    const [defaultColumnWidths] = useState([
        { columnName: 'sku', width: 90 },
        { columnName: 'productName', width: 150 },
        { columnName: 'category', width: 200 },
        { columnName: 'subcategory', width: 200 },
        { columnName: 'productUnits', width: 120 },
        { columnName: 'productBrand', width: 120 },
        { columnName: 'producType', width: 120 },
        { columnName: 'productModel', width: 120 },
        { columnName: 'saldoDisponible', width: 120 },
        { columnName: 'cantidadPresupuestada', width: 120 },
        { columnName: 'cantidadCompra', width: 120 },
        { columnName: 'montoPresupuestado', width: 120 },
        { columnName: 'montoCompra', width: 120 },
        { columnName: 'totalCompra', width: 120 },
        { columnName: 'totalPresupuestado', width: 120 },
        { columnName: 'porcentajeCompra', width: 120 },
    ]);


    const TableRow = ({ row, ...restProps }) => {
        // console.log(row)
        return (
            <Table.Row
                {...restProps}
                // eslint-disable-next-line no-alert
                onClick={() => history.push(`/informe/ordenes-de-compra/material/${row._id}/${row.projectId}/`)}
                style={{
                    color: row.saldoDisponible < 0 ? '#DD3434' : "#000",
                    fontWeight: row.saldoDisponible < 0 ? 'bold' : "normal",
                    cursor: "pointer"
                }}
            />
        )
    };


    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (<p>{value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>)
        } else {
            return (<p>{value}</p>)
        }
    };

    // --------------------------------------------------------------------------
    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['montoPresupuestado', 'totalPresupuestado', 'totalCompra', 'montoCompra']);

    const [percentColumns] = useState([
        'porcentajeCompra'
    ]);

    // ------------------------------------------------------------------------
    const exporterRef = useRef(null);


    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);


    const onSave = (workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Data_individual.xlsx');
        });
    };

    return (
        <Layout>
            <>
                <Topbar title="productos" />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12} className="position-relative">
                        {loadingTable && (
                            <div className="position-absolute" css={css`left:0;
                                    right:0;
                                    bottom:0;
                                    top:0;
                                    margin:auto;
                                    display: flex;
                                    justify-content: center;
                                    z-index: 100;
                                    align-items: center;
                                    background: rgba(0,0,0, 0.5);
                                    div{
                                        width: 4rem;
                                        height: 4rem;
                                    }
                                `}>
                                <div className="spinner-border  text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        )}
                        <div className="">
                            <Grid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                            >
                                <PercentTypeProvider
                                    for={percentColumns}
                                />
                                <CurrencyTypeProvider for={currencyColumns} />
                                <GroupingState grouping={[{ columnName: 'category' }, { columnName: 'subcategory' }]} />
                                <IntegratedGrouping />
                                <SearchState />
                                <IntegratedFiltering />
                                <Table />
                                <VirtualTable height={670} rowComponent={TableRow} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />
                                <Toolbar />
                                <TableGroupRow />
                                <SearchPanel />
                                <TableFixedColumns
                                    leftColumns={leftColumns}
                                />
                                 {/* <GroupingPanel /> */}
                                <ExportPanel startExport={startExport} />
                            </Grid>

                            <GridExporter
                                ref={exporterRef}
                                rows={data}
                                columns={columns}
                                grouping={[{ columnName: 'category' }, { columnName: 'subcategory' }]}
                                onSave={onSave}
                            />
                        </div>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default AdminProjectProducts;