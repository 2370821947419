import { UPDATE_USER_MANAGER_START, UPDATE_USER_MANAGER_SUCCESS, UPDATE_USER_MANAGER_FAIL} from '../actions/ActionsTypes';

const initialState = {
    success:false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER_MANAGER_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case UPDATE_USER_MANAGER_SUCCESS:
            return {
                ...state,
                success:true,
                error: null,
                loading: false
            }
        case UPDATE_USER_MANAGER_FAIL:
            return {
                ...state,
                success:false,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}