import {
    UPLOAD_AVATAR_FILE_START, UPLOAD_AVATAR_FILE_SUCCESS, UPLOAD_AVATAR_FILE_FAIL,

} from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function UploadAvatarAction(event, token) {
    return (dispatch) => {
        dispatch(UploadAvatarStart())
        // console.log(event, token)
        let fl = new FormData();
        fl.append("avatar", event.target.files[0]);

        clienteAxios.post(`/api/img/avatar/new/`, fl, {
            headers: {
                'Authorization': "Bearer " + token
            }
        })
            .then((res) => {
                // console.log(res.data.img)
                Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha cargado el archivo correctamente.' })
                dispatch(UploadAvatarSuccess(res.data.img))
            })
            .catch(error => {
                dispatch(UploadAvatarFail())
                // console.log(error)
            })
    }
}


export const UploadAvatarStart = () => {
    return {
        type: UPLOAD_AVATAR_FILE_START
    };
};

export const UploadAvatarSuccess = (avatar) => ({
    type: UPLOAD_AVATAR_FILE_SUCCESS,
    payload: avatar
})

export const UploadAvatarFail = () => ({
    type: UPLOAD_AVATAR_FILE_FAIL,
})
