import { SEND_NEW_MATERIAL_REQUEST_TABLE_START, SEND_NEW_MATERIAL_REQUEST_TABLE_SUCCESS, SEND_NEW_MATERIAL_REQUEST_TABLE_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function SendNewMaterialRequestTableAction(data, history, token, id, projectId, setFirstSend, projectName) {
    return (dispatch) => {
        // console.log(data)
        dispatch(SendNewMaterialRequestTableStart())
        let stop = false
        let count = 0
        let dataMaterialRequest = []
        for (let i = 0; i < data.products.length; i++) {
            let element = data.products[i];
            if (element.cantidadSolicitada != "") {
                count = count + 1
                dataMaterialRequest.push(element)
            }
        }

        if (count >= 1) {
            if (count > 15) {
                Swal.fire({ icon: 'warning', title: '¡Atención!', text: 'Solo es posible agregar hasta un máximo de 15 productos.' })
                stop = true;
            } else {
                for (let i = 0; i < dataMaterialRequest.length; i++) {
                    let element = dataMaterialRequest[i];
                    if (element.cantidadSolicitada === "" || element.cantidadSolicitada === "0") {
                        Swal.fire({ icon: 'error', title: 'Error!', text: 'Cantidad Solicitada requerida y debe ser mayor a 0' })
                        stop = true;
                        break;
                    }
                }
            }
        } else {
            Swal.fire({ icon: 'error', title: 'Error!', text: 'Debe solicitar almenos 1 material' })
            stop = true;
        }

        console.log(stop)
        if (stop) {
            console.log('Stop')
            return;
        } else {

            console.log("entro")
            let body = {
                materialRequestId: data.materialRequestId,
                products: dataMaterialRequest
            }
            // console.log(body)
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };

            Swal.fire({
                icon: 'question',
                title: 'Desea enviar la solicitud?',
                showDenyButton: true,
                confirmButtonText: `Enviar`,
                denyButtonText: `No enviar`,
                allowOutsideClick: false,
                confirmButtonColor: "#FFA41E",
                cancelButtonColor: "#6C757D",
                showCancelButton: true,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    clienteAxios.post(`/api/project/${id}/materialRequest/createTable`, body, config)
                        .then((res) => {
                            // console.log(res.data)
                            // Swal.fire({ icon: 'success', title: 'Exito!', text: 'Solicitud enviada correctamente.' })
                            dispatch(SendNewMaterialRequestTableSuccess())
                            let body2 = {
                                "status": "enviada"
                            }
                            clienteAxios.put(`/api/project/materialRequest/${id}`, body2, config)
                                .then((res) => {
                                    // console.log(res.data)
                                    Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha enviado correctamente.' })
                                    history.push(`/proyectos/solicitud-de-material/${projectId}`)
                                })
                                .catch(error => {
                                    // console.log(error)
                                })
                        })
                        .catch(error => {
                            dispatch(SendNewMaterialRequestTableFail())
                            // console.log(error)
                        })
                } else if (result.isDenied) {
                    clienteAxios.post(`/api/project/${id}/materialRequest/createTable`, body, config)
                        .then((res) => {
                            // console.log(res.data)
                            Swal.fire({ icon: 'success', title: 'Exito!', text: 'Solicitud enviada correctamente.' })
                            dispatch(SendNewMaterialRequestTableSuccess())
                            history.push(`/proyectos/solicitud-de-material/${projectId}`)
                        })
                        .catch(error => {
                            dispatch(SendNewMaterialRequestTableFail())
                            // console.log(error)
                        })
                }
            })
        }
    }
}

export function SendNewMaterialRequestTableUpdayeAction(data, history, token, id, projectId, projectName) {
    return (dispatch) => {
        // console.log(data)
        dispatch(SendNewMaterialRequestTableStart())
        let stop = false
        let count = 0
        let dataMaterialRequest = []
        for (let i = 0; i < data.products.length; i++) {
            let element = data.products[i];
            if (element.cantidadSolicitada != "") {
                count = count + 1
                dataMaterialRequest.push(element)
            }
        }


        if (count > 15) {
            Swal.fire({ icon: 'warning', title: '¡Atención!', text: 'Solo es posible agregar hasta un máximo de 15 productos.' })
            stop = true;
        } else {
            for (let i = 0; i < data.products.length; i++) {
                const element = data.products[i];
                if (element.cantidadSolicitada === 0 || element.cantidadSolicitada === "" || element.cantidadSolicitada === "0") {
                    Swal.fire({ icon: 'error', title: 'Error!', text: 'Cantidad Solicitada requerida y debe ser mayor a 0' })
                    stop = true;
                    break;
                }
            }
        }



        if (stop) {
            return;
        } else {

            Swal.fire({
                icon: 'question',
                title: 'Desea enviar la solicitud?',
                showDenyButton: true,
                confirmButtonText: `Enviar`,
                denyButtonText: `No enviar`,
                allowOutsideClick: false,
                confirmButtonColor: "#FFA41E",
                cancelButtonColor: "#6C757D",
                showCancelButton: true,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    const config = {
                        headers: { 'Authorization': "Bearer " + token }
                    };
                    clienteAxios.put(`/api/project/${id}/materialRequest/updateTable`, data, config)
                        .then((res) => {
                            // console.log(res.data)
                            // Swal.fire({ icon: 'success', title: 'Exito!', text: 'Solicitud actualizada correctamente.' })
                            // history.push(`/proyectos/solicitud-de-material/${projectId}`, { projectName })
                            dispatch(SendNewMaterialRequestTableSuccess())
                            let body2 = {
                                "status": "enviada"
                            }
                            clienteAxios.put(`/api/project/materialRequest/${id}`, body2, config)
                                .then((res) => {
                                    // console.log(res.data)
                                    Swal.fire({ icon: 'success', title: 'Exito!', text: 'Se ha enviado correctamente.' })
                                    history.push(`/proyectos/solicitud-de-material/${projectId}`)
                                })
                                .catch(error => {
                                    // console.log(error)
                                })
                        })
                        .catch(error => {
                            dispatch(SendNewMaterialRequestTableFail())
                            // console.log(error)
                        })
                } else if (result.isDenied) {
                    const config = {
                        headers: { 'Authorization': "Bearer " + token }
                    };
                    clienteAxios.put(`/api/project/${id}/materialRequest/updateTable`, data, config)
                        .then((res) => {
                            // console.log(res.data)
                            Swal.fire({ icon: 'success', title: 'Exito!', text: 'Solicitud actualizada correctamente.' })
                            dispatch(SendNewMaterialRequestTableSuccess())
                            // history.push(`/proyectos/solicitud-de-material/${projectId}`, { projectName })
                        })
                        .catch(error => {
                            dispatch(SendNewMaterialRequestTableFail())
                            // console.log(error)
                        })
                }
            })
        }
    }
}





export const SendNewMaterialRequestTableStart = () => {
    return {
        type: SEND_NEW_MATERIAL_REQUEST_TABLE_START
    };
};

export const SendNewMaterialRequestTableSuccess = () => ({
    type: SEND_NEW_MATERIAL_REQUEST_TABLE_SUCCESS
})

export const SendNewMaterialRequestTableFail = () => ({
    type: SEND_NEW_MATERIAL_REQUEST_TABLE_FAIL
})
