import React, { useState, useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Form, Button } from "react-bootstrap";
// redux
import { useSelector, useDispatch } from 'react-redux'
// alert
import Swal from 'sweetalert2';
import { UpdateUserManagerAction } from './../../redux/actions/UpdateUserManagerAction';
// router
import { useParams } from "react-router";
import clienteAxios from './../../config/axios';
// import { findAllByRole } from "@testing-library/dom";
import Fn from './../extras/validateRUT';

// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Layout from "../structures/Layout";
// import styled from '@emotion/styled';

const ManagerUpdateUser = ({ history }) => {

    const [password, setPassword] = useState("")

    const { id } = useParams();

    const token = useSelector(state => state.auth.token)
    const currentRoleID = useSelector(state => state.auth.user_role)

    const [formdata, setFormData] = useState({
        role: '',
        name: '',
        lastname: '',
        rut: '',
        email: '',
        status: '',
    })

    const { role, name, lastname, rut, email, status } = formdata;

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UpdateUserManagerFn = (formdata, id, history, token) => dispatch(UpdateUserManagerAction(formdata, id, history, token));

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async () => {
        if (role === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Rol del usuario requerido' })
        } else if (name === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Nombre del usuario requerido' })
        } else if (lastname === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Apellido del usuario requerido' })
        } else if (rut === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'RUT del usuario requerido' })
        } else if (email === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Email del usuario requerido' })
        } else {
            // console.log(formdata)
            if (Fn.validaRut(rut)) {
                let newRut = rut.split('-')
                let body = {
                    role,
                    name,
                    lastname,
                    rut: newRut[0] + newRut[1],
                    email,
                }
                UpdateUserManagerFn(body, id, history, token)
                // console.log(body)
            } else {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'Formato de rut invalido' })
            }
        }
    }

    const handleSubmitPassword = async () => {
        if (password === "") {
            Swal.fire({ icon: 'error', title: 'Lo siento', text: 'contraseña requerida' })
        } else {
            if (password.length < 7) {
                Swal.fire({ icon: 'error', title: 'Lo siento', text: 'La contraseña debe tener almenos 7 caracteres' })
            } else {
                let body = {
                    password
                }
                const config = {
                    headers: { 'Authorization': "Bearer " + token }
                };
                clienteAxios.put(`/api/users/${id}/updatePassword`, body, config)
                    .then((res) => {
                        // console.log(res.data)
                        Swal.fire({ icon: 'success', title: 'Exito', text: 'Contraseña actualizada' })
                    })
                    .catch(error => {
                        // console.log(error)
                    })
            }
        }
    }

    function capitalizarPrimeraLetra(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/users/${id}`, config)
            .then((res) => {
                // console.log(res.data)
                // setData(res.data)
                let beforeNRut = res.data.rut.toString().slice(0, -1)
                let afterNRut = res.data.rut.toString().slice(-1)
                setFormData({
                    role: capitalizarPrimeraLetra(res.data.role),
                    name: res.data.name,
                    lastname: res.data.lastname,
                    rut: beforeNRut + "-" + afterNRut,
                    email: res.data.email,
                    status: res.data.isDeleted ? true : false,
                })

            })
            .catch(error => {
                // console.log(error)
            })
    }, [])

    const ejectPutPassword = () => {
        // console.log(token)
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.put(`/api/users/${id}/restorePassword`, {}, config)
            .then((res) => {
                // console.log(res.data)
                Swal.fire({ icon: 'success', title: 'Exito', text: 'Contraseña actualizada' })
            })
            .catch(error => {
                // console.log(error)
            })
    }

    const deleteUser = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        Swal.fire({
            title: '¿Esta seguro de borrar el usuario?',
            // text: "Este cambio no podra revertirse",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                clienteAxios.delete(`/api/users/${id}`, config)
                    .then((res) => {
                        Swal.fire({ icon: 'success', title: 'Exito', text: 'Usuario eliminado' })
                        history.push('/gestion-de-usuarios')
                    })
                    .catch(error => {
                        // console.log(error)
                    })
            }
        })

    }
    const reactivateUser = () => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };

        Swal.fire({
            title: '¿Esta seguro de reactivar el usuario?',
            // text: "Este cambio no podra revertirse",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, Reactivar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                clienteAxios.put(`/api/users/${id}/reactivate`, {}, config)
                    .then((res) => {
                        Swal.fire({ icon: 'success', title: 'Exito', text: 'Usuario Reactivado' })
                        history.push('/gestion-de-usuarios')
                    })
                    .catch(error => {
                        // console.log(error)
                    })
            }
        })

    }


    return (
        <Layout>
            <>
                <Topbar title="Actaulizar usuario / Gerencia" />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="userRol">
                                <Form.Label>Rol</Form.Label>
                                <Form.Control as="select" name="role" onChange={readFormData}>
                                    <option css={css`visibility:hidden;`} value={role}>{role}</option>
                                    <option value="Comprador">Comprador</option>
                                    <option value="Aprobador">Aprobador</option>
                                    <option value="Supervisor">Supervisor</option>
                                    <option value="Visualizador">Visualizador</option>
                                    <option value="Colaborador">Colaborador</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="userName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" name="name" onChange={readFormData} value={name} />
                            </Form.Group>
                            <Form.Group controlId="userLastname">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control type="text" placeholder="Apellido" name="lastname" onChange={readFormData} value={lastname} />
                            </Form.Group>
                            <Form.Group controlId="userRUT">
                                <Form.Label>RUT</Form.Label>
                                <Form.Control type="text" placeholder="12345678-0" name="rut" disabled onChange={readFormData} value={rut} />
                            </Form.Group>
                            <Form.Group controlId="userEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="correo@correo.com" name="email" onChange={readFormData} value={email} />
                            </Form.Group>
                            {currentRoleID === "gerente" && (
                                <Form.Group controlId="userEmail">
                                    <Button onClick={ejectPutPassword} variant="secondary" className="mt-2 text-white mx-1">Restablecer contraseña</Button>
                                    {status ?
                                        <Button onClick={reactivateUser} variant="secondary" className="mt-2 text-white mx-1">Reactivar</Button>
                                        :
                                        <Button onClick={deleteUser} variant="secondary" className="mt-2 text-white mx-1">Borrar usuario</Button>
                                    }
                                </Form.Group>
                            )}

                            <Button onClick={handleSubmit} variant="warning" className="mt-5">Actualizar usuario</Button>
                        </Col>
                        {currentRoleID !== "gerente" && (
                            <Col xs={12} md={6}>
                                <Form.Group controlId="userEmail">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                                <Button onClick={() => handleSubmitPassword()} variant="warning" className="mt-2">Actualizar usuario</Button>
                            </Col>
                        )}
                    </Form>
                </Row>
            </>
            <>
                <Sidebar />
            </>
        </Layout>
    );
}

export default ManagerUpdateUser;