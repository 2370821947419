import { SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_START, SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS, SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_FAIL } from './ActionsTypes';
import Swal from 'sweetalert2';
import clienteAxios from '../../config/axios';

export function SaveProjectIdToMaterialRequestAction(projectId, materialRequestId, history, id) {
    return (dispatch) => {
        dispatch(SaveProjectIdToMaterialRequestStart())

        if (projectId) {
            // console.log(projectId)
            dispatch(SaveProjectIdToMaterialRequestSuccess(projectId))
            history.push(`/proyectos/solicitud-de-material/actualizar/${materialRequestId}/${id}`)
        }
    }
}




export const SaveProjectIdToMaterialRequestStart = () => {
    return {
        type: SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_START
    };
};

export const SaveProjectIdToMaterialRequestSuccess = (projectId) => ({
    type: SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS,
    payload: projectId
})

export const SaveProjectIdToMaterialRequestFail = () => ({
    type: SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_FAIL,
})
