import React, { useEffect, useState } from "react"

//Navbar
import Navbar from '../navigation/Navbar'
import Topbar from '../navigation/Topbar'
import Sidebar from '../navigation/SidebarOptions'
//Bootstrap
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { EditingState, DataTypeProvider, SearchState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, VirtualTable, TableInlineCellEditing, Toolbar, TableColumnResizing, TableEditColumn, TableFixedColumns, SearchPanel } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import AutoComplete from '../extras/autocompleteUpdate';
// redux
import { useDispatch, useSelector } from 'react-redux';
// router
import { useParams } from "react-router";
// axios
import clienteAxios from '../../config/axios';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Swal from "sweetalert2";
import Layout from "../structures/Layout";

const getRowId = row => row._id;

const FocusableCell = ({ onClick, ...restProps }) => (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);
const ManagerMaterialPurchaseView = ({ history, location }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })


    const { id } = useParams();
    const token = useSelector(state => state.auth.token)
    //DISPATCH
    const dispatch = useDispatch();

    const [data, setData] = useState([])
    const [projectName, setProjectName] = useState("")
    const [materialRequestNumber, setMaterialRequestNumber] = useState("")
    const [editingCells, setEditingCells] = useState([]);
    const addEmptyRow = () => commitChanges({ added: [{}] });
    const [statusToShowButton, setStatusToShowButton] = useState("")
    const [projectId, setProjectId] = useState("")
    const [booleanColumns] = React.useState(["cotizar"]);
    const [lookupColumns] = React.useState(["status"]);
    const [leftColumns] = useState(['sku', 'productName']);

    const addItemToTable = (Item) => {
        setData(state => [...state, Item])
    }

    const [columns] = useState([
        { name: 'sku', title: 'sku' },
        { name: 'productName', title: 'Producto ' },
        { name: 'productBrand', title: 'Marca' },
        { name: 'productModel', title: 'Modelo' },
        { name: 'cotizar', title: 'Cotizar  ' },
        { name: 'productUnits', title: 'Unidades' },
        { name: 'cantidadPresupuestada', title: 'Cantidad Presupuestada' },
        { name: 'saldoDisponible', title: 'Saldo disponible' },
        { name: 'cantidadSolicitada', title: 'Cantidad solicitada' },
        { name: 'cantidadCompra', title: 'Cantidad compra' },
        { name: 'montoPresupuestado', title: 'Monto presupuestado' },
        { name: 'montoCompra', title: 'Monto Compra' },
        { name: 'totalPresupuestado', title: 'Total presupuestado' },
        { name: 'disponiblePresupuesto', title: 'Disponible presupuesto' },
        { name: 'ocerp', title: 'OC ERP' },
        { name: 'Documento', title: 'Documento' },
        { name: 'observacion', title: 'Observacion' },
        { name: 'status', title: 'Estado' },
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'sku', width: 120 },
        { columnName: 'productName', width: 300 },
        { columnName: 'cotizar', width: 120 },
        { columnName: 'productBrand', width: 120 },
        { columnName: 'productModel', width: 120 },
        { columnName: 'productUnits', width: 120 },
        { columnName: 'cantidadPresupuestada', width: 220 },
        { columnName: 'cantidadSolicitada', width: 240 },
        { columnName: 'cantidadCompra', width: 240 },
        { columnName: 'saldoDisponible', width: 240 },
        { columnName: 'montoPresupuestado', width: 240 },
        { columnName: 'montoCompra', width: 240 },
        { columnName: 'totalPresupuestado', width: 240 },
        { columnName: 'disponiblePresupuesto', width: 240 },
        { columnName: 'ocerp', width: 120 },
        { columnName: 'Documento', width: 240 },
        { columnName: 'observacion', width: 240 },
        { columnName: 'status', width: 240 },
    ]);


    const [editingStateColumnExtensions] = useState([
        { columnName: 'sku', editingEnabled: false },
        { columnName: 'productName', editingEnabled: false },
        { columnName: 'productBrand', editingEnabled: false },
        { columnName: 'productModel', editingEnabled: false },
        { columnName: 'cotizar', editingEnabled: false },
        { columnName: 'productUnits', editingEnabled: false },
        { columnName: 'cantidadPresupuestada', editingEnabled: false },
        { columnName: 'cantidadSolicitada', editingEnabled: false },
        { columnName: 'cantidadCompra', editingEnabled: false },
        { columnName: 'saldoDisponible', editingEnabled: false },
        { columnName: 'montoPresupuestado', editingEnabled: false },
        { columnName: 'montoCompra', editingEnabled: false },
        { columnName: 'totalPresupuestado', editingEnabled: false },
        { columnName: 'disponiblePresupuesto', editingEnabled: false },
        { columnName: 'ocerp', editingEnabled: false },
        { columnName: 'Documento', editingEnabled: false },
        { columnName: 'observacion', editingEnabled: false },
        { columnName: 'status', editingEnabled: false },
    ]);

    const commitChanges = async ({ added, changed, deleted }) => {
        let changedRows;
        if (added) {
            const startingAddedId = data.length > 0
                ? Math.max(data[data.length - 1]._id, data[0]._id) + 1
                : 0;
            changedRows = [
                ...added.map((row, index) => ({
                    _id: startingAddedId + index,
                    ...row,
                })),
                ...data,
            ];
            setEditingCells([{ rowId: startingAddedId, columnName: columns[0].name }]);
        }
        if (changed) {
            // console.log(changed)
            changedRows = data.map(row => (changed[row._id] ? { ...row, ...changed[row._id] } : row));
        }
        if (deleted) {

            if (deleted[0].match(/^[0-9a-fA-F]{24}$/)) {

                let promise = new Promise((resolve, reject) => {
                    Swal.fire({
                        title: 'Estas seguro?',
                        text: "Esta acción no se puede revertir!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Si, eliminar!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const config = {
                                headers: { 'Authorization': "Bearer " + token }
                            };

                            clienteAxios.delete(`/api/project/${projectId}/materialRequest/updateTable/${deleted[0]}`, config)
                                .then((res) => {
                                    // console.log(res.data)

                                    const deletedSet = new Set(deleted);
                                    changedRows = data.filter(row => !deletedSet.has(row._id));

                                    Toast.fire({ icon: 'success', title: 'Eliminado correctamente' })

                                    resolve("done!")
                                })
                                .catch(error => {
                                    Toast.fire({ icon: 'error', title: 'Error al eliminar' })
                                    reject()
                                })
                        }
                    })
                });

                let result = await promise; // wait until the promise resolves (*)
                // console.log(result); // "done!"

            } else {
                const deletedSet = new Set(deleted);
                changedRows = data.filter(row => !deletedSet.has(row._id));

                Toast.fire({
                    icon: 'success',
                    title: 'Eliminado correctamente'
                })
            }

        }
        // console.log(changedRows)
        setData(changedRows ? changedRows : data);
    };



    useEffect(() => {
        // console.log(location.state)
        // if (location.state.redirect) {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/showTable`, config)
            .then((res) => {
                // console.log(res.data)
                let response = []
                for (let i = 0; i < res.data.materialRequest.length; i++) {
                    const element = res.data.materialRequest[i];
                    response.push({
                        "sku": element.materialId.sku,
                        "productName": element.materialId.productName,
                        "productUnits": element.materialId.productUnits,
                        "saldoDisponible": element.materialId.saldoDisponible,
                        "cantidadSolicitada": element.cantidadSolicitada,
                        "cantidadCompra": element.cantidadCompra,
                        "montoCompra": element.montoCompra,
                        "cantidadPresupuestada": element.materialId.cantidadPresupuestada,
                        "montoPresupuestado": element.materialId.montoPresupuestado,
                        "disponiblePresupuesto": element.materialId.disponiblePresupuesto,
                        "totalPresupuestado": element.materialId.totalPresupuestado,
                        "ocerp": element.ocerp,
                        "Documento": element.Documento,
                        "observacion": element.observacion,
                        "status": element.status,
                        "materialId": element.materialId._id,
                        "productModel": element.materialId.productModel,
                        "productBrand": element.materialId.productBrand,
                        "_id": element._id,
                    })
                }
                setData(response)
            })
            .catch(error => {
                // console.log(error)
            })

        clienteAxios.get(`/api/project/materialRequest/${id}`, config)
            .then((res) => {
                // console.log(res.data);
                setStatusToShowButton(res.data.status)
                setProjectId(res.data.projectId)
            })
            .catch(error => {
                // console.log(error)
            })

        // }
    }, [])
    useEffect(() => {
        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        clienteAxios.get(`/api/project/materialRequest/${id}/ProjectDataAndNumber`, config)
            .then((res) => {
                // console.log(res.data)
                setMaterialRequestNumber(res.data.materialRequestNumber)
                setProjectName(res.data.project.projectName)
            })
            .catch(error => {
                // console.log(error)
            })
    }, [])



    const TabCell = props => (
        <Table.Cell {...props} tabIndex={0} onFocus={props.onClick} />
    );

    const BooleanEditor = ({
        onValueChange,
        value,
        autoFocus,
        onBlur,
        onKeyDown
    }) => {
        const handleChange = event => {
            const { checked } = event.target;
            onValueChange(checked);
        };

        return (
            <Form.Check
                checked={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onChange={handleChange}
                value={true}
            />
        );
    };

    const BooleanFormatter = ({ value }) => (
        <span className="badge badge-secondary">
            {value ? 'Si' : 'No'}
        </span>
    );

    const BooleanProvider = props => {
        return (
            <DataTypeProvider
                formatterComponent={BooleanFormatter}
                editorComponent={BooleanEditor}
                {...props}
            />
        );
    };

    // ---------------------------
    const availableValues = {
        status: ["pendiente", "cotizando", "OC enviada", "en bodega", "recibido"]
    };
    const LookupEditor = ({ value, onValueChange, column, onBlur, autoFocus }) => {
        // const classes = useLookupEditCellStyles();
        const availableColumnValues = availableValues[column.name];
        const handleChange = event => {
            onValueChange(event.target.value);
        };

        return (
            <Form.Control as="select"
                value={value}
                autoFocus={autoFocus}
                onBlur={onBlur}
                onChange={handleChange}
                // MenuProps={{
                //     className: classes.selectMenu
                // }}
                input={<Form.Control />}
            >
                {availableColumnValues.map(item => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ))}
            </Form.Control>
        );
    };
    const LookupFormatter = props => (
        <DataTypeProvider
            editorComponent={params => <LookupEditor {...params} />}
            {...props}
        />
    );

    const CurrencyFormatter = ({ value }) => {
        if (value) {
            return (
                <p>
                    {value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </p>
            )
        } else {
            return (
                <p>
                    {value}
                </p>
            )
        }
    };

    const CurrencyTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={CurrencyFormatter}
            {...props}
        />
    );

    const [currencyColumns] = useState(['montoPresupuestado', 'totalPresupuestado', 'disponiblePresupuesto', 'montoCompra']);

    return (
        <Layout>

            <>
                <Topbar title={`Proyecto: ${projectName} | Número de Solicitud: ${materialRequestNumber}`} />
                <Row className="d-flex align-items-start py-3">
                    <Col xs={12}>
                        <div className="card">
                            <Grid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                            >
                                <CurrencyTypeProvider
                                    for={currencyColumns}
                                />
                                <BooleanProvider for={booleanColumns} />
                                <LookupFormatter for={lookupColumns} />

                                <EditingState
                                    onCommitChanges={commitChanges}
                                    editingCells={editingCells}
                                    onEditingCellsChange={setEditingCells}
                                    addedRows={[]}
                                    onAddedRowsChange={addEmptyRow}
                                    columnExtensions={editingStateColumnExtensions}
                                />
                                <SearchState />
                                <Table />
                                <VirtualTable height={670} cellComponent={TabCell} />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow />

                                <TableInlineCellEditing selectTextOnEditStart />
                                {data.length > 1 && (
                                    <TableEditColumn
                                        // showAddCommand
                                        showDeleteCommand
                                    />
                                )}

                                <Toolbar />
                                <SearchPanel />
                                {/* <AutoComplete addItemToTable={addItemToTable} quote statusToShowButton={statusToShowButton} /> */}
                                <TableFixedColumns
                                    leftColumns={leftColumns}
                                />
                            </Grid>
                        </div>
                    </Col>
                </Row>
            </>
            <>
                <Sidebar
                    // saveQuote
                    // quote
                    // pass
                    quoteData={data}
                    projectId={projectId}
                    history={history}
                />
            </>
        </Layout>
    );
}

export default ManagerMaterialPurchaseView;