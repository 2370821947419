import React from 'react';
import { Link } from 'react-router-dom';

//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//Images
// import NotFoundImage from './../resources/images/notfound/Construction.jpg'

const NotFound2 = () => {
    return (
        <Container fluid>
            <Row className="d-flex align-items-center justify-content-center h_100 w-100">
                <Col xs={6} className="text-center justify-content-center mx-auto">
                    <h1 className="pb-5">Lo sentimos, la página solicitada no ha sido encontrada</h1>
                    <Link to="/Recuperar-contrasena" className="btn btn-warning col-4">REGRESAR</Link>
                </Col>
                {/* <Col xs={6} className="text-center justify-content-center">
                    <img src={NotFoundImage} className="img-fluid" alt="No Encontrado" />
                </Col> */}
            </Row>
        </Container>
    );
}

export default NotFound2;