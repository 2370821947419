// Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const BUSINESS_REGISTER_START = 'BUSINESS_REGISTER_START';
export const BUSINESS_REGISTER_SUCCESS = 'BUSINESS_REGISTER_SUCCESS';
export const BUSINESS_REGISTER_FAIL = 'BUSINESS_REGISTER_FAIL';

export const UPLOAD_FILE_PRODUCTS_START = 'UPLOAD_FILE_PRODUCTS_START';
export const UPLOAD_FILE_PRODUCTS_SUCCESS = 'UPLOAD_FILE_PRODUCTS_SUCCESS';
export const UPLOAD_FILE_PRODUCTS_FAIL = 'UPLOAD_FILE_PRODUCTS_FAIL';

export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const UPDATE_TABLE_PRODUCT_START = 'UPDATE_TABLE_PRODUCT_START';
export const UPDATE_TABLE_PRODUCT_SUCCESS = 'UPDATE_TABLE_PRODUCT_SUCCESS';
export const UPDATE_TABLE_PRODUCT_FAIL = 'UPDATE_TABLE_PRODUCT_FAIL';

export const CREATE_USER_MANAGER_START = 'CREATE_USER_MANAGER_START';
export const CREATE_USER_MANAGER_SUCCESS = 'CREATE_USER_MANAGER_SUCCESS';
export const CREATE_USER_MANAGER_FAIL = 'CREATE_USER_MANAGER_FAIL';

export const MATERIAL_REQUEST_START = 'MATERIAL_REQUEST_START';
export const MATERIAL_REQUEST_SUCCESS = 'MATERIAL_REQUEST_SUCCESS';
export const MATERIAL_REQUEST_FAIL = 'MATERIAL_REQUEST_FAIL';

export const NEW_MATERIAL_REQUEST_START = 'NEW_MATERIAL_REQUEST_START';
export const NEW_MATERIAL_REQUEST_SUCCESS = 'NEW_MATERIAL_REQUEST_SUCCESS';
export const NEW_MATERIAL_REQUEST_FAIL = 'NEW_MATERIAL_REQUEST_FAIL';

export const SEND_NEW_MATERIAL_REQUEST_TABLE_START = 'SEND_NEW_MATERIAL_REQUEST_TABLE_START';
export const SEND_NEW_MATERIAL_REQUEST_TABLE_SUCCESS = 'SEND_NEW_MATERIAL_REQUEST_TABLE_SUCCESS';
export const SEND_NEW_MATERIAL_REQUEST_TABLE_FAIL = 'SEND_NEW_MATERIAL_REQUEST_TABLE_FAIL';

export const SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_START = 'SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_START';
export const SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS = 'SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_SUCCESS';
export const SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_FAIL = 'SAVE_PROJECT_ID_TO_MATERIAL_REQUEST_FAIL';

export const QUOTE_PDF_START = 'QUOTE_PDF_START';
export const QUOTE_PDF_SUCCESS = 'QUOTE_PDF_SUCCESS';
export const QUOTE_PDF_FAIL = 'QUOTE_PDF_FAIL';

export const QUOTE_SAVE_START = 'QUOTE_SAVE_START';
export const QUOTE_SAVE_SUCCESS = 'QUOTE_SAVE_SUCCESS';
export const QUOTE_SAVE_FAIL = 'QUOTE_SAVE_FAIL';

export const UPDATE_USER_MANAGER_START = 'UPDATE_USER_MANAGER_START';
export const UPDATE_USER_MANAGER_SUCCESS = 'UPDATE_USER_MANAGER_SUCCESS';
export const UPDATE_USER_MANAGER_FAIL = 'UPDATE_USER_MANAGER_FAIL';

export const UPLOAD_ICON_COMPANY_FILE_PRODUCTS_START = 'UPLOAD_ICON_COMPANY_FILE_PRODUCTS_START';
export const UPLOAD_ICON_COMPANY_FILE_PRODUCTS_SUCCESS = 'UPLOAD_ICON_COMPANY_FILE_PRODUCTS_SUCCESS';
export const UPLOAD_ICON_COMPANY_FILE_PRODUCTS_FAIL = 'UPLOAD_ICON_COMPANY_FILE_PRODUCTS_FAIL';
export const REMOVE_ICON_COMPANY_FILE_PRODUCTS_SUCCESS = 'REMOVE_ICON_COMPANY_FILE_PRODUCTS_SUCCESS';

export const UPLOAD_AVATAR_FILE_START = 'UPLOAD_AVATAR_FILE_START';
export const UPLOAD_AVATAR_FILE_SUCCESS = 'UPLOAD_AVATAR_FILE_SUCCESS';
export const UPLOAD_AVATAR_FILE_FAIL = 'UPLOAD_AVATAR_FILE_FAIL';

export const UPLOAD_BG_COMPANY_FILE_PRODUCTS_START = 'UPLOAD_BG_COMPANY_FILE_PRODUCTS_START';
export const UPLOAD_BG_COMPANY_FILE_PRODUCTS_SUCCESS = 'UPLOAD_BG_COMPANY_FILE_PRODUCTS_SUCCESS';
export const UPLOAD_BG_COMPANY_FILE_PRODUCTS_FAIL = 'UPLOAD_BG_COMPANY_FILE_PRODUCTS_FAIL';

export const UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_START = 'UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_START';
export const UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_SUCCESS = 'UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_SUCCESS';
export const UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_FAIL = 'UPLOAD_LOGO_COMPANY_FILE_PRODUCTS_FAIL';

export const COMPANY_START = 'COMPANY_START';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAIL = 'COMPANY_FAIL';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const UPLOAD_FILE_CATEGORY_SUBCATEGORY_START = 'UPLOAD_FILE_CATEGORY_SUBCATEGORY_START';
export const UPLOAD_FILE_CATEGORY_SUBCATEGORY_SUCCESS = 'UPLOAD_FILE_CATEGORY_SUBCATEGORY_SUCCESS';
export const UPLOAD_FILE_CATEGORY_SUBCATEGORY_FAIL = 'UPLOAD_FILE_CATEGORY_SUBCATEGORY_FAIL';

export const SHOW_PAGE_BY_ROL_START = 'SHOW_PAGE_BY_ROL_START';
export const SHOW_PAGE_BY_ROL_SUCCESS = 'SHOW_PAGE_BY_ROL_SUCCESS';
export const SHOW_PAGE_BY_ROL_FAIL = 'SHOW_PAGE_BY_ROL_FAIL';


