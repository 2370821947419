import React, { useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
// redux
import { useDispatch, useSelector } from 'react-redux';
import clienteAxios from "../../config/axios";


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip card p-3 shadow">
                <p>Proyecto: {`${payload[0].payload.projectName}`}</p>
                <p>Aprobada:{`${payload[0].payload.aprobada}`}</p>
                <p>Cerradas: {`${payload[0].payload.cerradas}`}</p>
                <p>Enviada:  {`${payload[0].payload.enviada}`}</p>
                <p>Pendiente:{`${payload[0].payload.pendiente}`}</p>
                <p>Días promedio: {`${payload[0].payload.diasPromedio}`}</p>
            </div>
        );
    }
    return null;
};

const ProjectGeneralData = ({graphProjectsGeneralData}) => {

    const token = useSelector(state => state.auth.token)
    // const [graphProjectsGeneralData, setGraphProjectsGeneralData] = useState("")



    return (
        <ResponsiveContainer width="100%" css={css`height:200px !important;`}>
            <BarChart
                width={500}
                height={300}
                data={graphProjectsGeneralData}
                margin={{
                    right: 20,
                    left: -20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="solicitudes" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="solicitudes" barSize={20} fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default ProjectGeneralData;