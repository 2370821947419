import { combineReducers } from 'redux';
import authReducer from './Auth';
import uploadFileProductReducer from './UploadFileProduct';
import createProjectReducer from './CreateProject';
import updateProjectReducer from './UpdateProject';
import updateTableProductReducer from './UpdateTableProduct';
import createUserManagerReducer from './CreateUserManager';
import materialRequestReducer from './MaterialRequest';
import newMaterialRequestReducer from './NewMaterialRequest';
import sendNewMateriaRequestReducer from './SendNewMateriaRequest';
import saveProyectIDToMaterialRequestReducer from './SaveProyectIDToMaterialRequest';
import quotePDFReducer from './QuotePDF';
import updateUserManagerReducer from './UpdateUserManager';
import uploadImageStyledCompanyReducer from './UploadImageStyledCompany';
import uploadAvatarReducer from './UploadAvatar';
import companyInfoReducer from './CompanyInfo';
import ShowPageByRolReducer from './ShowPageByRol';

export default combineReducers({
    auth: authReducer,
    uploadFileProduct: uploadFileProductReducer,
    createProject: createProjectReducer,
    updateProject: updateProjectReducer,
    updateTableProduct: updateTableProductReducer,
    createUserManager: createUserManagerReducer,
    materialRequest: materialRequestReducer,
    newMaterialRequest: newMaterialRequestReducer,
    sendNewMaterialRequest: sendNewMateriaRequestReducer,
    saveProyectIDToMaterialRequest: saveProyectIDToMaterialRequestReducer,
    quotePDFRequest: quotePDFReducer,
    updateUserManagerRequest: updateUserManagerReducer,
    uploadImageStyledCompanRequest: uploadImageStyledCompanyReducer,
    uploadAvatarRequest: uploadAvatarReducer,
    companyInfoRequest: companyInfoReducer,
    showPageByRol: ShowPageByRolReducer,
});