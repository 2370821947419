import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
//Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, Button, SplitButton, Dropdown } from 'react-bootstrap';
//Images
import Avatar from './../../resources/images/icons/avatar.svg'
//Structures
import UploadProductsManuallyPopup from './../structures/UploadProductsManuallyPopup';
// redux
import { useDispatch, useSelector } from 'react-redux';
//Actions
import { UploadProductsFileAction } from '../../redux/actions/UploadFileProductActions';
import { UpdateTableProductAction } from '../../redux/actions/UpdateTableProduct';
import { NewMaterialRequest } from '../../redux/actions/NewMaterialRequest';
import { UploadAvatarAction } from '../../redux/actions/UploadAvatarAction';
import { UploadCategorySUbcategoryFileAction } from '../../redux/actions/UpdateCategoryAndSubcategoru';
// router
import { useParams } from "react-router";
// styled
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { faPen } from '@fortawesome/free-solid-svg-icons'
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
const ContainerAvatar = styled.div`
    &:hover{
        div{
            visibility:initial !important;
        }
    }
        div{
            bottom: 0px;
            right: 0px;
            background: white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            visibility: hidden;
            label{
                cursor:pointer;
            }
        }
    `;

const Topbar = ({ WelcomeName, gestionProducts, onEditTable, title, materialRequest, idMaterialRequest, history, setData, projectName, excel, setRefreshData, dataCategorySubCategory, filterCategory, orderPurchaseReport, infoTitle }) => {
    const token = useSelector(state => state.auth.token)
    const companyId = useSelector(state => state.auth.company)
    const user_id = useSelector(state => state.auth.user_id)
    const user_name = useSelector(state => state.auth.user_name)
    const role = useSelector(state => state.auth.user_role)
    const listProducts = useSelector(state => state.uploadFileProduct.listProducts)
    // Optiene los productos del state general 
    const ProductsData = useSelector(state => state.uploadFileProduct.listProducts);
    // user avatar
    const avatarPost = useSelector(state => state.uploadAvatarRequest.avatar);
    const avatarLogin = useSelector(state => state.auth.avatar);
    // ID del parametro enviado por url, pertenece al id del proyecto que se esta viendo.
    const { id } = useParams();
    // estate local para mostrar los botones que accionan, cargar productos
    const [Gestionar, setGestionar] = useState(false);
    const [options, setOptions] = useState([])
    const [userSelectedName, setUserSelectedName] = useState("")
    const [userId, setUserSelectedId] = useState("")
    const [infoTitleShow, setInfoTitle] = useState("")

    //DISPATCH
    const dispatch = useDispatch();
    //ACTIONS
    const UploadProductsFileFn = (formdata, id, token) => dispatch(UploadProductsFileAction(formdata, id, token));
    const UpdateProductsTableFn = (ProductsData, id, token) => dispatch(UpdateTableProductAction(ProductsData, id, token));
    const UpdateCategorySubcategoryTableFn = (ProductsData, id, token) => dispatch(UploadCategorySUbcategoryFileAction(ProductsData, id, token, setRefreshData));
    const newRequestFn = (idProject, userId, history, token, projectName) => dispatch(NewMaterialRequest(idProject, userId, history, token, projectName));
    const UploadAvatar = (event, token) => dispatch(UploadAvatarAction(event, token));

    // handles
    const onChangeHandler = event => {
        UploadProductsFileFn(event, id, token)
    }
    const onChangeHandlerCategorySubcategory = event => {
        // console.log("se ejecuto")
        UpdateCategorySubcategoryTableFn(event, id, token, setRefreshData)
    }
    const onUpdateTableProductHandler = () => {
        UpdateProductsTableFn(ProductsData, id, token)
    }

    const newRequest = (idProject, userId, projectName) => {
        newRequestFn(idProject, userId, history, token, projectName)
    }

    const onChangeHandlerAvatar = event => {
        UploadAvatar(event, token)
    }

    useEffect(() => {
        setInfoTitle(infoTitle)
    }, [infoTitle])
    // get supervisores
    useEffect(() => {
        // console.log(orderPurchaseReport)

        const config = {
            headers: { 'Authorization': "Bearer " + token }
        };
        if (role !== "superAdmin") {
            clienteAxios.get(`/api/company/${companyId}/supervisors`, config)
                .then((res) => {
                    // console.log(res.data)
                    let respon = res.data;
                    let finalData = [];
                    for (let i = 0; i < respon.length; i++) {
                        const element = respon[i];
                        finalData.push(
                            { "value": element._id, "label": element.name + " " + element.lastname },
                        )
                    }
                    setOptions(finalData)
                })
                .catch(error => {
                    // console.log(error)
                })
        }
    }, [])
    // -------------------------------------
    const [wasPreviouslyLoaded, setWasPreviouslyLoaded] = useState(true)
    useEffect(() => {
        if (gestionProducts) {
            const config = {
                headers: { 'Authorization': "Bearer " + token }
            };
            // console.log(body)
            clienteAxios.post(`/api/project/${id}/uploadTable/wasPreviouslyLoaded`, {}, config)
                .then((res) => {
                    // console.log(res.data)
                    setWasPreviouslyLoaded(res.data.wasPreviouslyLoaded)
                })
                .catch(error => {
                    // console.log(error)
                })
        }
    }, [listProducts])



    return (
        <Row className="d-flex align-items-center pb-2 border-bottom">
            <Col lg={WelcomeName || gestionProducts || materialRequest || excel ? 5 : 12}>
                {orderPurchaseReport ?
                    <div className="d-flex">
                        <div>
                            <p><b>Nombre: </b>{infoTitleShow.productName}</p>
                            <p><b>SKU: </b>{infoTitleShow.sku}</p>
                        </div>
                        <div className="ml-3">
                            <p><b>Categoría: </b>{infoTitleShow.category}</p>
                            <p><b>Subcategoría: </b>{infoTitleShow.subcategory}</p>
                        </div>
                    </div>
                    :
                    <h1 className="py-3" css={css`font-size:1.4rem !important;`}>{title}</h1>
                }
            </Col>
            {excel ?
                dataCategorySubCategory.length === 0 ?
                    <Col lg={7} className="d-flex align-items-center text-lg-right justify-content-lg-end">
                        <React.Fragment>
                            <Col xs="auto" className="pl-0 pr-3">
                                <Form.Group controlId="fileUpload" className="mb-0">
                                    <Form.Label className="btn btn-secondary text-white px-5 mb-0" css={css`text-transform:uppercase;`}>Importar</Form.Label>
                                    <Form.File name="file" onChange={onChangeHandlerCategorySubcategory} />
                                </Form.Group>
                            </Col>
                        </React.Fragment>
                    </Col>
                    :
                    <div></div>
                :
                <div></div>
            }


            {WelcomeName || gestionProducts || materialRequest ?
                <Col lg={7} className="d-flex align-items-center text-lg-right justify-content-lg-end">
                    {WelcomeName && (
                        <div className="d-flex align-items-center">
                            <p className="text_big pr-4">Bienvenido <strong>{user_name}</strong></p>
                            <ContainerAvatar className="position-relative">
                                <img src={avatarPost ? avatarPost : avatarLogin ? avatarLogin : Avatar} className="avatar rounded-circle" alt="Avatar" />
                                <div className="position-absolute">
                                    <label htmlFor="pen">
                                        <FontAwesomeIcon icon={faPen} size="1x" />
                                    </label>
                                    <input
                                        type="file"
                                        id="pen"
                                        css={css`visibility:hidden !important;`}
                                        name="file" onChange={onChangeHandlerAvatar}
                                        accept="image/jpg, image/jpeg"
                                    />
                                </div>
                            </ContainerAvatar>
                        </div>
                    )}

                    {gestionProducts && (
                        <React.Fragment>
                            {onEditTable && (
                                <Button className="text-light btn btn-secondary px-5 mx-3" onClick={onUpdateTableProductHandler}>Actualizar tabla</Button>
                            )}
                            {!Gestionar && (
                                <Button onClick={() => setGestionar(Gestionar ? false : true)} className="text-light btn btn-secondary px-5">Gestionar</Button>
                            )}
                            {
                                Gestionar && (
                                    <Row className="d-flex align-items-center">
                                        <Col xs="auto">
                                            <UploadProductsManuallyPopup setData={setData} filterCategory={filterCategory} />
                                        </Col>
                                        {wasPreviouslyLoaded === false ?
                                            <Col xs="auto" className="pl-0 pr-3">
                                                <Form.Group controlId="fileUpload" className="mb-0">
                                                    <Form.Label className="btn btn-secondary text-white px-5 mb-0" css={css`text-transform:uppercase;`}>Importar</Form.Label>
                                                    <Form.File name="file" onChange={onChangeHandler} />
                                                </Form.Group>
                                            </Col>
                                            :
                                            <div></div>}
                                    </Row>
                                )
                            }
                        </React.Fragment>
                    )}
                    {materialRequest && (
                        <React.Fragment>
                            {role === "gerente" ?
                                <SplitButton
                                    id={`dropdown-split-variants-secondary`}
                                    variant="secondary"
                                    title={`Nueva solicitud ${userSelectedName}`}
                                    css={css`
                                    button{
                                        color:white !important;
                                        padding: 5px 20px;
                                    }
                                `}
                                    onClick={() => {
                                        userId === ""
                                            ? Swal.fire({ icon: 'error', title: 'Oops!', text: 'Seleccione un supervisor' })
                                            : newRequest(id, userId, projectName)
                                    }}
                                >
                                    {options.map((supervisor, i) => (
                                        <Dropdown.Item
                                            eventKey={i}
                                            value={supervisor.value}
                                            onClick={() => {
                                                setUserSelectedName(supervisor.label)
                                                setUserSelectedId(supervisor.value)
                                            }}
                                        >
                                            {supervisor.label}
                                        </Dropdown.Item>
                                    ))}

                                </SplitButton>
                                :
                                <Button className="text-light btn btn-secondary px-5" onClick={() => newRequest(id, user_id, projectName)}>Nueva solicitud</Button>
                            }
                        </React.Fragment>
                    )}
                </Col>
                :
                <div></div>
            }
        </Row>
    );
}

export default Topbar;