import React, { useEffect } from "react"

//Navbar
import Navbar from './../navigation/Navbar'
import Topbar from './../navigation/Topbar'
import Sidebar from './../navigation/SidebarOptions'
//Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import Layout from "../structures/Layout";


const CreateCompany = () => {


    return (
        <Layout>
            <>
                <Topbar />
                <Row className="d-flex align-items-start py-3">
                    <Form className="col-12 d-md-flex align-items-start">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="companyName">
                                <Form.Label>Nombre de la empresa</Form.Label>
                                <Form.Control type="text" placeholder="Nombre de la empresa" />
                            </Form.Group>
                            <Form.Group controlId="companyAdminRUT">
                                <Form.Label>RUT de la empresa</Form.Label>
                                <Form.Control type="text" placeholder="1234567-0" />
                            </Form.Group>
                            <Form.Group controlId="companyAdminName">
                                <Form.Label>Nombre del administrador</Form.Label>
                                <Form.Control type="text" placeholder="Nombre del administrador" />
                            </Form.Group>
                            <Form.Group controlId="companyAdminLastname">
                                <Form.Label>Apellido del administrador</Form.Label>
                                <Form.Control type="text" placeholder="Apellido del administrador" />
                            </Form.Group>
                            <Form.Group controlId="companyAdminEmail">
                                <Form.Label>Email del administrador</Form.Label>
                                <Form.Control type="email" placeholder="correo@correo.com" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label>Logo</Form.Label>
                                <Form.File className="d-none" id="companyLogo" label={<FontAwesomeIcon icon={faFileUpload} size="2x" />} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Icono</Form.Label>
                                <Form.File className="d-none" id="companyIcon" label={<FontAwesomeIcon icon={faFileUpload} size="2x" />} />
                            </Form.Group>
                        </Col>
                    </Form>
                </Row>
            </>
            <>
                <Sidebar />
            </>
        </Layout>
    );
}

export default CreateCompany;