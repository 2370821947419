import { COMPANY_START, COMPANY_SUCCESS, COMPANY_FAIL} from '../actions/ActionsTypes';

const initialState = {
    companyName:false,
    icon:false,
    isActive:false,
    userQty:false,
    ownerId:false,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPANY_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case COMPANY_SUCCESS:
            return {
                ...state,
                companyName:action.payload.companyName,
                icon:action.payload.icon,
                isActive:action.payload.isActive,
                userQty:action.payload.userQty,
                ownerId:action.payload.ownerId,
                error: null,
                loading: false
            }
        case COMPANY_FAIL:
            return {
                ...state,
                success:false,
                error: null,
                loading: false,
            }
        default:
            return state;
    }
}